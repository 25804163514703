import get from 'lodash/get';
import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { IBreadcrumbInfo } from 'src/interfaces/DashboardDataInterface';
import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';

const useFetchDashboardCitationIncreaseDecrease = (breadcrumbInfo: IBreadcrumbInfo) => {
  const [citationIncreaseDecrease, setCitationIncreaseDecrease] = useState({});
  const [isCitationIncreaseDecreaseLoading, setCitationIncreaseDecreaseLoading] = useState(false);

  useDeepCompareEffect(() => {
    const fetchDashboardCitationIncreaseDecrease = async () => {
      setCitationIncreaseDecreaseLoading(true);
      try {
        const response = await ApiCalls.getDashboardCitationIncreaseDecrease(breadcrumbInfo);
        const { data } = response;
        const fetchedCitations = get(data, 'data', {});
        setCitationIncreaseDecrease(fetchedCitations);
      } catch(error) {
        createToast({
          type: 'error',
          description: 'Couldn\'t fetch citation data'
        });
      } finally {
        setCitationIncreaseDecreaseLoading(false);
      }
    };

    fetchDashboardCitationIncreaseDecrease();
  }, [breadcrumbInfo]);

  return [citationIncreaseDecrease, isCitationIncreaseDecreaseLoading];
};

export default useFetchDashboardCitationIncreaseDecrease;

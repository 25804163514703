import React, { Fragment } from 'react';

import forEach from 'lodash/forEach';
import { Table } from 'semantic-ui-react';

import { LoadingPlaceholder } from 'src/components/Placeholders';
import { BoxTitle, DashboardBox } from 'src/screens/Dashboard/styles';
import {
  KeywordTable,
  KeywordTableCell,
  KeywordTableDepartmentCell,
  KeywordTableHeaderCell
} from './styles';

interface IProps {
  data: any;
  title?: string;
  loading: boolean;
}

const TopicKeyword = ({ data, title, loading }: IProps) => {
  const departments = Object.keys(data);
  const allKeywords =
    Array.isArray(departments) && departments.map((department: string, deptIndex: number) => {
      const keywords = data[department];
      return (
        <Table.Row key={deptIndex}>
          <KeywordTableDepartmentCell>{department}</KeywordTableDepartmentCell>
          {keywords.map((keywordData: any, index: number) => index < 3 && (
            <KeywordTableCell key={index}>{keywordData[0]}</KeywordTableCell>
          ))}
        </Table.Row>
      )
    })

    // keyw.map(({ id, department, keywords }) => (
    //   <Table.Row key={id}>
    //     <KeywordTableDepartmentCell>{department}</KeywordTableDepartmentCell>
    //     {keywords.map(
    //       (keyword: string, index: number) =>
    //         index < 3 && (
    //           <KeywordTableCell key={index}>{keyword}</KeywordTableCell>
    //         )
    //     )}
    //   </Table.Row>
    // ));

  return (
    <Fragment>
      {title && <BoxTitle>{title}</BoxTitle>}
      <DashboardBox>
        <LoadingPlaceholder loading={loading}>
          <KeywordTable striped>
            <Table.Header>
              <Table.Row>
                <KeywordTableHeaderCell />
                <KeywordTableHeaderCell>#1</KeywordTableHeaderCell>
                <KeywordTableHeaderCell>#2</KeywordTableHeaderCell>
                <KeywordTableHeaderCell>#3</KeywordTableHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{allKeywords}</Table.Body>
          </KeywordTable>
        </LoadingPlaceholder>
      </DashboardBox>
    </Fragment>
  );
};

export default React.memo(TopicKeyword);

import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component, authenticated, ...rest }: any) => (
  <Route
    {...rest}
    render={(props: any) => {
      if (authenticated) {
        return React.createElement(component, props);
      }

      return <Redirect to={{ pathname: '/' }} />;
    }}
  />
);

export { ProtectedRoute };

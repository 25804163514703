const disciplines = [
  {
    key: "anthropology",
    text: "Anthropology",
    value: "Anthropology"
  },
  {
    key: "archaeology",
    text: "Archaeology",
    value: "Archaeology"
  },
  {
    key: "arts",
    text: "Arts",
    value: "Arts"
  },
  {
    key: "business",
    text: "Business",
    value: "Business"
  },
  {
    key: "biology",
    text: "Biology",
    value: "Biology"
  },
  {
    key: "chemistry",
    text: "Chemistry",
    value: "Chemistry"
  },
  {
    key: "computer-science",
    text: "Computer Science",
    value: "Computer Science"
  },
  {
    key: "earth-science",
    text: "Earth Science",
    value: "Earth Science"
  },
  {
    key: "economics",
    text: "Economics",
    value: "Economics"
  },
  {
    key: "education",
    text: "Education",
    value: "Education"
  },
  {
    key: "engineering-and-technology",
    text: "Engineering and Technology",
    value: "Engineering and Technology"
  }
];

export default disciplines;

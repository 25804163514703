import styled from 'styled-components';

import { Table } from 'semantic-ui-react';

export const KeywordTable = styled(Table)`
  &&& {
    border-radius: 0;
    border: none;
  }
`;

export const KeywordTableHeaderCell = styled(Table.HeaderCell)`
  &&& {
    background: #fff;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 0;
    padding: 8px 10px;
  }
`;

export const KeywordTableDepartmentCell = styled(Table.Cell)`
  &&& {
    font-size: 15px;
    font-weight: bold;
    color: #212327;
    border-top: none;
    padding: 8px 10px;
    background: #fff;
  }
`;

export const KeywordTableCell = styled(Table.Cell)`
  &&& {
    font-size: 14px;
    color: #212327;
    border-top: none;
    padding: 8px 10px;
  }
`;

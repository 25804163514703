import { LOADING_START, LOADING_STOP } from "../types";

const initialState = {
  loading: true
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    // LOADING
    case LOADING_START:
      return {
        ...state,
        loading: true
      };

    case LOADING_STOP:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

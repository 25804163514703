import styled from 'styled-components';

interface ILinkProps {
  spacing?: string;
}

export const UploadPapersBox = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UploadDocumentInfo = styled.p`
  font-size: 14px;
  font-weight: 500;
  max-width: 580px;
  margin: 0 0 20px 0;
`;

export const ConversionLink = styled.a`
  font-size: 14px;
  color: #448ced;
  text-decoration: underline;
  margin-bottom: ${({ spacing }: ILinkProps) => (spacing ? '30px' : '5px')};
`;

import React, { Fragment } from 'react';

import forEach from 'lodash/forEach';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import { LoadingPlaceholder } from 'src/components/Placeholders';
import { BoxTitle, DashboardBox } from 'src/screens/Dashboard/styles';

interface IProps {
  data: any;
  title?: string;
  loading: boolean;
}

const dummyData = [{
  name: 'name',
  positive: 10,
  negative: 15,
  neutral: 10,
}]

const RechartBar = ({ data, title, loading }: IProps) => {

  const chartNames = Object.keys(data);
  const chartData = chartNames && chartNames.length ? chartNames.map((chartName: string) => {
    const itemData = data[chartName];
    return {
      name: chartName,
      positive: itemData[0],
      neutral: itemData[1],
      negative: itemData[2],
    };
  }) : [];

  return (
      <Fragment>
      {title && <BoxTitle>{title}</BoxTitle>}
      <DashboardBox>
        <LoadingPlaceholder loading={loading}>
          <ResponsiveContainer width="100%" height={260}>
            <BarChart data={chartData}>
              <XAxis dataKey="name"/>
              <Tooltip/>
              <Bar dataKey="positive" fill="#448CED"/>
              <Bar dataKey="neutral" fill="#DFE3EA"/>
              <Bar dataKey="negative" fill="#DE594D"/>
            </BarChart>
          </ResponsiveContainer>
        </LoadingPlaceholder>
      </DashboardBox>
    </Fragment>
  );
};

export default React.memo(RechartBar);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';

import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { NewPasswordCredentials } from 'src/interfaces/AuthInterface';
import { displayError } from 'src/services/helpers/FormHelper';

interface IFormValues extends NewPasswordCredentials {
  confirmPassword: string;
}
interface IProps {
  forgotPasswordSubmit: (args: NewPasswordCredentials) => void;
}

const NewPasswordForm = (props: FormikProps<IFormValues> & IProps) => {
  const { t } = useTranslation();

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit
  }: FormikProps<IFormValues> = props;

  return (
    <Form size="large" loading={isSubmitting}>
      <Form.Input
        fluid
        icon="user"
        iconPosition="left"
        placeholder={t('email')}
        value={values.email}
        onChange={handleChange('email')}
        {...displayError({
          field: 'email',
          touched: touched.email,
          errors: errors.email
        })}
      />

      <Form.Input
        fluid
        icon="user"
        iconPosition="left"
        placeholder={t('code')}
        value={values.code}
        onChange={handleChange('code')}
        {...displayError({
          field: 'code',
          touched: touched.code,
          errors: errors.code
        })}
      />

      <Form.Input
        fluid
        icon="lock"
        iconPosition="left"
        placeholder={t('password')}
        type="password"
        value={values.password}
        onChange={handleChange('password')}
        {...displayError({
          field: 'password',
          touched: touched.password,
          errors: errors.password
        })}
      />

      <Form.Input
        fluid
        icon="lock"
        iconPosition="left"
        placeholder={t('Repeat Password')}
        type="password"
        value={values.confirmPassword}
        onChange={handleChange('confirmPassword')}
        {...displayError({
          field: 'confirmPassword',
          touched: touched.confirmPassword,
          errors: errors.confirmPassword
        })}
      />

      <Button primary fluid size="large" onClick={() => handleSubmit()}>
        {t('Change Password')}
      </Button>
    </Form>
  );
};

export default withFormik<IProps, IFormValues>({
  mapPropsToValues() {
    return {
      email: '',
      code: '',
      password: '',
      confirmPassword: ''
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid Field')
      .required('Required Field'),
    code: Yup.string().required('Required Field'),
    password: Yup.string()
      .min(6, 'Password Min Length')
      .required('Required Field'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords do not match')
      .required('Required Field')
  }),
  async handleSubmit(values, { setSubmitting, props }) {
    setSubmitting(true);

    await props.forgotPasswordSubmit(values);

    setSubmitting(false);
  }
})(NewPasswordForm);

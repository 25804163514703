import React from 'react';

import { Button, Form } from 'semantic-ui-react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { IChangePassword } from 'src/interfaces/AuthInterface';
import { displayError } from 'src/services/helpers/FormHelper';

import { ForgotPasswordLink } from './styles';

interface IFormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface IProps {
  changePassword: (args: IChangePassword) => void;
}

interface IFormSubmit {
  handleSubmit: () => void;
}

const ChangePasswordForm = (props: FormikProps<IFormValues> & IProps) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit
  }: FormikProps<IFormValues> & IFormSubmit = props;

  return (
    <Form loading={isSubmitting}>
      <Form.Input
        fluid
        label="Old Password"
        type="password"
        value={values.oldPassword}
        onChange={handleChange('oldPassword')}
        {...displayError({
          field: 'oldPassword',
          touched: touched.oldPassword,
          errors: errors.oldPassword
        })}
      />

      <Form.Input
        fluid
        label="New Password"
        type="password"
        value={values.newPassword}
        onChange={handleChange('newPassword')}
        {...displayError({
          field: 'newPassword',
          touched: touched.newPassword,
          errors: errors.newPassword
        })}
      />

      <Form.Input
        fluid
        label="Repeat Password"
        type="password"
        value={values.confirmPassword}
        onChange={handleChange('confirmPassword')}
        {...displayError({
          field: 'confirmPassword',
          touched: touched.confirmPassword,
          errors: errors.confirmPassword
        })}
      />

      <Form.Field>
        <ForgotPasswordLink align="left" to="/resetpassword">
          Forgot password
        </ForgotPasswordLink>
      </Form.Field>

      <Button primary floated="right" type="submit" onClick={handleSubmit}>
        Save
      </Button>
    </Form>
  );
};

export default withFormik<IProps, IFormValues>({
  mapPropsToValues() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    };
  },
  validationSchema: Yup.object().shape({
    oldPassword: Yup.string()
      .min(6, 'Password Min Length')
      .required('Required Field'),
    newPassword: Yup.string()
      .min(6, 'Password Min Length')
      .required('Required Field'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
      .required('Required Field')
  }),
  async handleSubmit(values, { setSubmitting, props }) {
    setSubmitting(true);

    await props.changePassword(values);

    setSubmitting(false);
  }
})(ChangePasswordForm);

import _ from 'lodash';
import { useEffect, useState } from 'react';

import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';

const useFetchUniversities = () => {
  // This should be used in the future, I think
  const [universities, setUniversities] = useState([]);
  const [universitiesLoading, setUniversitiesLoading] = useState(false);

  useEffect(() => {
    const fetchUniversities = async () => {
      setUniversitiesLoading(true);
      try {
        const response = ApiCalls.fetchUniversities();
      } catch (error) {
        createToast({
          type: 'error',
          description: `Universities have not been fetched`
        });
      } finally {
        setUniversitiesLoading(false);
      }
    };
    fetchUniversities();
  }, []);

  return universitiesLoading;
};

export default useFetchUniversities;

import styled from 'styled-components';

import { Tab } from 'semantic-ui-react';

export const SettingsFormContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.07);
  padding: 32px;
  border-radius: 3px;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

export const SettingsForms = styled(Tab)`
  &&& {
    max-width: 400px;
    margin: 0 auto;
    > .menu {
      border-bottom: none;
      a.item {
        font-size: 14px;
        color: #212327;
        opacity: 0.5;
        cursor: pointer;
        line-height: 45px;
        padding: 0 22px;
        border: none;
        background: transparent;
        &.active {
          font-weight: 400;
          background: #fff;
          opacity: 1;
          border: none;
          margin: 0;
          border-radius: 0 !important;
          box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
        }
      }
    }
  }
`;

export const SettingsFormsTabPane = styled(Tab.Pane)`
  &&&&& {
    padding: 0;
    border: none;
  }
`;

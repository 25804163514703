import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import CollegesList from 'components/CollegesList';
import { LoadingPlaceholder } from 'src/components/Placeholders';
import { useFetchColleges } from 'src/services/hooks/useFetchColleges';

import {
  SidebarContainer,
  SidebarLogo,
  SidebarSearch,
  University,
  UniversityPreTitle,
  UniversityTitle
} from './styles';

const AdminSidebar = () => {
  const history = useHistory();
  const [colleges, collegesLoading]: any = useFetchColleges();
  const [filteredCollegesData, setFilteredCollegesData] = useState([]);

  useEffect(() => {
    setFilteredCollegesData(colleges);
  }, [colleges]);

  const redirectHandler = () => history.push('/');

  const filterColleges = (e: React.SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    const filteredColleges = colleges.filter(({ college }: any) =>
      college.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCollegesData(filteredColleges);
  };

  return (
    <SidebarContainer>
      <SidebarLogo onClick={redirectHandler} />
      <University>
        <UniversityPreTitle>University:</UniversityPreTitle>
        <UniversityTitle>Arizona State University (ASU)</UniversityTitle>
      </University>
      <SidebarSearch
        placeholder="Quick search"
        icon="search"
        onChange={filterColleges}
      />
      <LoadingPlaceholder loading={collegesLoading}>
        <CollegesList colleges={filteredCollegesData} />
      </LoadingPlaceholder>
    </SidebarContainer>
  );
};

export default React.memo(AdminSidebar);

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5a44b99e-905a-4aac-8abd-8ca2fd7c712e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6yyaUrGuI",
    "aws_user_pools_web_client_id": "1ktmfth6clpgc75t15vage3cva",
    "oauth": {
        "domain": "vextedubfca8b89-bfca8b89-master.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://vextedu.net/waitingResponse/",
        "redirectSignOut": "https://vextedu.net/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "vextedu-20190718202930-hostingbucket-master",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2c2a2qkadswo3.cloudfront.net"
};


export default awsmobile;

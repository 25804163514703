const universities = [
  {
    key: 'asu',
    text: 'ASU',
    value: 'ASU'
  },
  {
    key: 'msu',
    text: 'MSU',
    value: 'MSU'
  },
  {
    key: 'atu',
    text: 'ATU',
    value: 'ATU'
  }
];

export default universities;

import React, { Fragment, useContext, useEffect, useState } from 'react';

import { isObject } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { Select } from 'semantic-ui-react';

import DepartmentsList from 'components/DepartmentsList';
import { buildYearOptions } from 'src/components/CollegeItem/utils';
import { LoadingPlaceholder } from 'src/components/Placeholders';
import { DashboardDataState } from 'src/contexts/DashboardDataProvider';
import { useFetchCollegeInfo } from 'src/services/hooks/useFetchCollegeInfo';
import { useFetchDepartmentsCoursesAndClasses } from 'src/services/hooks/useFetchDepartmentsCoursesAndClasses';

import {
  CollegeDropdownsContainer,
  CollegeDropdownSelect,
  CollegeItemContainer,
  CollegeItemIcon,
  CollegeItemPreTitle,
  CollegeItemTitle,
  CollegeItemTitleContainer,
} from './styles';

const CollegeItem = (props: any) => {
  const { _id, college } = props;

  const location = useLocation();
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);

  const {
    setDashboardDataId,
    setInstitutions,
    collegeYear,
    collegeSemester,
    setCollegeYear,
    setCollegeSemester
  } = useContext(
    DashboardDataState
  );

  const [
    departments,
    departmentsLoading
  ]: any = useFetchDepartmentsCoursesAndClasses(_id);
  const [collegeInfo, collegeInfoLoading]: any = useFetchCollegeInfo(_id);

  // Variables needed for department/year dropdowns
  const semestersOptions = collegeInfo.semesters ? collegeInfo.semesters.map((s: any) => ({
    text: s,
    value: s,
  })) : [];
  const yearOptions = buildYearOptions();

  useEffect(() => {
    const universityInstitutions: any = isObject(props) &&
      Array.isArray(departments) &&
      departmentsLoading && {
        ...props,
        depts: departments
      };

    universityInstitutions && setInstitutions(universityInstitutions);
  }, [props, departments]);

  const toggleCollege = () => setOpen(!isOpen);

  const dashboardRedirectHandler = () =>
    !location.pathname.includes('dashboard') && history.push('/dashboard');

  const getCollegeData = () => {
    if (!isOpen) {
      dashboardRedirectHandler();
      setDashboardDataId(_id);
    }
    toggleCollege();
  };

  return (
    <CollegeItemContainer>
      <CollegeItemTitleContainer onClick={getCollegeData} isOpen={isOpen}>
        <CollegeItemIcon name="caret right" />
        <CollegeItemPreTitle>College:</CollegeItemPreTitle>
        <CollegeItemTitle>{college}</CollegeItemTitle>
      </CollegeItemTitleContainer>
      {isOpen && (
        <Fragment>
          <LoadingPlaceholder loading={collegeInfoLoading}>
            <CollegeDropdownsContainer>
              <CollegeDropdownSelect
                options={semestersOptions}
                placeholder="Pick the semester"
                value={collegeSemester}
                onChange={(e: any, { value }: any) => setCollegeSemester(value)}
              />
              <CollegeDropdownSelect
                options={yearOptions}
                placeholder="Pick the year"
                value={collegeYear}
                onChange={(e: any, { value }: any) => setCollegeYear(value)}
              />
            </CollegeDropdownsContainer>
          </LoadingPlaceholder>
          <LoadingPlaceholder loading={departmentsLoading}>
            <DepartmentsList departments={departments} />
          </LoadingPlaceholder>
        </Fragment>
      )}
    </CollegeItemContainer>
  );
};

export default React.memo(CollegeItem);

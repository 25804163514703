import styled from 'styled-components';

import { Input } from 'semantic-ui-react';

export const PapersSearch = styled(Input)`
  &&&&& {
    height: 52px;
    width: 100%;
    margin-bottom: 20px;
    input {
      border-radius: 30px;
      background: #ffffff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid transparent;
      padding-left: 60px !important;
    }
    i {
      font-size: 16px;
      margin-left: 15px;
    }
  }
`;

export const PapersFound = styled.h4`
  font-size: 16px;
  margin: 0 0 12px 0;
`;

import styled from "styled-components";

// Articles
export const ArticlesCarouselSettings = {
  buttonSetting: {
    placeOn: "middle-inside",
    style: {
      left: {
        color: "#929393",
        background: "#FFFFFF",
        border: "1px solid #e1e4e8",
        borderRadius: "50%"
      },
      right: {
        color: "#929393",
        background: "#FFFFFF",
        border: "1px solid #e1e4e8",
        borderRadius: "50%"
      }
    }
  },
  sliderBoxStyle: {
    background: "transparent",
    height: "400px",
    width: "80%",
    overflow: "hidden"
  },
  dotsSetting: {
    placeOn: "bottom",
    style: {
      dotSize: "5px",
      marginTop: "0px"
    }
  },
  itemsStyle: {
    background: "transparent"
  }
};

export const ArticleCardImage = styled.img`
  height: 70% !important;
`;

export const ArticleCardDesc = styled.p`
  background-color: transparent !important;
  color: #000000 !important;
  font-size: 20px;
  text-align: left !important;
  margin: 0px !important;
  height: 1px;
`;

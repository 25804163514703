import React, { useContext } from 'react';

import get from 'lodash/get';
import { Container, Grid } from 'semantic-ui-react';

import Breadcrumbs from 'src/components/Breadcrumbs';
import FingerprintHeatmap from 'src/components/FingerprintHeatmap';
import MicroData from 'src/components/MicroData';
import RechartBar from 'src/components/RechartBar';
import TopicKeyword from 'src/components/TopicKeyword';
import ContainerOverview from 'src/containers/ContainerOverview';
import { DashboardDataState } from 'src/contexts/DashboardDataProvider';
import { useFetchDashboardCitationIncreaseDecrease } from 'src/services/hooks/useFetchDashboardCitationIncreaseDecrease';
import { useFetchDashboardMicroData } from 'src/services/hooks/useFetchDashboardMicroData';
import { useFetchDashboardSentimentHeatmap } from 'src/services/hooks/useFetchDashboardSentimentHeatmap';
import { useFetchDashboardTopicKeywords } from 'src/services/hooks/useFetchDashboardTopicKeywords';

const Dashboard = () => {
  const { id, breadcrumbInfo, collegeYear, collegeSemester, breadcrumbs } = useContext(DashboardDataState);
  const [microData, isMicroDataLoading]: any = useFetchDashboardMicroData(breadcrumbInfo);
  const [topicKeywords, isTopicKeywordsLoading]: any = useFetchDashboardTopicKeywords(breadcrumbInfo);
  const [sentimentHeatmap, isSentimentHeatmapLoading]: any = useFetchDashboardSentimentHeatmap(breadcrumbInfo, collegeYear, collegeSemester);
  const [citationIncDec, isCitationIncDecLoading]: any = useFetchDashboardCitationIncreaseDecrease(breadcrumbInfo);

  const currentBreadcrumbItem = breadcrumbs ? breadcrumbs[breadcrumbs.length - 1] : undefined;

  const showFingerprintHeatmap = breadcrumbInfo.classid || (collegeYear && collegeSemester);

  return (
    <ContainerOverview>
      <Container>
        <Breadcrumbs search />
        {Object.keys(microData).length > 0 && (
          <Grid doubling columns={2}>
            <Grid.Row>
              <Grid.Column>
                <MicroData
                  data={microData}
                  title="Micro Data"
                  loading={isMicroDataLoading}
                />
                <TopicKeyword
                  data={topicKeywords}
                  title="Topic Keyword"
                  loading={isTopicKeywordsLoading}
                />
              </Grid.Column>
              <Grid.Column>
                <FingerprintHeatmap
                  data={sentimentHeatmap}
                  title="Fingerprint Heatmap"
                  currentBreadcrumbItem={currentBreadcrumbItem}
                  loading={isSentimentHeatmapLoading}
                  showFingerprintHeatmap={showFingerprintHeatmap}
                />
                <RechartBar
                  data={citationIncDec}
                  title="Citation Average per Paper"
                  loading={isCitationIncDecLoading}
                />
                {/*<RechartBar*/}
                {/*  data={citationIncreaseDecrease}*/}
                {/*  title="Average Citation Increase/Decrease"*/}
                {/*  loading={isCitationIncreaseDecreaseLoading}*/}
                {/*/>*/}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Container>
    </ContainerOverview>
  );
};

export default Dashboard;

import React, { useState, useContext } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { DashboardDataState } from 'src/contexts/DashboardDataProvider';
import CourseItem from 'components/CourseItem';

import {
  DepartmentItemContainer,
  DepartmentItemTitle,
  DepartmentItemIcon
} from './styles';

const DepartmentItem = ({ _id, dept, courses }: any) => {
  const location = useLocation();
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const { setDashboardDataId } = useContext(DashboardDataState);

  const toggleDepartment = () => setOpen(!isOpen);

  const dashboardRedirectHandler = () =>
    !location.pathname.includes('dashboard') && history.push('/dashboard');

  const getDepartmentsData = () => {
    if (!isOpen) {
      dashboardRedirectHandler();
      setDashboardDataId(_id);
    }
    toggleDepartment();
  };

  const totalCourses =
    Array.isArray(courses) &&
    courses.map((course: any) => <CourseItem key={course._id} {...course} />);

  return (
    <DepartmentItemContainer isOpen={isOpen}>
      <DepartmentItemTitle onClick={getDepartmentsData}>
        <DepartmentItemIcon name="caret right" />
        {dept}
        <span>({courses.length})</span>
      </DepartmentItemTitle>
      {isOpen && totalCourses}
    </DepartmentItemContainer>
  );
};

export default React.memo(DepartmentItem);

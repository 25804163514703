import styled from 'styled-components';

export const LoadingDivContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
`;

export const LoaderPosition = styled.div`
  margin-top: 20%;
`;

import {
  LOADING_AUTH_START,
  LOADING_AUTH_STOP,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  REGISTER_ADMIN_SUCCESS,
  REGISTER_ADMIN_FAILED,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  SAVE_USER,
  SAVE_USER_FAILED,
  SAVE_USER_SUCCESS,
  UPDATE_ATTR_USER,
  UPDATE_ATTR_USER_FAILED,
  UPDATE_ATTR_USER_SUCCESS
} from '../types';

import { IAuth } from 'src/interfaces/ReducersInterface';

const initialState: IAuth = {
  loggedIn: false,
  resetPassword: false,
  user: null
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    // Save user
    case SAVE_USER:
      return {
        ...initialState
      };
    case SAVE_USER_SUCCESS:
      return {
        ...initialState,
        loggedIn: true,
        user: { ...action.attributes }
      };
    case SAVE_USER_FAILED:
      return {
        ...initialState,
        loggedIn: false
      };
    // LOGIN
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.attributes
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        loggedIn: false
      };

    // SIGN UP
    case REGISTER_ADMIN_SUCCESS:
      return {
        ...state,
        loggedIn: true
      };
    case REGISTER_ADMIN_FAILED:
      return {
        ...state,
        loggedIn: true
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loggedIn: false
      };
    case REGISTER_USER_FAILED:
      return {
        ...state,
        loggedIn: false
      };

    // LOG OUT
    case LOGOUT_USER_SUCCESS:
      return {
        ...initialState
      };

    // FORGOT PASSWORD SUBMIT
    case UPDATE_ATTR_USER:
      return {
        ...state
      };
    case UPDATE_ATTR_USER_SUCCESS:
      return {
        ...state,

        user: { ...state.user, ...action.attributes }
      };
    case UPDATE_ATTR_USER_FAILED:
      return {
        ...state
      };

    // CUSTOM
    case LOADING_AUTH_START:
      return {
        ...state
      };

    case LOADING_AUTH_STOP:
      return {
        ...state
      };
    default:
      return state;
  }
};

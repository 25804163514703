import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PropperRoute = ({ component, authenticated, ...rest }: any) => (
  <Route
    {...rest}
    render={(props: any) => {
      if (authenticated) {
        return <Redirect to={{ pathname: '/' }} />;
      }

      return React.createElement(component, props);
    }}
  />
);

export { PropperRoute };

import { toast } from "react-semantic-toasts";
import { IToast } from "src/interfaces/ToastInterface";

const DEFAULT_TOASTS: any = {
  warning: {
    type: "warning",
    icon: "warning circle",
    title: "Warning",
    description: "Warning message",
    animation: "bounce",
    time: 10000
  },
  info: {
    type: "info",
    icon: "warning circle",
    title: "Info",
    description: "Info message",
    animation: "bounce",
    time: 10000
  },
  error: {
    type: "error",
    icon: "warning circle",
    title: "Error",
    description: "Error message",
    animation: "bounce",
    time: 10000
  }
};

export const createToast = (data: IToast) => {
  toast({
    ...DEFAULT_TOASTS[data.type],
    ...data
  });
};

import React from 'react';
import { Grid } from 'semantic-ui-react';

interface IProps {
  children: any;
}

const ScreenContentContainer = (props: IProps) => (
  <Grid columns={3} stackable>
    <Grid.Row>
      <Grid.Column />
      <Grid.Column>{props.children}</Grid.Column>
      <Grid.Column />
    </Grid.Row>
  </Grid>
);

export default ScreenContentContainer;

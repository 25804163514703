import styled from 'styled-components';

export const Checkbox = styled.div`
  position: relative;
  label {
    display: block;
    position: relative;
    font-size: 15px;
    padding-left: 25px;
    cursor: pointer;
    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 17px;
      height: 17px;
      background: #fff;
      border-radius: 0.21428571rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-transition: border 0.1s ease, opacity 0.1s ease,
        -webkit-transform 0.1s ease, -webkit-box-shadow 0.1s ease;
      -webkit-transition: border 0.1s ease, opacity 0.1s ease,
        box-shadow 0.1s ease, -webkit-transform 0.1s ease;
      transition: border 0.1s ease, opacity 0.1s ease, box-shadow 0.1s ease,
        -webkit-transform 0.1s ease;
      transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease,
        box-shadow 0.1s ease;
      transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease,
        box-shadow 0.1s ease, -webkit-transform 0.1s ease;
      border: 1px solid #adb1b9;
    }
    &:before {
      content: '';
      opacity: 1;
    }
    &:after {
      content: '✔';
      font-size: 12px;
      font-weight: 600;
      opacity: 0;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    &:checked {
      & + label {
        & {
          &:before {
            opacity: 0;
          }
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
`;

import React, { useState } from 'react';

import { Input, Select } from 'semantic-ui-react';

import {
  DeletePaperIcon,
  UploadedPapersFormField,
  UploadedPapersFormGroup
} from 'components/UploadedPapers/styles';
import { IOptionProp } from 'src/interfaces/GeneralInterface';
import { useFetchDepartmentsCoursesAndClasses } from 'src/services/hooks/useFetchDepartmentsCoursesAndClasses';

interface IProps {
  title: string;
  id: string;
  collegeId?: string;
  deptId?: string;
  courseId?: string;
  classId?: string;
  collegeOptions: IOptionProp[] | [];
  index: number;
  clipboardPaperFieldChange: (
    value: string,
    index: number,
    field: string
  ) => void;
  clipboardPaperCollegeChange: (value: string, index: number) => void;
  clipboardPaperDeptChange: (value: string, index: number) => void;
  clipboardPaperCourseChange: (value: string, index: number) => void;
  clipboardPaperClassChange: (value: string, index: number) => void;
  deleteClipboardPaper: (id: string) => void;
}

const UploadedClipboardPaper = ({
  title,
  id,
  collegeId,
  deptId,
  classId,
  courseId,
  index,
  collegeOptions,
  clipboardPaperFieldChange,
  clipboardPaperCourseChange,
  clipboardPaperClassChange,
  clipboardPaperDeptChange,
  clipboardPaperCollegeChange,
  deleteClipboardPaper
}: IProps) => {

  const [departments, departmentsLoading]: any = useFetchDepartmentsCoursesAndClasses(collegeId);

  const departmentsOptions = departments.map((d: any) => ({ text: d.dept, value: d._id }));

  const currentDept = deptId ? departments.find((d: any) => d._id === deptId) : null;
  const coursesOptions = currentDept ? currentDept.courses.map((c: any) => ({ text: c.course, value: c._id })) : [];

  const currentCourse = courseId ? currentDept.courses.find((c: any) => c._id === courseId ) : null;
  const classesOptions = currentCourse ? currentCourse.classes.map((cl: any) => ({ text: cl.class,  value: cl._id })) : [];

  const handleTitleSelect = (e: React.ChangeEvent<HTMLInputElement>) => clipboardPaperFieldChange(e.target.value, index, 'title')

  const handleCollegeSelect = (e: any, { value }: any) => clipboardPaperCollegeChange(value, index);

  const handleDeptSelect = (e:any, { value }: any) => clipboardPaperDeptChange(value, index);

  const handleClassSelect = (e: any, { value }: any) => clipboardPaperClassChange(value, index);

  const handleCourseSelect = (e: any, { value }: any) => clipboardPaperCourseChange(value, index);

  return (
    <UploadedPapersFormGroup>
      <UploadedPapersFormField
        control={Input}
        icon="cloud upload"
        iconPosition="left"
        placeholder="Enter title of paper (required)"
        value={title}
        onChange={handleTitleSelect}
      />
      <UploadedPapersFormField
        control={Select}
        placeholder="Select college (required)"
        options={collegeOptions}
        onChange={handleCollegeSelect}
        fluid={true}
        value={collegeId}
        search
      />
      <UploadedPapersFormField
        control={Select}
        placeholder="Select department (required)"
        onChange={handleDeptSelect}
        options={departmentsOptions}
        value={deptId}
        loading={departmentsLoading}
        search
      />
      <UploadedPapersFormField
        control={Select}
        placeholder="Select course (required)"
        onChange={handleCourseSelect}
        options={coursesOptions}
        value={courseId}
        search
      />
      <UploadedPapersFormField
        control={Select}
        value={classId}
        onChange={handleClassSelect}
        placeholder="Select class (required)"
        options={classesOptions}
        search
      />
      <DeletePaperIcon
        link
        name="delete"
        onClick={() => deleteClipboardPaper(id)}
      />
    </UploadedPapersFormGroup>
  );
};

export default React.memo(UploadedClipboardPaper);

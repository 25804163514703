import React, { useContext } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { SelectClasses } from './styles';
import { DashboardDataState } from 'src/contexts/DashboardDataProvider';

const CourseClasses = ({ classes, course }: any) => {
  const history = useHistory();
  const location = useLocation();
  const { setDashboardDataId } = useContext(DashboardDataState);

  const classesOptions =
    Array.isArray(classes) &&
    classes.reduce((accumulator, currentValue) => {
      const { _id, year, semester, section } = currentValue;
      return [
        ...accumulator,
        {
          key: _id,
          value: `${course}: ${year}: ${semester}: ${section}`,
          text: `${course}: ${year}: ${semester}: ${section}`
        }
      ];
    }, []);

  const selectClassHandler = (e: any): any => {
    location.pathname !== '/dashboard' && history.push('/dashboard');
    const { target } = e;

    const selectedOption = classesOptions.filter((option: any) => {
      const targetValue = target.children[0]
        ? target.children[0].innerHTML
        : target.innerHTML;
      return option.value.includes(targetValue);
    });

    Array.isArray(selectedOption) &&
      selectedOption.length > 0 &&
      setDashboardDataId(selectedOption[0].key);
  };

  return (
    <SelectClasses
      placeholder="Select class"
      options={classesOptions}
      onChange={selectClassHandler}
    />
  );
};

export default React.memo(CourseClasses);

import React from 'react';
import UploadButton from 'src/components/UploadButton';
import { ConversionLink, UploadDocumentInfo, UploadPapersBox } from './styles';

const UploadPapersCover = () => (
  <UploadPapersBox>
    <UploadDocumentInfo>
      Your paper must have at least one citation. Cite at least one author in
      your paper and include the bibliography or works cited as the concluding
      section of your paper. PDF file upload only.
    </UploadDocumentInfo>
    <ConversionLink
      target="_blank"
      href="https://support.office.com/en-us/article/save-or-convert-to-pdf-or-xps-d85416c5-7d77-4fd6-a216-6f4bf7c7c110"
    >
      Click here to see how to convert your Word file to a PDF
    </ConversionLink>
    <ConversionLink
      target="_blank"
      href="https://oregonstate.teamdynamix.com/TDClient/KB/ArticleDet?ID=45811"
      spacing="true"
    >
      Click here to see how to convert your Google Doc to a PDF
    </ConversionLink>

    <UploadButton>Upload</UploadButton>
  </UploadPapersBox>
);

export default UploadPapersCover;

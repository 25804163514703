import styled from 'styled-components';

import { Grid } from 'semantic-ui-react';

export const PaperFeedbackContainer = styled.div`
  margin-bottom: 16px;
`;

export const PaperFeedbackTitle = styled.h4`
  margin-bottom: 18px;
`;

export const PaperFeedbackGrid = styled(Grid)`
  &&& {
    background: #f4f5f7;
    margin: 0;
  }
`;

export const PaperFeedbackColumn = styled(Grid.Column)`
  &&&&& {
    padding: 18px 18px 28px;
  }
`;

export const ColumnTitle = styled.p`
  color: #212327;
  margin-bottom: 14px;
`;

export const ColumnParagraph = styled.p`
  color: #74777b;
`;

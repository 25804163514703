export default {
  translation: {
    // General
    by: 'by',
    or: 'or',
    name: 'Name',
    message: 'Message',
    code: 'Code',
    send: 'Send',
    submit: 'Submit',

    // Home Page
    'Home Title': 'A New Era of Academic Integrity',
    'Home Title 2': 'Interact. Measure. Map. Improve',
    'Home Subtitle': 'The 20th century plagiarism detection paradigm is dead.',
    'Home Subtitle 2':
      ' A new ethic of openness for student growth, data-driven practices, and academic integrity has arrived on campus.',
    'Home Subheader Title':
      'VEXT offers an ethical approach to idea generation, data visualization, and plagiarism checking',
    'Home Subheader Subtitle':
      'Your students and faculty want openness. VEXT aligns with their values.',
    'Home Subheader Subtitle 2':
      'VEXT gives you control, openness, and transparency. Evolve from Plagiarism Detection to Data Driven Insights.',
    // Paper
    'Paper Of': 'VEXT Paper Fingerprint of',
    'Paper Fingerprint': 'Paper Fingerprint',
    'Topic Keywords': 'Topic Key Words',
    'Positive Sentiment': 'Positive Sentiment',
    'Negative Sentiment': 'Negative Sentiment',
    'Paper Authors': 'Authors',
    'Paper Custom Tags': 'Add custom tags to your file. (Optional)',
    'Paper Title': 'Paper Title',
    'Your Top Keywords': 'Your top keywords',
    'Peers To Connect': 'Peers to connect with',
    'Trending In': 'Trending in',
    'Macro Curricular Trends': 'How to read your VEXT Paper Fingerprint',
    'Trending Academic Topics': 'Citations and Key Topics',
    'Frequently Cited Institutions': 'Sentiment Analysis and Critical Thinking',
    'Peer Search': 'Peer Search',
    'Your Papers': 'Your Papers',
    'Upload Document': 'Upload Document',

    // Auth
    'Old Password': 'Old password',
    'New Password': 'New password',
    'Confirm Password': 'Repeat new password',
    'Repeat Password': 'Repeat password',
    'Reset Password': 'Reset Password',
    password: 'Password',
    email: 'Email',
    'First Name': 'First name',
    'Last Name': 'Last name',

    'Change Password': 'Change Password',
    'Forgot Password': 'Forgot password',
    'Log In': 'Login',
    Register: 'Register',
    'Remember Me': 'Remember me',
    'Accept Terms': 'I have read and accept the VEXT terms and conditions',
    'Contact To Enable': 'To enable this functionality, contact VEXT',
    'Upload Papers Placeholder': 'You have 0 uploaded papers',

    // Attributes
    'Select Discipline': 'Select your primary discipline',
    'Institution Zip': 'Institution Zip',
    'Select Institution Code': 'Home country of your institution.',
    'Institution Name':
      'Name of your current institution (e.g. Springfield High, Stanford University, Lawrence Livermore National Laboratory, etc.).',
    'Select Home Code': 'Your home country code',

    // Footer
    Sitemap: 'Sitemap',
    Categories: 'Categories',
    'Social Networks': 'Social Networks',
    'About Us': 'About Us',

    // Technology
    Technology: 'Technology',
    'Technology Subtitle':
      'VEXT applies search technology to make the universe of academic outputs open and discoverable. In addition, VEXT deploys a suite of machine learning algorithms to visualize the “DNA” of your papers and pedagogy. At the heart of our analytics is the “paper fingerprint”, which surfaces themes, patterns, and sentiment towards the ideas in your paper. Taken altogether VEXT a is platform that allows you to become more data-driven in your practice as a student, teacher, administrator of writing.',

    // Custom
    'Terms And Conditions': 'Terms and conditions',
    Copyright: '© Copyright 2019 Vext',

    // Messages
    'Email sent': 'Email has been successfully sent',
    'Email not sent': 'Email has not been sent'
  }
};

import React, { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Grid, Icon } from "semantic-ui-react";

import {
  PremiumPaperItemContainer,
  PremiumPaperItemTitle,
  BlurContent,
  ContactContainer
} from "./styles";

const PremiumPaperPlaceholder = (props: any) => {
  const [mouseIn, setMouseIn] = useState(false);

  useEffect(() => {
    const isMobile =
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1;

    if (isMobile) {
      setMouseIn(true);
    }
  }, []);

  return (
    <div
      onMouseEnter={() => setMouseIn(true)}
      onMouseLeave={() => setMouseIn(false)}
    >
      {mouseIn ? <ContactUsArea history={props.history} /> : null}
      <BlurContent>
        <Grid doubling columns={3}>
          <Grid.Column>
            <PremiumPaperItem />
          </Grid.Column>

          <Grid.Column>
            <PremiumPaperItem />
          </Grid.Column>

          <Grid.Column>
            <PremiumPaperItem />
          </Grid.Column>

          <Grid.Column>
            <PremiumPaperItem />
          </Grid.Column>

          <Grid.Column>
            <PremiumPaperItem />
          </Grid.Column>

          <Grid.Column>
            <PremiumPaperItem />
          </Grid.Column>
        </Grid>
      </BlurContent>
    </div>
  );
};

const PremiumPaperItem = () => {
  return (
    <PremiumPaperItemContainer textAlign="center">
      <Icon name="file alternate" size="huge" color="grey" />
      <PremiumPaperItemTitle>Paper's Title</PremiumPaperItemTitle>
    </PremiumPaperItemContainer>
  );
};

const ContactUsArea = (props: any) => {
  const { t } = useTranslation();

  return (
    <ContactContainer onClick={() => props.history.push("/contact")}>
      <p>{t("Contact To Enable")}</p>
    </ContactContainer>
  );
};

export default withRouter(PremiumPaperPlaceholder);

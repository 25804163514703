import styled from 'styled-components';

interface IHeatmapListItemProps {
  fingerprint: number;
}

interface IHeatmapFingerprint {
  positive?: boolean;
  negative?: boolean;
}

export const HeatmapStatistics = styled.div`
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.14);
`;

export const HeatmapClassStatistics = styled.p`
  margin-bottom: 8px;
`;

export const HeatmapFingerprintStatistics = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`;

export const HeatmapFingerprint = styled.li`
  list-style: none;
  margin-right: 15px;
  position: relative;
  padding-left: 22px;
  margin-bottom: 2px;
  &:before {
    content: '';
    height: 16px;
    width: 16px;
    display: block;
    border-radius: 3px;
    background: ${({ positive, negative }: IHeatmapFingerprint) =>
      positive ? '#448CED' : negative ? '#DE594D' : '#DFE3EA'};
    position: absolute;
    top: 2px;
    left: 0;
  }
  b {
    margin-left: 4px;
  }
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const HeatmapList = styled.ul`
  padding: 0;
  margin: 0 0 12px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const HeatmapListGroup = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-inline-start: 0px;
`;

export const HeatmapListItem = styled.li`
  list-style: none;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  border: 1px solid white;
  background: #dfe3ea;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    z-index: 10;
    opacity: ${({ fingerprint }: IHeatmapListItemProps) =>
      Math.abs(fingerprint)};
    background: ${({ fingerprint }: IHeatmapListItemProps) =>
      fingerprint > 0 ? '#448CED' : fingerprint < 0 ? '#DE594D' : '#DFE3EA'};
  }
`;

import React, { Fragment, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Icon, Menu, Responsive, Sidebar } from 'semantic-ui-react';

import { ReactComponent as Logo } from 'src/assets/logo.svg';
import { Header, MenuItem, MenuLogo } from './styles';

const NavBarMobile = ({
  children,
  onPusherClick,
  onToggle,
  items,
  visible,
  history
}: any) => (
  <Sidebar.Pushable>
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      vertical
      visible={visible}
    >
      {items.map(({ route, key, url }: any) => (
        <Menu.Item
          name={route}
          key={key}
          onClick={() => {
            history.push(url);
            onPusherClick();
          }}
        />
      ))}
    </Sidebar>
    <Sidebar.Pusher
      dimmed={visible}
      onClick={onPusherClick}
      style={{ minHeight: '100vh' }}
    >
      <Header>
        <Menu secondary>
          <MenuLogo to="/">
            <Logo />
          </MenuLogo>
          <Menu.Item onClick={onToggle} position="right">
            <Icon name="sidebar" />
          </Menu.Item>
        </Menu>
      </Header>
      {children}
    </Sidebar.Pusher>
  </Sidebar.Pushable>
);

const NavBarDesktop = ({ items, isAdmin }: any) => (
  <Header isAdmin={isAdmin}>
    <Menu secondary>
      {!isAdmin && (
        <MenuLogo to="/">
          <Logo />
        </MenuLogo>
      )}
      <Menu.Menu>
        {Array.isArray(items) &&
          items.map(({ url, key, route }: any) => (
            <MenuItem
              to={url}
              key={key}
              activeStyle={{
                color: '#448CED'
              }}
            >
              {route}
            </MenuItem>
          ))}
      </Menu.Menu>
    </Menu>
  </Header>
);

const HeaderNavbar = ({ children, items, isAdmin }: any) => {
  const [visible, setVisible] = useState(false);

  const history = useHistory();

  const handlePusherClick = () => visible && setVisible(false);

  const handleToggle = () => setVisible(!visible);

  return (
    <Fragment>
      <Responsive {...Responsive.onlyMobile}>
        <NavBarMobile
          onPusherClick={handlePusherClick}
          onToggle={handleToggle}
          items={items}
          visible={visible}
          history={history}
        >
          {children}
        </NavBarMobile>
      </Responsive>
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <NavBarDesktop items={items} isAdmin={isAdmin} />
        {children}
      </Responsive>
    </Fragment>
  );
};

export default HeaderNavbar;

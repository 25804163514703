import React from 'react';
import { useTranslation } from 'react-i18next';
import { UploadPapersWrapper, UploadPapersHeading } from './styles';
import { ReactComponent as PapersIcon } from 'src/assets/paper/papers.svg';

import UploadPapersCover from 'src/containers/UploadPapers';

const UploadDocumentsPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <UploadPapersWrapper>
      <PapersIcon />

      <UploadPapersHeading>
        {t('Upload Papers Placeholder')}
      </UploadPapersHeading>

      <UploadPapersCover />
    </UploadPapersWrapper>
  );
};

export default UploadDocumentsPlaceholder;

import React from 'react';
import { Container, Divider, Header } from 'semantic-ui-react';

const TermsAndConditions = () => {
  return (
    <Container>
      <Header size="huge" textAlign="center">
        Terms and Conditions
      </Header>
      <Divider />

      <TermsSection
        title="Welcome to VEXT"
        content={
          <>
            <p>
              Thanks for using our products and services (“Services”). The
              Services are provided by VEXT, Inc (“VEXT”), located in San
              Carlos, CA, United States.
            </p>

            <p>
              By using our Services, you are agreeing to these terms. Please
              read them carefully.
            </p>

            <p>
              Our Services are innovative, diverse, and evolving, so sometimes
              additional terms or product requirements may apply. Additional
              terms will be available as Services become differentiated from
              core Service, and those additional terms become part of your
              agreement with us if you use those Services.
            </p>
          </>
        }
      />

      <TermsSection
        title="Using VEXT Services"
        content={
          <>
            <p>
              You must follow all the policies made available to you within the
              Services.
            </p>
            <p>
              Don’t misuse VEXT Services. For example, don’t interfere with our
              Services or try to access them using a method other than the
              interface and the instructions that we provide. You may use our
              Services only as permitted by law, including applicable export and
              re-export control laws and regulations. We may suspend or stop
              providing our Services to you if you do not comply with our terms
              or policies or if we are investigating suspected misconduct.
            </p>
            <p>
              Using our Services does not give you ownership of any intellectual
              property rights in our Services or the content you access. You may
              not use content from our Services unless you obtain permission
              from its owner or are otherwise permitted by law. These terms do
              not grant you the right to use any branding or logos used in our
              Services. Don’t remove, obscure, or alter any legal notices
              displayed in or along with our Services.
            </p>
            <p>
              Our Services display some content that is not VEXT’s. This content
              is the sole responsibility of the person and/or entity that makes
              it available. We may review content to determine whether it is
              threatening, illegal or violates our policies, and we may remove
              or refuse to display content that we reasonably believe violates
              our policies or the law. But that does not necessarily mean that
              we review content, so do not assume that we do.
            </p>
            <p>
              In connection with your use of the Services, we may send you
              service announcements, administrative messages, and other
              information. You may opt out of some of those communications.
            </p>
            <p>
              Some of our Services are available on mobile devices. Do not use
              such Services in a way that distracts you and prevents you from
              obeying traffic or safety laws.
            </p>
          </>
        }
      />

      <TermsSection
        title="Your VEXT Account"
        content={
          <>
            <p>
              You may need a VEXT Account in order to use some of our Services.
              You may create your own VEXT Account, or your VEXT Account may be
              assigned to you by an administrator, such as your employer or
              educational institution. If you are using a VEXT Account assigned
              to you by an administrator, different or additional terms may
              apply and your administrator may be able to access or disable your
              account.
            </p>

            <p>
              To protect your VEXT Account, keep your password confidential. You
              are responsible for the activity that happens on or through your
              VEXT Account. Try not to reuse your VEXT Account password on
              third-party applications. If you learn of any unauthorized use of
              your password or VEXT Account, contact VEXT or your VEXT
              administrator.
            </p>
          </>
        }
      />

      <TermsSection
        title="Privacy and Copyright Protection"
        content={
          <>
            <p>
              VEXT’s{' '}
              <a href="https://www.freeprivacypolicy.com/privacy/view/905bf13961a1f1bd3f36c3b645c59f1a">
                privacy policies
              </a>{' '}
              explain how we treat your personal data and protect your privacy
              when you use our Services. By using our Services, you agree that
              VEXT can use such data in accordance with our privacy policies.
            </p>

            <p>
              We respond to notices of alleged copyright infringement and
              terminate accounts of repeat infringers according to the process
              set out in the U.S. Digital Millennium Copyright Act.
            </p>
            <p>
              We provide information to help copyright holders manage their
              intellectual property online. If you think somebody is violating
              your copyrights and want to notify us, you can submit notices and
              to our <a href="/contact">Contact Us.</a>
            </p>
          </>
        }
      />

      <TermsSection
        title="Your Content in our Services"
        content={
          <>
            <p>
              Aspects of our Services allow you to upload, submit, store, send
              or receive content. You retain ownership of any intellectual
              property rights that you hold in that content. In short, what
              belongs to you stays yours.
            </p>
            <p>
              When you upload, submit, store, send or receive content to or
              through our Services, you give VEXT (and those we work with) a
              worldwide license to use, host, store, reproduce, modify, create
              derivative works (such as those resulting from translations,
              adaptations or other changes we make so that your content works
              better with our Services), communicate, publish, publicly perform,
              publicly display and distribute such content. The rights you grant
              in this license are for the limited purpose of operating,
              promoting, and improving our Services, and to develop new ones.
              This license continues even if you stop using our Services (for
              example, for a business listing you have added to VEXT Maps). Some
              Services may offer you ways to access and remove content that has
              been provided to that Service. Also, in some of our Services,
              there are terms or settings that narrow the scope of our use of
              the content submitted in those Services. Make sure you have the
              necessary rights to grant us this license for any content that you
              submit to our Services.
            </p>
            <p>
              Our automated systems analyze your content to provide you
              personally relevant product features, such as customized search
              results and tailored analytics. This analysis occurs as the
              content is sent, received, and when it is stored.
            </p>
            <p>
              If you have a VEXT Account, we may display your Profile name,
              Profile photo, and actions you take on VEXT or on third-party
              applications connected to your VEXT Account in our Services,
              including displaying in ads and other commercial contexts. We will
              respect the choices you make to limit sharing or visibility
              settings in your VEXT Account. For example, you can choose your
              settings so your name and photo do not appear in an ad.
            </p>
            <p>
              You can find more information about how VEXT uses and stores
              content in the privacy policy or additional terms for particular
              Services. If you submit feedback or suggestions about our
              Services, we may use your feedback or suggestions without
              obligation to you.
            </p>
          </>
        }
      />

      <TermsSection
        title="About Software in our Services"
        content={
          <>
            <p>
              When a Service requires or includes downloadable software, this
              software may update automatically on your device once a new
              version or feature is available. Some Services may let you adjust
              your automatic update settings.
            </p>
            <p>
              VEXT gives you a personal, worldwide, royalty-free, non-assignable
              and non-exclusive license to use the software provided to you by
              VEXT as part of the Services. This license is for the sole purpose
              of enabling you to use and enjoy the benefit of the Services as
              provided by VEXT, in the manner permitted by these terms. You may
              not copy, modify, distribute, sell, or lease any part of our
              Services or included software, nor may you reverse engineer or
              attempt to extract the source code of that software, unless laws
              prohibit those restrictions or you have our written permission.
            </p>
            <p>
              Open source software is important to us. Some software used in our
              Services may be offered under an open source license that we will
              make available to you. There may be provisions in the open source
              license that expressly override some of these terms.
            </p>
          </>
        }
      />

      <TermsSection
        title="Modifying and Terminating our Services"
        content={
          <>
            <p>
              We are constantly changing and improving our Services. We may add
              or remove functionalities or features, and we may suspend or stop
              a Service altogether.
            </p>
            <p>
              You can stop using our Services at any time, although we’ll be
              sorry to see you go. VEXT may also stop providing Services to you,
              or add or create new limits to our Services at any time.
            </p>
            <p>
              We believe that you own your data and preserving your access to
              such data is important. If we discontinue a Service, where
              reasonably possible, we will give you reasonable advance notice
              and a chance to get information out of that Service.
            </p>
          </>
        }
      />

      <TermsSection
        title="Our Warranties and Disclaimers"
        content={
          <>
            <p>
              We provide our Services using a commercially reasonable level of
              skill and care and we hope that you will get great value from
              using them. But there are certain things that we don’t promise
              about our Services.
            </p>
            <p>
              OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OR ADDITIONAL
              TERMS, NEITHER VEXT NOR ITS SUPPLIERS OR DISTRIBUTORS MAKE ANY
              SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE DON’T MAKE
              ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES, THE
              SPECIFIC FUNCTIONS OF THE SERVICES, OR THEIR RELIABILITY,
              AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE PROVIDE THE
              SERVICES “AS IS”.
            </p>
            <p>
              SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE
              IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE
              EXCLUDE ALL WARRANTIES.
            </p>
          </>
        }
      />

      <TermsSection
        title="Liability for our Services"
        content={
          <>
            <p>
              WHEN PERMITTED BY LAW, VEXT, AND VEXT’S SUPPLIERS AND
              DISTRIBUTORS, WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES,
              OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL,
              EXEMPLARY, OR PUNITIVE DAMAGES.
            </p>
            <p>
              TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF VEXT, AND
              ITS SUPPLIERS AND DISTRIBUTORS, FOR ANY CLAIMS UNDER THESE TERMS,
              INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU
              PAID US TO USE THE SERVICES (OR, IF WE CHOOSE, TO SUPPLYING YOU
              THE SERVICES AGAIN).
            </p>
            <p>
              IN ALL CASES, VEXT, AND ITS SUPPLIERS AND DISTRIBUTORS, WILL NOT
              BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY
              FORESEEABLE.
            </p>
          </>
        }
      />
      <TermsSection
        title="Business uses of our Services"
        content={
          <>
            <p>
              If you are using our Services on behalf of a business, that
              business accepts these terms. It will hold harmless and indemnify
              VEXT and its affiliates, officers, agents, and employees from any
              claim, suit or action arising from or related to the use of the
              Services or violation of these terms, including any liability or
              expense arising from claims, losses, damages, suits, judgments,
              litigation costs and attorneys’ fees.
            </p>
          </>
        }
      />

      <TermsSection
        title="About these Terms"
        content={
          <>
            <p>
              We may modify these terms or any additional terms that apply to a
              Service to, for example, reflect changes to the law or changes to
              our Services. You should look at the terms regularly. We’ll post
              notice of modifications to these terms on this page. We’ll post
              notice of modified additional terms in the applicable Service.
              Changes will not apply retroactively and will become effective no
              sooner than fourteen days after they are posted. However, changes
              addressing new functions for a Service or changes made for legal
              reasons will be effective immediately. If you do not agree to the
              modified terms for a Service, you should discontinue your use of
              that Service.
            </p>
            <p>
              If there is a conflict between these terms and the additional
              terms, the additional terms will control for that conflict.
            </p>
            <p>
              These terms control the relationship between VEXT and you. They do
              not create any third party beneficiary rights.
            </p>
            <p>
              If you do not comply with these terms, and we don’t take action
              right away, this doesn’t mean that we are giving up any rights
              that we may have (such as taking action in the future).
            </p>
            <p>
              If it turns out that a particular term is not enforceable, this
              will not affect any other terms.
            </p>
            <p>
              The laws of California, U.S.A., excluding California’s conflict of
              laws rules, will apply to any disputes arising out of or relating
              to these terms or the Services. All claims arising out of or
              relating to these terms or the Services will be litigated
              exclusively in the federal or state courts of Santa Clara County,
              California, USA, and you and VEXT consent to personal jurisdiction
              in those courts.
            </p>
            <p>
              For information about how to contact VEXT, please visit our{' '}
              <a href="/contact">contact page</a>.
            </p>
          </>
        }
      />
      <TermsSection title="" content={<> </>} />
      <TermsSection title="" content={<> </>} />
    </Container>
  );
};

const TermsSection = ({
  title,
  content
}: {
  title: string;
  content: JSX.Element;
}) => (
  <>
    <Header size="medium">{title}</Header>
    {content}
  </>
);
export default TermsAndConditions;

import React from "react";
import ReactSVG from "react-svg";

interface IProps {
  src: string;
  width?: string | "100%";
  height?: string | "100%";
}

const Svg = ({ src, width, height }: IProps) => (
  <ReactSVG src={src} svgStyle={{ width, height }} />
);

export default Svg;

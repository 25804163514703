import get from 'lodash/get';
import { useEffect, useState } from 'react';

import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';

const useFetchPapersByUserId = (userId: string) => {
  const [papers, setPapers] = useState([]);
  const [papersLoading, setPapersLoading] = useState(false);

  const fetchPapers = async (isCancelled: boolean, userId: string) => {
    setPapersLoading(true);
    try {
      if (!isCancelled) {
        const response = await ApiCalls.getPapersByUserId(userId);
        const data = response.data;
        const dataPapers = get(data, 'data', []);
        setPapers(dataPapers);
      }
    } catch (e) {
      if (!isCancelled) {
        createToast({
          type: 'error',
          description: `Paper(s) have not been fetched`
        });
      }
    } finally {
      setPapersLoading(false);
    }
  }

  useEffect(() => {
    let isCancelled = false;
    fetchPapers(isCancelled, userId);
    return () => {
      isCancelled = true;
    };
  }, [userId]);

  return [papers, papersLoading, () => fetchPapers(false, userId)];
};

export default useFetchPapersByUserId;

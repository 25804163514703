import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Grid, Divider } from 'semantic-ui-react';

import { FooterContainer, SiteMapContainer, TACContainer } from './styles';

interface FooterItem {
  title: string;
  content: string;
}

const FooterSection = () => {
  return (
    <FooterContainer>
      <TermsAndConditions />
    </FooterContainer>
  );
};

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <TACContainer>
      <Grid columns={4} doubling>
        <Grid.Column>
          <a href='/terms-and-conditions'>
            <b>{t('Terms And Conditions')}</b>
          </a>
        </Grid.Column>

        <Grid.Column>
          <a href='https://www.freeprivacypolicy.com/privacy/view/905bf13961a1f1bd3f36c3b645c59f1a'>
            <b>{t('Privacy Policy')}</b>
          </a>
        </Grid.Column>

        <Grid.Column>
          <a href='/contact'>
            <b>{t('Universities & Administrators')}</b>
          </a>
        </Grid.Column>

        <Grid.Column>
          <a href='https://docs.google.com/document/d/1dj-tbXHvscRvwV3Lax2T4kNg2EIjlj-uquqQwn_p_-Q/edit'>
            <b>{t('Online Master Service Agreement')}</b>
          </a>
        </Grid.Column>
      </Grid>
    </TACContainer>
  );
};

const SiteMap = () => {
  const { t } = useTranslation();
  return (
    <SiteMapContainer>
      <Grid columns={4} doubling>
        <Grid.Column>
          <FooterItemColumn
            title={t('Sitemap')}
            content={t('Sitemap Content')}
          />
        </Grid.Column>
        <Grid.Column>
          <FooterItemColumn
            title={t('Categories')}
            content={t('Categories Content')}
          />
        </Grid.Column>
        <Grid.Column>
          <FooterItemColumn
            title={t('Social Networks')}
            content={t('Social Networks Content')}
          />
        </Grid.Column>
        <Grid.Column>
          <FooterItemColumn
            title={t('About Us')}
            content={t('Social Networks Content')}
          />
        </Grid.Column>
      </Grid>
    </SiteMapContainer>
  );
};

const FooterItemColumn = ({ title, content }: FooterItem) => (
  <Fragment>
    <b>{title}</b>
    <p style={{ opacity: 0.9, fontWeight: 400 }}>{content}</p>
  </Fragment>
);

export default withRouter(FooterSection);

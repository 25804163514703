import { IBreadcrumbInfo } from 'src/interfaces/DashboardDataInterface';

export const buildDashboardParamUrl = (breadcrumbInfo: IBreadcrumbInfo) => {
  const { classid, courseid, deptid, collegeid } = breadcrumbInfo || {};
  if(classid) {
    return `classid=${classid}`;
  }
  if(courseid) {
    return `courseid=${courseid}`;
  }
  if(deptid) {
    return `deptid=${deptid}`;
  }
  if(collegeid) {
    return `collegeid=${collegeid}`;
  }
}
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Global Styles
import './index.css';

// Semantic UI
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import 'semantic-ui-css/semantic.min.css';

import * as serviceWorker from './services/serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();

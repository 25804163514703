export const buildYearOptions = () => {
  const yearOptions = [];
  const currentYear = new Date().getFullYear();
  for(let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({
      text: year,
      value: year,
    });
  }
  return yearOptions;
};

import React, { useContext } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import CourseClasses from 'src/components/CourseClasses';
import { DashboardDataState } from 'src/contexts/DashboardDataProvider';
import { CourseItemContainer, CourseItemTitle } from './styles';

const CourseItem = ({ _id, course, classes }: any) => {
  const location = useLocation();
  const history = useHistory();
  const state = useContext(DashboardDataState);

  const dashboardRedirectHandler = () =>
    !location.pathname.includes('dashboard') && history.push('/dashboard');

  const getCourseData = () => {
    dashboardRedirectHandler();
    state.setDashboardDataId(_id);
  };

  const classesIds = classes.map(({ _id }: any) => _id);

  const isActiveCourse = state.id === _id || classesIds.includes(state.id);

  return (
    <CourseItemContainer>
      <CourseItemTitle onClick={getCourseData} isActive={isActiveCourse}>
        {course}
        <span>({classes.length})</span>
      </CourseItemTitle>
      {isActiveCourse && <CourseClasses classes={classes} course={course} />}
    </CourseItemContainer>
  );
};

export default React.memo(CourseItem);

import React, { useState } from 'react';

import { Container } from 'semantic-ui-react';

import Breadcrumbs from 'src/components/Breadcrumbs';
import ContainerOverview from 'src/containers/ContainerOverview';
import { LoadingPlaceholder } from 'src/components/Placeholders';
import { useFetchPapersByTitleSearch } from 'src/services/hooks/useFetchPapersByTitleSearch';
import { useDebounce } from 'src/services/hooks/useDebounce';
import { MyPapersSection } from 'src/components/Sections';

import { PapersSearch, PapersFound } from './styles';

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [papers, papersLoading]: any = useFetchPapersByTitleSearch(
    debouncedSearchTerm
  );

  const searchPapers = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value);

  return (
    <ContainerOverview>
      <Container>
        <Breadcrumbs dashboard />
        <PapersSearch
          icon="search"
          iconPosition="left"
          placeholder="Search for keyword or phrase"
          onChange={searchPapers}
        />
        <LoadingPlaceholder loading={papersLoading}>
          <PapersFound>{papers.length} papers found</PapersFound>
          {papers.length > 0 && <MyPapersSection items={papers} isAdmin />}
        </LoadingPlaceholder>
      </Container>
    </ContainerOverview>
  );
};

export default Search;

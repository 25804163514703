import React from 'react';
import { Container, Header } from 'semantic-ui-react';

import EmptySvg from 'src/assets/illustrations/empty.svg';
import Svg from 'src/containers/Svg';

const NotFoundError = () => (
  <Container textAlign="center">
    <Svg src={EmptySvg} width="80%" height="80%" />
    <Header as="h1">PAGE NOT FOUND</Header>
  </Container>
);

export default NotFoundError;

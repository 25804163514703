import get from 'lodash/get';
import { useEffect, useState } from 'react';

import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';

const useFetchDepartmentsCoursesAndClasses = (collegeID: string | undefined) => {
  const [departments, setDepartments] = useState([]);
  const [departmentsLoading, setDepartmentsLoading] = useState(false);

  useEffect(() => {
    const fetchColleges = async () => {
      setDepartmentsLoading(true);
      try {
        const response = await ApiCalls.getDepartmentsCoursesAndClasses(
          collegeID
        );
        const data = response.data;
        const departments = get(data, 'data', []);
        setDepartments(departments);
      } catch (e) {
        createToast({
          type: 'error',
          description: `Department(s) have not been fetched`
        });
      } finally {
        setDepartmentsLoading(false);
      }
    };
    if(collegeID) {
      fetchColleges();
    }
  }, [collegeID]);

  return [departments, departmentsLoading];
};

export default useFetchDepartmentsCoursesAndClasses;

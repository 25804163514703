import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

interface IProps {
  title: string;
  subtitle?: string;
  icon?: any;
  textAlign?: 'left' | 'center' | 'right' | 'justified';
}

const ScreenHeader = (props: IProps) => {
  return (
    <Header as="h2" icon textAlign={props.textAlign}>
      {props.icon && <Icon name={props.icon} />}
      {props.title}
      {props.subtitle && <Header.Subheader>{props.subtitle}</Header.Subheader>}
    </Header>
  );
};

export default ScreenHeader;

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { IBreadcrumbInfo } from 'src/interfaces/DashboardDataInterface';
import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';

const useFetchDashboardMicroData = (breadcrumbInfo: IBreadcrumbInfo) => {
  const [microData, setMicroData] = useState({});
  const [isMicroDataLoading, setMicroDataLoading] = useState(false);

  function useDeepCompareMemoize(value: any) {
    const ref = useRef()

    if (!isEqual(value, ref.current)) {
      ref.current = value
    }

    return ref.current
  }

  useDeepCompareEffect(() => {
    const fetchDashboardMicroData = async () => {
      setMicroDataLoading(true);
      try {
        const response = await ApiCalls.getDashboardMicroData(breadcrumbInfo);
        const { data } = response;
        const fetchedMicroData = get(data, 'data', {})
        setMicroData(fetchedMicroData);
      } catch(error) {
        console.log('error', error);
        createToast({
          type: 'error',
          description: 'Couldn\'t fetch general data'
        });
      } finally {
        setMicroDataLoading(false);
      }
    };
    fetchDashboardMicroData();
  }, [breadcrumbInfo]);

  return [microData, isMicroDataLoading];
};

export default useFetchDashboardMicroData;
import React from 'react';

import {
  PaperFeedbackContainer,
  PaperFeedbackTitle,
  PaperFeedbackGrid,
  PaperFeedbackColumn,
  ColumnTitle,
  ColumnParagraph
} from './styles';

const PaperFeedback = () => (
  <PaperFeedbackContainer>
    <PaperFeedbackTitle>Assessment Feedback</PaperFeedbackTitle>
    <PaperFeedbackGrid stackable columns={2}>
      <PaperFeedbackColumn>
        <ColumnTitle>Top writing patterns</ColumnTitle>
        <ColumnParagraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt
        </ColumnParagraph>
      </PaperFeedbackColumn>
      <PaperFeedbackColumn>
        <ColumnTitle>Model Writing Styles to Try Next Time</ColumnTitle>
        <ColumnParagraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt
        </ColumnParagraph>
      </PaperFeedbackColumn>
    </PaperFeedbackGrid>
  </PaperFeedbackContainer>
);

export default PaperFeedback;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import { SubmitButton } from './styles';

import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { displayError } from 'src/services/helpers/FormHelper';
interface IFormValues {
  email: string;
}
interface IProps {
  resetPassword: (email: string) => void;
}

interface IFormSubmit {
  handleSubmit: any;
}

const ResetPasswordForm = (props: FormikProps<IFormValues> & IProps) => {
  const { t } = useTranslation();

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit
  }: FormikProps<IFormValues> & IFormSubmit = props;

  return (
    <Form size="large" loading={isSubmitting}>
      <Form.Input
        fluid
        label="Email address:"
        type="email"
        value={values.email}
        onChange={handleChange('email')}
        {...displayError({
          field: 'email',
          touched: touched.email,
          errors: errors.email
        })}
      />

      <SubmitButton primary type="submit" onClick={handleSubmit}>
        {t('Reset Password')}
      </SubmitButton>
    </Form>
  );
};

export default withFormik<IProps, IFormValues>({
  mapPropsToValues() {
    return {
      email: ''
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid Field')
      .required('Required Field')
  }),
  async handleSubmit(values, { setSubmitting, props }) {
    setSubmitting(true);

    await props.resetPassword(values.email);

    setSubmitting(false);
  }
})(ResetPasswordForm);

import get from 'lodash/get';
import { useEffect, useState } from 'react';

import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';

const useFetchPapersByTitleSearch = (title: string) => {
  const [papers, setPapers] = useState([]);
  const [papersLoading, setPapersLoading] = useState(false);

  useEffect(() => {
    const fetchPapers = async () => {
      setPapersLoading(true);
      try {
        const response = await ApiCalls.getVextPapersByTitleSearch(title);
        const data = response.data;
        const dataPapers = get(data, 'data', []);
        setPapers(dataPapers);
      } catch (e) {
        createToast({
          type: 'error',
          description: `Papers(s) have not been fetched`
        });
      } finally {
        setPapersLoading(false);
      }
    };
    fetchPapers();
  }, [title]);

  return [papers, papersLoading];
};

export default useFetchPapersByTitleSearch;

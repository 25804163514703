import React from 'react';

import ScreenContentContainer from 'src/containers/ScreenContentContainer';

import { ContactForm } from 'src/components/Forms';
import { ScreenHeader } from 'src/components/Headers';

const Contact = () => (
  <ScreenContentContainer>
    <ScreenHeader
      title="Contact Us"
      subtitle="Need additional information or have a question or comment? Please feel free to leave a message, and we'll get back to you as soon as possible."
      icon="address book"
      textAlign="center"
    />

    <ContactForm />
  </ScreenContentContainer>
);

export default Contact;

import styled from 'styled-components';

import { Icon, Select } from 'semantic-ui-react';

interface IProps {
  isOpen?: boolean;
}

export const CollegeItemContainer = styled.div`
  margin-bottom: 8px;
`;

export const CollegeDropdownsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CollegeDropdownSelect = styled(Select)`
  margin-bottom: 5px;
`;

export const CollegeItemPreTitle = styled.span`
  color: #93959a;
  font-size: 14px;
  display: block;
  margin-bottom: 2px;
`;

export const CollegeItemTitleContainer = styled.div`
  position: relative;
  padding: 0 12px 16px 17px;
  cursor: pointer;
  i {
    transform: ${({ isOpen }: IProps) =>
      isOpen ? 'rotate(90deg) translate(3px, 0)' : 'rotate(0deg)'};
  }
`;

export const CollegeItemTitle = styled.h4`
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  cursor: pointer;
`;

export const CollegeItemIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: -3px;
  color: #fff;
`;

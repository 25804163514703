import styled from 'styled-components';

import { Button, Form, Icon } from 'semantic-ui-react';

export const UploadedPapersForm = styled(Form)`
  text-align: center;
`;

export const UploadedPapersTitle = styled.h5`
  margin-top: 0;
  margin-bottom: 10px;
`;

export const UploadedPapersFormGroup = styled(Form.Group)`
  &&&& {
    padding: 7px 10px 7px 5px;
    margin: 0 0 6px 0;
    border-radius: 3px;
    border: 1px solid #c4c8d1;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1200px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;

export const UploadedPapersFormField = styled(Form.Field)`
  &&&&& {
    margin-bottom: 0 !important;
    flex: 1;
    padding: 0 2.5px;
    @media(min-width: 1201px) {
      .ui.selection.dropdown div.text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;
      }
    }
    @media (max-width: 1200px) {
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 6px !important;
    }
    @media (max-width: 580px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

export const DeletePaperIcon = styled(Icon)`
  &&& {
    margin: -4px 0 0 6px;
    @media (max-width: 1200px) {
      font-size: 18px;
      margin: 6px;
    }
  }
`;

export const UploadedPapersButton = styled(Button)`
  &&& {
    margin-top: 25px;
  }
`;

import React from 'react';
import Loader from 'src/components/Loader';

interface IProps {
  loading: boolean;
  children: JSX.Element[] | JSX.Element;
}
const ContentContainer = ({ loading, children }: IProps) => {
  return loading ? <Loader active={loading} /> : <div>{children}</div>;
};

export default ContentContainer;

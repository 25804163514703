import { useEffect, useState } from 'react';

import get from 'lodash/get';
import { useHistory } from 'react-router-dom';

import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';

const useFetchPaperInfo = (paperId: string) => {
  const [paperInfo, setPaperInfo] = useState({
    title: '',
    sentiment: {},
    department: '',
    course: '',
    courseClass: ''
  });
  const [paperInfoLoading, setPaperInfoLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchPaperInfo();
  }, [paperId]);

  const fetchPaperInfo = async () => {
    setPaperInfoLoading(true);
    try {
      const response = await ApiCalls.getPaperInfo(paperId);
      const data = response.data;
      const fetchedPaper = get(data, 'data', []);
      setPaperInfo({...fetchedPaper})
    } catch(err) {
      console.log('err', err);
      createToast({
        type: 'error',
        description: `Paper information have not been fetched`
      });
    } finally {
      setPaperInfoLoading(false);
    }
  };

  return [paperInfo, paperInfoLoading];
};

export default useFetchPaperInfo;

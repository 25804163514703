import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Responsive } from 'semantic-ui-react';

interface Props {
  children: JSX.Element[] | JSX.Element;
}

const getWidth: any = () => {
  return Responsive.onlyTablet.minWidth;
};

const Layout = ({ children }: Props) => (
  <BrowserRouter>
    <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
      {children}
    </Responsive>
  </BrowserRouter>
);

export default Layout;

import React, { Fragment } from "react";
import Loader from "src/components/Loader";
import { LoadingDivContainer, LoaderPosition } from "./style";

interface Props {
  loading: boolean;
}

const LoadingContainer = ({ loading }: Props) => {
  return (
    <Fragment>
      {loading && (
        <LoadingDivContainer>
          <LoaderPosition>
            <Loader active={loading} />
          </LoaderPosition>
        </LoadingDivContainer>
      )}
    </Fragment>
  );
};

export default LoadingContainer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { LoginCredentials } from 'src/interfaces/AuthInterface';
import { displayError } from 'src/services/helpers/FormHelper';

import CheckboxField from '../Forms/CheckboxField/CheckboxField';

import { ForgotPasswordLink, SubmitButton } from './styles';

interface IFormValues {
  email: string;
  password: string;
  rememberUser: boolean;
}

interface IProps {
  loginUser: (args: LoginCredentials, history: any) => void;
  history: any;
}

interface IFormSubmit {
  handleSubmit: any;
}

const LoginForm = (props: FormikProps<IFormValues> & IProps) => {
  const { t } = useTranslation();

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit
  }: FormikProps<IFormValues> & IFormSubmit = props;

  return (
    <Form size="large" loading={isSubmitting}>
      <Form.Input
        fluid
        label="Email address:"
        type="email"
        value={values.email}
        onChange={handleChange('email')}
        {...displayError({
          field: 'email',
          touched: touched.email,
          errors: errors.email
        })}
      />

      <Form.Input
        fluid
        label="Password:"
        type="password"
        value={values.password}
        onChange={handleChange('password')}
        {...displayError({
          field: 'password',
          touched: touched.password,
          errors: errors.password
        })}
      />

      <Form.Group widths="equal">
        <CheckboxField
          label="Remember me"
          checked={values.rememberUser}
          onChange={handleChange('rememberUser')}
          {...displayError({
            field: 'rememberUser',
            touched: touched.rememberUser,
            errors: errors.rememberUser
          })}
        />

        <Form.Field>
          <ForgotPasswordLink to="/resetpassword">
            Forgot password
          </ForgotPasswordLink>
        </Form.Field>
      </Form.Group>

      <SubmitButton primary type="submit" onClick={handleSubmit}>
        {t('Log In')}
      </SubmitButton>
    </Form>
  );
};

export default withFormik<IProps, IFormValues>({
  mapPropsToValues() {
    return {
      email: '',
      password: '',
      rememberUser: false
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid Field')
      .required('Required Field'),
    password: Yup.string()
      .min(6, 'Password Min Length 6')
      .required('Required Field'),
    rememberUser: Yup.boolean()
  }),
  async handleSubmit(values, { setSubmitting, props }) {
    setSubmitting(true);

    await props.loginUser(values, props.history);

    setSubmitting(false);
  }
})(LoginForm);

import React, { useContext, useEffect, useState } from 'react';

import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { LoadingPlaceholder } from 'src/components/Placeholders';
import { DashboardDataState } from 'src/contexts/DashboardDataProvider';
import { IBreadcrumbInfo } from 'src/interfaces/DashboardDataInterface';
import { useFetchColleges } from 'src/services/hooks/useFetchColleges';

import {
  BreadcrumbsContainer,
  BreadcrumbsList,
  BreadcrumbsListItem
} from './styles';

interface IProps {
  search?: boolean;
  dashboard?: boolean;
}

const Breadcrumbs = ({ search, dashboard }: IProps) => {
  const history = useHistory();
  const [colleges, collegesLoading]: any = useFetchColleges();

  const { id, setDashboardDataId, institutions, breadcrumbs, setBreadcrumbs, setBreadcrumbData } = useContext(
    DashboardDataState
  );

  useEffect(() => {
    getBreadcrumbs();
  }, [id, colleges, institutions]);

  const getBreadcrumbs = () => {
    const breadcrumbsItems: any = [];
    const dashboardBreadcrumbInfo: IBreadcrumbInfo = {};

    const getCollege = () => {
      const mainCollegeByColleges = colleges.filter(
        (college: any) => college._id === id
      );

      const mainCollegeByDepartments = institutions.filter(({ depts }: any) =>
        depts.some((dept: any) => dept._id === id)
      );

      const mainCollegeByCourses = institutions.filter((college: any) =>
        college.depts.some((dept: any) =>
          dept.courses.some((course: any) => course._id === id)
        )
      );

      const mainCollegeByClasses = institutions.filter((college: any) =>
        college.depts.some((dept: any) =>
          dept.courses.some((course: any) =>
            course.classes.some((cl: any) => cl._id === id)
          )
        )
      );

      mainCollegeByColleges.length > 0 &&
        breadcrumbsItems.push(...mainCollegeByColleges);

      mainCollegeByDepartments.length > 0 &&
        breadcrumbsItems.push(...mainCollegeByDepartments);

      mainCollegeByCourses.length > 0 &&
        breadcrumbsItems.push(...mainCollegeByCourses);

      mainCollegeByClasses.length > 0 &&
        breadcrumbsItems.push(...mainCollegeByClasses);
    };
    getCollege();

    dashboardBreadcrumbInfo.collegeid = get(breadcrumbsItems[0],'_id', undefined);

    const getDepartment = () => {
      const mainCollege = get(breadcrumbsItems[0], 'depts', []);

      const mainDepartmentByDepartments = mainCollege.filter(
        (dep: any) => dep._id === id
      );

      const mainDepartmentByCourses = mainCollege.filter((dep: any) =>
        dep.courses.some((course: any) => course._id === id)
      );

      const mainDepartmentByClasses = mainCollege.filter((dep: any) =>
        dep.courses.some((course: any) =>
          course.classes.some((cl: any) => cl._id === id)
        )
      );

      mainDepartmentByDepartments.length > 0 &&
        breadcrumbsItems.push(...mainDepartmentByDepartments);

      mainDepartmentByCourses.length > 0 &&
        breadcrumbsItems.push(...mainDepartmentByCourses);

      mainDepartmentByClasses.length > 0 &&
        breadcrumbsItems.push(...mainDepartmentByClasses);
    };
    getDepartment();

    dashboardBreadcrumbInfo.deptid = get(breadcrumbsItems[1],'_id', undefined);

    const getCourse = () => {
      const mainDepartment = get(breadcrumbsItems[1], 'courses', []);

      const mainCourseByCourse = mainDepartment.filter(
        (course: any) => course._id === id
      );

      const mainCourseByClasses = mainDepartment.filter((course: any) =>
        course.classes.some((cl: any) => cl._id === id)
      );

      mainCourseByCourse.length > 0 &&
        breadcrumbsItems.push(...mainCourseByCourse);

      mainCourseByClasses.length > 0 &&
        breadcrumbsItems.push(...mainCourseByClasses);
    };
    getCourse();

    dashboardBreadcrumbInfo.courseid = get(breadcrumbsItems[2],'_id', undefined);

    const getClass = () => {
      const mainCourse = get(breadcrumbsItems[2], 'classes', []);

      const mainClassByClass = mainCourse.filter(
        (course: any) => course._id === id
      );

      mainClassByClass.length > 0 && breadcrumbsItems.push(...mainClassByClass);
    };
    getClass();

    dashboardBreadcrumbInfo.classid = get(breadcrumbsItems[3],'_id', undefined);

    setBreadcrumbData(breadcrumbsItems, dashboardBreadcrumbInfo);
  };
  const breadcrumbsListItems =
    Array.isArray(breadcrumbs) &&
    breadcrumbs.length > 0 &&
    breadcrumbs.map(
      ({ _id, college, dept, course, year, semester, section }) => (
        <BreadcrumbsListItem key={_id} onClick={() => setDashboardDataId(_id)}>
          {college || dept || course || `${year}: ${semester}: ${section}`}
        </BreadcrumbsListItem>
      )
    );

  return (
    <BreadcrumbsContainer>
      <BreadcrumbsList>
        {dashboard ? (
          <BreadcrumbsListItem onClick={() => history.push('/dashboard')}>
            Back to dashboard
          </BreadcrumbsListItem>
        ) : (
          <LoadingPlaceholder loading={collegesLoading}>
            {breadcrumbsListItems}
          </LoadingPlaceholder>
        )}
      </BreadcrumbsList>
      {search && (
        <Button primary onClick={() => history.push('/search')}>
          Paper Search
        </Button>
      )}
    </BreadcrumbsContainer>
  );
};

export default React.memo(Breadcrumbs);

const COUNTRY_CODES: any = [
  {
    key: "united-states",
    text: "United States",
    value: "United States"
  },
  {
    key: "australia",
    text: "Australia",
    value: "Australia"
  },
  {
    key: "canada",
    text: "Canada",
    value: "Canada"
  },
  {
    key: "china",
    text: "China",
    value: "China"
  },
  {
    key: "germany",
    text: "Germany",
    value: "Germany"
  },
  {
    key: "israel",
    text: "Israel",
    value: "Israel"
  },
  {
    key: "japan",
    text: "Japan",
    value: "Japan"
  },
  {
    key: "taiwan",
    text: "Taiwan",
    value: "Taiwan"
  },
  {
    key: "united-kingdom",
    text: "United Kingdom",
    value: "United Kingdom"
  },
  {
    key: "other",
    text: "Other",
    value: "Other"
  }
];

export default COUNTRY_CODES;

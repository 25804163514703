import get from 'lodash/get';
import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { IBreadcrumbInfo } from 'src/interfaces/DashboardDataInterface';
import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';

const useFetchDashboardTopicKeywords = (breadcrumbInfo: IBreadcrumbInfo) => {
  const [topicKeywords, setTopicKeywords] = useState({});
  const [isTopicKeywordsLoading, setTopicKeywordsLoading] = useState(false);

  useDeepCompareEffect(() => {
    const fetchDashboardTopicKeywords = async () => {
      setTopicKeywordsLoading(true);
      try {
        const response = await ApiCalls.getDashboardTopKeywords(breadcrumbInfo);
        const { data } = response;
        const fetchedTopicKeywords = get(data, 'data', {});
        setTopicKeywords(fetchedTopicKeywords);
      } catch(error) {
        createToast({
          type: 'error',
          description: 'Couldn\'t fetch keywords data'
        });
      } finally {
        setTopicKeywordsLoading(false);
      }
    };
    fetchDashboardTopicKeywords();
  }, [breadcrumbInfo]);

  return [topicKeywords, isTopicKeywordsLoading];
};

export default useFetchDashboardTopicKeywords;

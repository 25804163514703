import React from 'react';

import DepartmentItem from 'components/DepartmentItem';

const DepartmentsList = ({ departments }: any): any =>
  Array.isArray(departments) &&
  departments.map(department => (
    <DepartmentItem key={department._id} {...department} />
  ));

export default React.memo(DepartmentsList);

import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

export const BlurContent = styled.div`
  filter: blur(8px);
  -webkit-filter: blur(8px);
`;

// Premium Papers
export const PremiumPaperItemContainer = styled(Segment)`
  box-shadow: none !important;
  > * {
    color: #666666 !important;
  }
`;

export const PremiumPaperItemTitle = styled.p`
  font-size: 16px;
`;

// Contact
export const ContactContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 20px;
  background-color: #b2bec3;
  cursor: pointer;
  text-align: center;
`;

export const UploadPapersWrapper = styled.div`
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  min-height: 520px;
  padding: 60px 30px 85px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UploadPapersHeading = styled.h2`
  margin: 35px 0 24px 0;
`;

import Auth from '@aws-amplify/auth';
import get from 'lodash/get';
import { uuid } from 'uuidv4';

import { facebookOAuthUrl } from 'src/config/facebook';
import {
  IChangePassword,
  LoginCredentials,
  NewPasswordCredentials,
  SignUpCredentials
} from 'src/interfaces/AuthInterface';
import { ApiCalls } from 'src/services/api';
import {
  LOADING_START,
  LOADING_STOP,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  REGISTER_ADMIN_FAILED,
  REGISTER_ADMIN_SUCCESS,
  REGISTER_USER_FAILED,
  REGISTER_USER_SUCCESS,
  SAVE_USER,
  SAVE_USER_FAILED,
  SAVE_USER_SUCCESS,
  UPDATE_ATTR_USER,
  UPDATE_ATTR_USER_FAILED,
  UPDATE_ATTR_USER_SUCCESS
} from '../types';
import { createToast } from './ToastActions';

/**
 * Store current user
 */
export const saveCurrentUser = (bypassCache = true) => async (
  dispatch: any
) => {
  dispatch({ type: LOADING_START });
  dispatch({ type: SAVE_USER });

  try {
    const data = await Auth.currentAuthenticatedUser({
      bypassCache
    });

    dispatch({ type: SAVE_USER_SUCCESS, attributes: data.attributes });
    dispatch({ type: LOADING_STOP });
  } catch {
    dispatch({ type: SAVE_USER_FAILED });
    dispatch({ type: LOADING_STOP });
  }
};

/**
 * Sets the userId that we get from the server to the localStorage
 * If there's no user with that cognitoId we create it
 * @param cognitoId
 * @param singUpCredentials
 */
const setUserIdByCognitoId = async (cognitoId: string, singUpCredentials: SignUpCredentials) => {
  try {
    const response = await ApiCalls.getUserInfoByCognitoId(cognitoId);
    const userData = response.data;
    const fetchedUserData = get(userData, 'data', undefined);
    const userId = fetchedUserData && fetchedUserData._id;
    localStorage.setItem('userId', userId);
  } catch(error) {
    const createUserResponse = await ApiCalls.createUser(singUpCredentials, cognitoId);
    const createdUserData = get(createUserResponse.data, 'data', undefined);
    const userId = createdUserData && createdUserData._id;
    localStorage.setItem('userId', userId);
  }
};


/**
 * Login User
 */
export const loginUser = (
  credentials: LoginCredentials,
  history: any
) => async (dispatch: any) => {
  try {
    let data = await Auth.signIn(credentials.email, credentials.password);
    if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
      await Auth.completeNewPassword(data, credentials.password, []);
      data = await Auth.signIn(credentials.email, credentials.password);
    }

    const cognitoId = data.attributes.sub;
    const [firstName, lastName] = data.attributes.name.split(' ');
    const createUserCredentials = {
      firstName,
      lastName,
      ...credentials
    };
    
    await setUserIdByCognitoId(cognitoId, createUserCredentials);

    return loginUserSuccess(dispatch, data, history);
  } catch (error) {
    return loginUserFail(dispatch, error, history);
  }
};

const loginUserSuccess = (dispatch: any, data: any, history: any) => {
  history.push('/account');
  dispatch({ type: LOGIN_USER_SUCCESS, attributes: data.attributes });
};

const loginUserFail = (dispatch: any, error: any, history: any) => {
  if (error.code === 'PasswordResetRequiredException') {
    history.push('/newpassword');
  } else {
    dispatch({ type: LOGIN_USER_FAILED });
    createToast({ type: 'error', description: error.message });
  }
};

/**
 * OAuth
 */
export const loginUserFacebook = () => (dispatch: any) => {
  dispatch({ type: LOADING_START });
  window.location.href = facebookOAuthUrl;
};

/**
 * Register Admin
 */
export const signUpAdmin = (credentials: any) => {
  return async (dispatch: any) => {
    for (const people of credentials.people) {
      try {
        await Auth.signUp({
          username: people.email,
          password: uuid(),
          attributes: {
            email: people.email,
            'custom:university': people.university,
            'custom:isAdmin': '1'
          }
        });
        dispatch(registerAdminSuccess());
        createToast({
          type: 'info',
          description: `A confirmation link was sent on the given email: ${people.email}`
        });
      } catch (error) {
        dispatch(registerAdminFail());
        switch (error.name) {
          case 'UsernameExistsException':
            createToast({
              type: 'error',
              description: `An account with the given email already exists: ${people.email}`
            });
            break;
          default:
            createToast({
              type: 'error',
              description: error.message
            });
        }
      }
    }
  };
};

const registerAdminSuccess = () => ({
  type: REGISTER_ADMIN_SUCCESS
});

const registerAdminFail = () => ({
  type: REGISTER_ADMIN_FAILED
});

/**
 * Register User
 */

export const signUpUser = (
  credentials: SignUpCredentials,
  history: any
) => async (dispatch: any) => {
  try {
    const signUpResult = await Auth.signUp({
      username: credentials.email,
      password: credentials.password,
      attributes: {
        name: `${credentials.firstName} ${credentials.lastName}`,
        email: credentials.email,
        updated_at: JSON.stringify(Date.now())
      }
    });
    const userCognitoId = signUpResult.userSub;

    await ApiCalls.createUser(credentials, userCognitoId);

    return registerUserSuccess(dispatch, history);
  } catch (error) {
    return registerUserFail(dispatch, error);
  }
};

const registerUserSuccess = (dispatch: any, history: any) => {
  dispatch({ type: REGISTER_USER_SUCCESS });

  history.push('/login');
  createToast({
    type: 'info',
    description: 'A confirmation link was sent on your email.'
  });
};

const registerUserFail = (dispatch: any, error: any) => {
  dispatch({ type: REGISTER_USER_FAILED });
  createToast({ type: 'error', description: error.message });
};

/**
 * Log Out
 */
export const signOutUser = (history: any) => (dispatch: any) => {
  dispatch({ type: LOADING_START });

  Auth.signOut()
    .then(() => {
      localStorage.removeItem('userId');
      dispatch({ type: LOGOUT_USER_SUCCESS });
      dispatch({ type: LOADING_STOP });
    })
    .catch(err => {
      dispatch({ type: LOADING_STOP });
      createToast({ type: 'error', description: err.message });
    });
};

/**
 * Forgot Password
 */

export const resetPassword = (username: string, history: any) => async (
  dispatch: any
) => {
  try {
    await Auth.forgotPassword(username);

    createToast({
      type: 'info',
      description: 'Password reseted. Check your email.'
    });

    return history.push('/newpassword');
  } catch (error) {
    return createToast({ type: 'error', description: error.message });
  }
};

/**
 * Forgot password submit
 */

export const forgotPasswordSubmit = (
  { email, code, password }: NewPasswordCredentials,
  history: any
) => async (dispatch: any) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, password);

    createToast({
      type: 'info',
      description: 'Password has been changed successfuly.'
    });

    return history.push('/');
  } catch (error) {
    return createToast({ type: 'error', description: error.message });
  }
};

/**
 * Change Password
 */

export const changePassword = ({
  oldPassword,
  newPassword
}: IChangePassword) => async (dispatch: any) => {
  const user = await Auth.currentAuthenticatedUser();

  if (user) {
    try {
      await Auth.changePassword(user, oldPassword, newPassword);
      return changePasswordSuccess(dispatch);
    } catch (error) {
      return changePasswordFail(dispatch, error);
    }
  } else {
    return changePasswordFail(dispatch, { message: 'User not found' });
  }
};

const changePasswordSuccess = (dispatch: any) => {
  createToast({
    type: 'info',
    description: 'Password has been changed successfuly.'
  });
};

const changePasswordFail = (dispatch: any, error: any) => {
  createToast({ type: 'error', description: error.message });
};

/**
 * Update Attributes
 */
export const updateAttributes = (attributes: any) => async (dispatch: any) => {
  if (attributes.hasOwnProperty('errors')) {
    delete attributes.errors;
  }

  dispatch({ type: UPDATE_ATTR_USER });

  const user = await Auth.currentAuthenticatedUser();

  if (user) {
    try {
      await Auth.updateUserAttributes(user, attributes);
      return updateAttributesSuccess(dispatch, attributes);
    } catch (error) {
      return updateAttributesFail(dispatch, error);
    }
  } else {
    return updateAttributesFail(dispatch, { message: 'User not found' });
  }
};

const updateAttributesSuccess = (dispatch: any, attributes: any) => {
  dispatch({ type: UPDATE_ATTR_USER_SUCCESS, attributes });
  dispatch({ type: LOADING_STOP });
  createToast({
    type: 'info',
    description: 'Attributes have been changed successfuly.'
  });
};

const updateAttributesFail = (dispatch: any, error: any) => {
  dispatch({ type: UPDATE_ATTR_USER_FAILED });
  dispatch({ type: LOADING_STOP });
  createToast({ type: 'error', description: error.message });
};

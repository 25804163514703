import React, { createContext, useState } from 'react';

import { IBreadcrumbInfo } from 'src/interfaces/DashboardDataInterface';

const INITIAL_STATE: any = {
  id: '5e73eb48a671c2fc34fc2eac',
  institutions: [],
  breadcrumbInfo: {},
};

export const DashboardDataState = createContext(INITIAL_STATE);

const DashboardDataProvider = ({ children }: any) => {
  const [state, setState] = useState(INITIAL_STATE);

  const { id, institutions, breadcrumbInfo, breadcrumbs, collegeSemester, collegeYear } = state;

  const value = {
    id,
    institutions,
    breadcrumbs,
    breadcrumbInfo,
    collegeSemester,
    collegeYear,
    setDashboardDataId: (id: string) =>
      setState((prevState: { id: string }) => ({
        ...prevState,
        id
      })),
    setInstitutions: (institute: any) =>
      setState((prevState: any) => ({
        ...prevState,
        institutions: [...state.institutions, institute]
      })),
    setBreadcrumbInfo: (breadcrumbInfo: IBreadcrumbInfo) =>
      setState((prevState: any) => ({
          ...prevState,
          breadcrumbInfo,
          collegeYear,
          collegeSemester,
        })),
    setBreadcrumbs: (breadcrumbs: any) =>
      setState((prevState: any) => ({
        ...prevState,
        breadcrumbs,
      })),
    setBreadcrumbData: (breadcrumbs: any, breadcrumbInfo: IBreadcrumbInfo) =>
      setState((prevState: any) => {
        const newCollegeId = breadcrumbInfo.collegeid !== prevState.breadcrumbInfo.collegeid;
        const collegeYear = newCollegeId ? null : prevState.collegeYear;
        const collegeSemester = newCollegeId ? null : prevState.collegeSemester;
        return {
          ...prevState,
          breadcrumbs,
          breadcrumbInfo,
          collegeSemester,
          collegeYear,
      }}),
    setCollegeYear: (collegeYear: string) =>
      setState((prevState: any) => ({
        ...prevState,
        collegeYear,
      })),
    setCollegeSemester: (collegeSemester: string) =>
      setState((prevState: any) => ({
        ...prevState,
        collegeSemester,
      }))
  };

  return (
    <DashboardDataState.Provider value={value}>
      {children}
    </DashboardDataState.Provider>
  );
};

export default DashboardDataProvider;

import React from 'react';

import { get } from 'lodash';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';

import { IChangePassword } from 'src/interfaces/AuthInterface';
import { IUser } from 'src/interfaces/IUser';
import { IAuth } from 'src/interfaces/ReducersInterface';
import { changePassword } from 'src/redux/actions/AuthActions';
import { updateAttributes } from 'src/redux/actions/AuthActions';
import { ChangePasswordForm, UpdateProfileForm } from 'src/components/Forms';
import ContainerOverview from 'src/containers/ContainerOverview';

import {
  SettingsForms,
  SettingsFormContainer,
  SettingsFormsTabPane
} from './styles';

interface IProps {
  changePassword: (args: IChangePassword) => void;
  updateAttributes: (args: any) => void;
  user: IUser;
}

const Settings = ({ user, updateAttributes, changePassword }: IProps) => {
  const name = get(user, 'name', '');
  const email = get(user, 'email', '');
  const discipline = get(user, 'custom:discipline', '');
  const institution_zip = get(user, 'custom:institution_zip', '');
  const institution_code = get(user, 'custom:institution_code', '');
  const institution = get(user, 'custom:institution', '');
  const home_code = get(user, 'custom:home_code', '');

  const formsPanes = [
    {
      menuItem: 'Personal info',
      render: () => (
        <SettingsFormsTabPane>
          <SettingsFormContainer>
            <UpdateProfileForm
              name={name}
              email={email}
              discipline={discipline}
              zip={institution_zip}
              code={institution_code}
              institution={institution}
              homeCode={home_code}
              updateAttributes={updateAttributes}
            />
          </SettingsFormContainer>
        </SettingsFormsTabPane>
      )
    },
    {
      menuItem: 'Security',
      render: () => (
        <SettingsFormsTabPane>
          <SettingsFormContainer>
            <ChangePasswordForm changePassword={changePassword} />
          </SettingsFormContainer>
        </SettingsFormsTabPane>
      )
    }
  ];

  return (
    <ContainerOverview>
      <Container>
        <SettingsForms panes={formsPanes} />
      </Container>
    </ContainerOverview>
  );
};

const mapStateToProps = ({ auth }: { auth: IAuth }) => ({
  user: auth.user as IUser
});

export default connect(mapStateToProps, { changePassword, updateAttributes })(
  Settings
);

import React, { Fragment } from 'react';

import { withFormik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Form, Dropdown, Grid } from 'semantic-ui-react';

import { signUpAdmin } from 'src/redux/actions/AuthActions';
import { Universities } from 'src/constants';
import ContainerOverview from 'src/containers/ContainerOverview';

import {
  InvitationContainer,
  InvitationTitle,
  InvitationSubtitle,
  SubmitButton,
  AddedPeopleInfo,
  InviteFormGroup,
  AddPeopleIcon,
  DeletePeopleIcon,
  InviteGridColumn,
  FormDivider,
  InvitePeopleButton
} from './styles';

const Invitation = (props: any) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue
  } = props;

  const onChange = (field: string, value: any) => {
    setFieldValue(field, value);
  };

  const validateFormValues = (element: string, index: number) => {
    return errors &&
      errors.people &&
      errors.people[index] &&
      errors.people[index][element] &&
      errors.people[index][element]
      ? true
      : false;
  };

  const isRecipients = values.people && values.people.length > 0;
  const invitePeopleValues = {
    university: '',
    email: ''
  };

  return (
    <ContainerOverview>
      <Container>
        <InvitationContainer>
          <InvitationTitle>Invite people to VEXT</InvitationTitle>
          <InvitationSubtitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </InvitationSubtitle>

          {isRecipients && (
            <Fragment>
              <Grid columns={2}>
                <InviteGridColumn>University:</InviteGridColumn>
                <InviteGridColumn paddingleft="7">
                  Email address:
                </InviteGridColumn>
              </Grid>
              <FormDivider />
            </Fragment>
          )}

          <Form loading={isSubmitting}>
            <FieldArray
              name="people"
              render={arrayHelpers => (
                <Fragment>
                  {isRecipients ? (
                    values.people.map((person: any, index: number) => (
                      <InviteFormGroup widths="equal" key={index}>
                        <Form.Field>
                          <Dropdown
                            fluid
                            selection
                            options={Universities}
                            placeholder="Select university"
                            value={values.people[index].university}
                            error={validateFormValues('university', index)}
                            onChange={(_, { value }) => {
                              onChange(`people[${index}].university`, value);
                            }}
                          />
                        </Form.Field>
                        <Form.Input
                          fluid
                          placeholder="Enter email address"
                          type="email"
                          value={values.people[index].email}
                          error={validateFormValues('email', index)}
                          onChange={handleChange(`people[${index}].email`)}
                        />
                        <DeletePeopleIcon
                          name="trash alternate outline"
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        />
                      </InviteFormGroup>
                    ))
                  ) : (
                    <InvitePeopleButton
                      primary
                      onClick={() => arrayHelpers.push(invitePeopleValues)}
                    >
                      Invite People
                    </InvitePeopleButton>
                  )}
                  {isRecipients && (
                    <AddPeopleIcon
                      name="plus circle"
                      onClick={() => arrayHelpers.push(invitePeopleValues)}
                    />
                  )}
                </Fragment>
              )}
            />
            {isRecipients && (
              <Fragment>
                <SubmitButton primary type="submit" onClick={handleSubmit}>
                  Send Invites
                </SubmitButton>
                <AddedPeopleInfo>
                  {values.people.length} people added
                </AddedPeopleInfo>
              </Fragment>
            )}
          </Form>
        </InvitationContainer>
      </Container>
    </ContainerOverview>
  );
};

const formikEnhancer = withFormik({
  mapPropsToValues() {
    return {
      people: [
        {
          university: '',
          email: ''
        }
      ]
    };
  },
  validationSchema: Yup.object().shape({
    people: Yup.array().of(
      Yup.object().shape({
        university: Yup.string().required('Required Field'),
        email: Yup.string()
          .email('Invalid Field')
          .min(6, 'Password Min Length 6')
          .required('Required Field')
      })
    )
  }),

  async handleSubmit(values, { setSubmitting, props }: any) {
    setSubmitting(true);

    await props.signUpAdmin(values);

    setSubmitting(false);
  }
})(Invitation);

const InvitationComponent = connect(null, { signUpAdmin })(formikEnhancer);

export default InvitationComponent;

import styled from 'styled-components';

export const Overview = styled.section`
  background: #f4f5f7;
  padding: 85px 16px 90px;
  min-height: calc(100vh - 55px);
  height: auto;
  @media (max-width: 768px) {
    padding: 50px 0 60px;
  }
  @media (max-width: 576px) {
    padding: 30px 0 40px;
  }
`;

import React from 'react';
import { Container } from 'semantic-ui-react';
import { ScreenHeader } from 'src/components/Headers';

const About = () => {
  return (
    <Container>
      <ScreenHeader title="About Us" icon="info circle" textAlign="center" />
      <p>
        You are doing the hard work of creating knowledge with each paper you
        research and write. We recognize the long hours it takes to provide
        valuable feedback on student and peer writing. You are doing the tough
        work of crafting rigorous classroom pedagogy and life charting
        curricula. We recognize - and honor - the arduous intellectual labors
        you are engaged in.
      </p>
      <p>
        All that hard work you’re doing...it’s our goal to allow you to make
        even more gains from having done it. VEXT’s mission is to give you
        actionable insights and data on your writing, your pedagogy, your
        curriculum.
      </p>
      <p>
        VEXT provides open access to global ideas by making student writing
        searchable for the first time ever.
      </p>
      <p>
        VEXT dashboards allow administrators and department heads to evolve into
        data-driven decision-making organizations.
      </p>
      <p>
        VEXT provides Formative and Summative data analytics to improve writing.
      </p>
      <p>
        VEXT has an open, crowd-sourced approach to Idea Generation and
        Plagiarism Detection across submissions to the VEXT database.
      </p>
      <p>
        We have sat in your seat and have felt the moral stings of having to
        settle for traditional “originality checkers.” Our objective is to
        provide an open, ethical solution to plagiarism checking by valorizing
        the work students and faculty are doing.{' '}
      </p>
      <p>
        Our philosophy, technology, and pedagogically-minded approach are more
        in line with YOUR values - which is a breath of fresh air, we believe.
        VEXT isn’t the crusty, default technology that most schools use today.
        And that's exactly why you should give VEXT a try.{' '}
      </p>
    </Container>
  );
};

export default About;

import { useState, useEffect } from 'react';

import { ApiCalls } from 'src/services/api';
import { createToast } from 'src/redux/actions/ToastActions';

const useFetchPaperFingerprint = (paperId: string, userId: string) => {
  const [paperFingerprint, setPaperFingerprint] = useState('');
  const [paperFingerprintLoading, setPaperFingerprintLoading] = useState(false);

  useEffect(() => {
    fetchPaperFingerprint();
  }, [paperId, userId]);

  const fetchPaperFingerprint = async () => {
    setPaperFingerprintLoading(true);
    try {
      const response = await ApiCalls.vextImageUrls(paperId);
      const data = response.data.data;
      const { fpURL } = data;
      setPaperFingerprint(fpURL);
    } catch {
      createToast({
        type: 'error',
        description: `Paper fingerprint have not been fetched`
      });
    } finally {
      setPaperFingerprintLoading(false);
    }
  };

  return [paperFingerprint, paperFingerprintLoading];
};

export default useFetchPaperFingerprint;

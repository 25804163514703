import styled from 'styled-components';

export const BoxTitle = styled.h4`
  &&& {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 12px;
  }
`;

export const DashboardBox = styled.div`
  background: #fff;
  padding: 18px;
  margin-bottom: 25px;
  border-radius: 3px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
`;

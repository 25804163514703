import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { signOutUser } from 'src/redux/actions/AuthActions';

interface IProps {
  signOutUser: (history: any) => void;
  history: any;
}

const Logout = (props: IProps) => {
  useEffect(() => {
    props.signOutUser(props.history);
  }, []);

  return <div />;
};

export default connect(
  null,
  { signOutUser }
)(Logout);

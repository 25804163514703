import React from "react";
import { useTranslation } from "react-i18next";
import { Segment, Container, Grid, Image } from "semantic-ui-react";
import {
  TechContainer,
  TechHeader,
  TechSubheader,
  TechImage,
  TechName
} from "./styles";

import FingerPrintImage from "src/assets/paper/fingerprint.png";

import DnaIcon from "src/assets/technologies/dna.png";
import SentimentIcon from "src/assets/technologies/sentiment.png";
import SearchIcon from "src/assets/technologies/search.png";
import ConnectionIcon from "src/assets/technologies/connection.png";
import InsightsIcon from "src/assets/technologies/insights.png";

const TechnologySection = () => {
  const { t } = useTranslation();

  return (
    <TechContainer basic color="yellow" inverted secondary textAlign="center">
      <Container>
        <TechHeader>{t("Technology")}</TechHeader>
        <Image src={FingerPrintImage} size="big" rounded centered />
        <TechSubheader>{t("Technology Subtitle")}</TechSubheader>
        <Technologies />
      </Container>
    </TechContainer>
  );
};

const Technologies = () => (
  <Grid container columns={5} doubling>
    <Grid.Column>
      <Technology src={DnaIcon} content="Paper DNA" />
    </Grid.Column>
    <Grid.Column>
      <Technology src={SentimentIcon} content="Sentiment Analysis" />
    </Grid.Column>
    <Grid.Column>
      <Technology src={SearchIcon} content="Open Search" />
    </Grid.Column>
    <Grid.Column>
      <Technology src={ConnectionIcon} content="Idea Connections" />
    </Grid.Column>
    <Grid.Column>
      <Technology src={InsightsIcon} content="Actionable Insights" />
    </Grid.Column>
  </Grid>
);

const Technology = ({ src, content }: { src: string; content: string }) => (
  <Segment basic textAlign="center">
    <TechImage src={src} size="big" />
    <TechName>{content}</TechName>
  </Segment>
);

export default TechnologySection;

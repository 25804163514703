import React, { Fragment } from 'react';

import UploadedClipboardPaper from 'components/UploadedClipboardPaper';
import UploadedPaper from 'components/UploadedPaper';
import { IOptionProp } from 'src/interfaces/GeneralInterface';

import {
  UploadedPapersForm,
  UploadedPapersTitle,
  UploadedPapersButton
} from './styles';

interface IProps {
  papers: any;
  clipboardPapers: any;
  collegeOptions: IOptionProp[] | [];
  clipboardPaperFieldChange: (
    value: string,
    index: number,
    field: string
  ) => void;
  clipboardPaperCollegeChange: (value: string, index: number) => void;
  clipboardPaperDeptChange: (value: string, index: number) => void;
  clipboardPaperCourseChange: (value: string, index: number) => void;
  clipboardPaperClassChange: (value: string, index: number) => void;
  submitPapers: () => void;
  deleteClipboardPaper: (id: string) => void;
  deletePaper: (paperID: string) => void;
  loading: boolean;
  disabled: boolean;
}

const UploadedPapers = (props: IProps) => {
  const {
    papers,
    clipboardPapers,
    collegeOptions,
    clipboardPaperFieldChange,
    clipboardPaperCollegeChange,
    clipboardPaperDeptChange,
    clipboardPaperClassChange,
    clipboardPaperCourseChange,
    submitPapers,
    deleteClipboardPaper,
    deletePaper,
    loading,
    disabled
  } = props;

  const uploadedPapersToClipboard: any = Array.isArray(clipboardPapers)
    ? clipboardPapers.map(({ title, id, collegeId, deptId, courseId, classId }: any, index: number) => (
        <UploadedClipboardPaper
          title={title}
          collegeId={collegeId}
          collegeOptions={collegeOptions}
          index={index}
          deptId={deptId}
          courseId={courseId}
          classId={classId}
          key={id}
          id={id}
          clipboardPaperFieldChange={clipboardPaperFieldChange}
          clipboardPaperCollegeChange={clipboardPaperCollegeChange}
          clipboardPaperDeptChange={clipboardPaperDeptChange}
          clipboardPaperClassChange={clipboardPaperClassChange}
          clipboardPaperCourseChange={clipboardPaperCourseChange}
          deleteClipboardPaper={deleteClipboardPaper}
        />
      ))
    : null;

  const uploadedPapers = Array.isArray(papers)
    ? papers.map(({ title, paperID }: any) => {
        return (
          <UploadedPaper
            title={title}
            key={paperID}
            deletePaper={() => deletePaper(paperID)}
          />
        );
      })
    : null;

  return (
    <Fragment>
      <UploadedPapersTitle>Uploaded Papers:</UploadedPapersTitle>
      <UploadedPapersForm loading={loading}>
        {uploadedPapersToClipboard}
        {uploadedPapers}
        <UploadedPapersButton
          disabled={disabled}
          primary
          onClick={submitPapers}
        >
          Submit Papers
        </UploadedPapersButton>
      </UploadedPapersForm>
    </Fragment>
  );
};

export default UploadedPapers;

import get from 'lodash/get';
import { useEffect, useReducer, useState } from 'react';

import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';

const useFetchCollegeInfo = (collegeId: string) => {
  const [collegeInfo, setCollegeInfo] = useState({});
  const [isCollegeInfoLoading, setCollegeInfoLoading] = useState(false);

  useEffect(() => {
    const fetchCollegeInfo = async () => {
      setCollegeInfoLoading(true);
      try {
        const response = await ApiCalls.getCollegeInfo(collegeId)
        const { data } = response;
        const fetchedCollegeInfo = get(data, 'data', {});
        setCollegeInfo(fetchedCollegeInfo);
      } catch(error) {
        console.log('error', error);
        createToast({
          type: 'error',
          description: 'Dashboard data have not been fetched'
        });
      } finally {
        setCollegeInfoLoading(false);
      }
    };
    fetchCollegeInfo();
  }, [collegeId]);

  return [collegeInfo, isCollegeInfoLoading];
};

export default useFetchCollegeInfo;

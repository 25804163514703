import axios from "axios";

import {
  EMAILJS_SERVICE_ID,
  EMAILJS_TEMPLATE_ID,
  EMAILJS_USER_ID
} from "src/config";

interface IProps {
  name: string;
  email: string;
  message: string;
}

const SendMail = async (props: IProps) => {
  const message = {
    service_id: EMAILJS_SERVICE_ID,
    template_id: EMAILJS_TEMPLATE_ID,
    user_id: EMAILJS_USER_ID,
    template_params: {
      from_name: props.name,
      message_content: props.message,
      user_email: props.email
    }
  };

  return axios.post(
    "https://api.emailjs.com/api/v1.0/email/send",
    JSON.stringify(message),
    {
      headers: { "Content-Type": "application/json" }
    }
  );
};

export default SendMail;

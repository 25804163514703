import styled from 'styled-components';

export const PapersLayoutHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const PapersLayoutHeading = styled.h3`
  margin: 0 0 0 0;
`;

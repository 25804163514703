import React, { useState, useEffect } from 'react';
import { BlogArticlesContainer } from './styles';
import { BLOG_USER, RSS_KEY } from 'src/config';

import { ArticlesCarousel } from 'src/components/Carousels';

const BlogArticlesSection = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setLoading(true);

    fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/${BLOG_USER}&api_key=${RSS_KEY}`
    )
      .then(response => response.json())
      .then((data: any) => {
        setItems(data.items);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, []);

  return (
    <BlogArticlesContainer basic textAlign="center" loading={loading}>
      {items && items.length > 0 && <ArticlesCarousel items={items} />}
    </BlogArticlesContainer>
  );
};

export default BlogArticlesSection;

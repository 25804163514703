import React from 'react';
import { HomePageHeading, HomePageSubheading } from 'src/components/Headings';

import {
  BlogArticlesSection,
  FooterSection,
  InformativeDashboardSection,
  PricingSection,
  TechnologySection
} from 'src/components/Sections';

const Home = () => {
  return (
    <div>
      <HomePageHeading />
      <HomePageSubheading />
      <InformativeDashboardSection />
      <TechnologySection />
      <PricingSection />
      <BlogArticlesSection />
      <FooterSection />
    </div>
  );
};

export default Home;

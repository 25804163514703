import React from 'react';

import { useHistory } from 'react-router-dom';

import { LoadingPlaceholder } from 'src/components/Placeholders';

import {
  PapersLibraryContainer,
  PapersLibraryTitle,
  PapersLibrarySubtitle,
  PapersOverview,
  PaperItem,
  PaperItemTitle
} from 'src/screens/Paper/styles';

import { ReactComponent as PaperIcon } from 'src/assets/paper/paper.svg';
import { useFetchPapersByUserId } from 'src/services/hooks/useFetchPapersByUserId';

interface IProps {
  userId: string;
  papersToShow: number;
}

const PapersLibrary = ({ userId, papersToShow }: IProps) => {
  const [papers, papersLoading]: any = useFetchPapersByUserId(userId);

  const history = useHistory();

  const paperRedirect = (paperID: string) => {
    history.push(`/paper/${paperID}`);
    window.scrollTo(0, 0);
  };
  const papersPreview = Array.isArray(papers)
    ? papers.slice(0, papersToShow).map(({ _id, title }: any) => (
        <PaperItem key={_id} onClick={() => paperRedirect(_id)}>
          <PaperIcon />
          <PaperItemTitle>{title}</PaperItemTitle>
        </PaperItem>
      ))
    : null;

  return (
    <PapersLibraryContainer>
      <LoadingPlaceholder loading={papersLoading}>
        <PapersLibraryTitle>Your Papers</PapersLibraryTitle>
        <PapersLibrarySubtitle>Your Library of Papers</PapersLibrarySubtitle>
        <PapersOverview>{papersPreview}</PapersOverview>
      </LoadingPlaceholder>
    </PapersLibraryContainer>
  );
};

export default React.memo(PapersLibrary);

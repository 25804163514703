import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Grid, Icon, List } from 'semantic-ui-react';

import fingerprintPlaceholder from 'src/assets/paper/fingerprint.svg';
import PaperFeedback from 'src/components/PaperFeedback';
import PapersLibrary from 'src/components/PapersLibrary';
import PeerPapersLibrary from 'src/components/PeerPapersLibrary';
import { LoadingPlaceholder } from 'src/components/Placeholders';
import UploadButton from 'src/components/UploadButton';
import ContainerOverview from 'src/containers/ContainerOverview';
import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';
import { useFetchPaperFingerprint } from 'src/services/hooks/useFetchPaperFingerprint';
import { useFetchPaperInfo } from 'src/services/hooks/useFetchPaperInfo';

import {
  DashboardLink,
  PaperContainer,
  PaperFingerprint,
  PaperFingerprintImage,
  PaperFingerprintTitle,
  PaperHeader,
  PaperIconDelete,
  PaperList,
  PaperListItem,
  PaperTitle,
  PaperTitleSection,
  SentimentListItem,
  SentimentPercentage,
  SentimentTitle
} from './styles';

interface IProps {
  match: {
    params: {
      paperId: string;
    };
  };
}

const Paper = ({
  match: {
    params: { paperId }
  }
}: IProps) => {
  const papersToShow = 3;
  const cognitoId = useSelector((state: any) => state.auth.user.sub);
  const userId = localStorage.getItem('userId') || cognitoId;
  const [paperInfo, paperInfoLoading]: any = useFetchPaperInfo(paperId);
  const [
    paperFingerprint,
    paperFingerprintLoading
  ]: any = useFetchPaperFingerprint(paperId, userId);
  const history = useHistory();

  const loading = paperInfoLoading || paperFingerprintLoading;

  const {
    _id,
    title,
    deptName,
    courseName,
    className,
    pctPositive,
    pctNegative
  } = paperInfo;

  const positiveSentiment = (pctPositive * 100).toFixed(2);
  const negativeSentiment = (pctNegative * 100).toFixed(2);

  const handleDeletePaper = () => {
    return ApiCalls.deleteVextPaper(_id)
      .then(res => {
        history.push('/account');
        createToast({
          type: 'success',
          description: 'Paper was successfully deleted'
        });
      })
      .catch(err => {
        createToast({
          type: 'error',
          description: err.message
        });
      });
  }

  return (
    <ContainerOverview>
      <Container>
        <PaperHeader>
          <DashboardLink to="/account">
            <Icon name="angle left" />
            Back to account
          </DashboardLink>
          <UploadButton>Upload</UploadButton>
        </PaperHeader>

        <PaperContainer>
          <LoadingPlaceholder loading={loading}>
            <PaperTitleSection>
              <PaperTitle>{title}</PaperTitle>
              <PaperList horizontal>
                <PaperListItem>Department: {deptName}</PaperListItem>
                <PaperListItem>Course: {courseName}</PaperListItem>
                <PaperListItem>Class: {className}</PaperListItem>
              </PaperList>
              <PaperIconDelete link name="trash alternate outline" onClick={handleDeletePaper}/>
            </PaperTitleSection>
            <PaperFingerprint>
              <PaperFingerprintTitle>Paper Fingerprint:</PaperFingerprintTitle>
              <PaperFingerprintImage src={paperFingerprint} />
              <List horizontal>
                <SentimentListItem>
                  <SentimentTitle>% positive sentiment</SentimentTitle>
                  <SentimentPercentage>{`${positiveSentiment}%`}</SentimentPercentage>
                </SentimentListItem>
                <SentimentListItem>
                  <SentimentTitle>% negative sentiment</SentimentTitle>
                  <SentimentPercentage>{`${negativeSentiment}%`}</SentimentPercentage>
                </SentimentListItem>
              </List>
            </PaperFingerprint>
          </LoadingPlaceholder>
          <PaperFeedback />
          <Grid stackable columns={2}>
            <Grid.Column>
              <PeerPapersLibrary userId={userId} papersToShow={papersToShow} />
            </Grid.Column>
            <Grid.Column>
              <PapersLibrary userId={userId} papersToShow={papersToShow} />
            </Grid.Column>
          </Grid>
        </PaperContainer>
      </Container>
    </ContainerOverview>
  );
};

export default Paper;

import get from 'lodash/get';
import { useEffect, useState } from 'react';

import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';

const useFetchColleges = () => {
  const [colleges, setColleges] = useState([]);
  const [collegesLoading, setCollegesLoading] = useState(false);

  useEffect(() => {
    const fetchColleges = async () => {
      setCollegesLoading(true);
      try {
        const response = await ApiCalls.getColleges();
        const data = response.data;
        const fetchedColleges = get(data, 'data', []);
        setColleges(fetchedColleges);
      } catch (e) {
        createToast({
          type: 'error',
          description: `College(s) have not been fetched`
        });
      } finally {
        setCollegesLoading(false);
      }
    };
    fetchColleges();
  }, []);

  return [colleges, collegesLoading];
};

export default useFetchColleges;

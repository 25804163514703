import styled from 'styled-components';

export const MicroDataKeyword = styled.p`
  &&& {
    font-size: 16px;
    margin-bottom: 21px;
    span {
      font-weight: bold;
      color: #448ced;
    }
  }
`;

export const MicroDataList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 480px) {
    justify-content: space-between;
  }
`;

export const MicroDataListItem = styled.li`
  background: #f4f5f7;
  border-radius: 2px;
  padding: 12px 15px;
  list-style: none;
  flex: 0 0 32%;
  max-width: 32%;
  height: 70px;
  margin-right: 2%;
  margin-bottom: 2%;
  &:nth-of-type(3n + 3) {
    margin-right: 0;
  }
  @media (max-width: 480px) {
    flex: 0 0 48.5%;
    max-width: 48.5%;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const ListItemTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ListItemSubtitle = styled.p`
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

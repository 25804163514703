import React, { useState } from 'react';

import { Icon, Table } from 'semantic-ui-react';

import {
  PapersContainer,
  PapersTable,
  PapersTableHeaderCell,
  PapersTableCell,
  PapersTableRow,
  PapersPagination,
  TrashBagIcon,
  PaperLink,
  PaperTitle
} from './styles';

import ContentContainer from 'src/containers/ContentContainer';

interface IProps {
  items: IPaperItem[];
  isAdmin?: boolean;
  deletePaper?: any;
}

interface IPaperItem {
  id: string;
  title: string;
  authors: [];
  dateUploaded: string;
  dept: string;
  courseName: string;
  year: string;
}

const MyPapersSection = (props: IProps) => {
  const { items, isAdmin, deletePaper } = props;
  const [activePage, setActivePage] = useState(1);

  const postsPerPage = 10;
  const indexOfLastPost = activePage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = items.slice(indexOfFirstPost, indexOfLastPost);

  const totalPages = Array.isArray(items)
    ? Math.ceil(items.length / postsPerPage)
    : 0;

  const onPageChange = (e: any, pageInfo: any) => {
    setActivePage(pageInfo.activePage);
  };
  const allPapers = currentPosts.map(
    ({
      _id,
      title,
      authors,
      dateUploaded,
      dept,
      courseName,
      year
    }: any) => (
      <PapersTableRow key={_id}>
        <PapersTableCell>
          <PaperLink to={`/paper/${_id}`}>
            <Icon name="file alternate outline" />
            <PaperTitle>{title || 'No title'}</PaperTitle>
          </PaperLink>
        </PapersTableCell>
        {isAdmin && <PapersTableCell>{authors}</PapersTableCell>}
        <PapersTableCell>{dateUploaded}</PapersTableCell>
        <PapersTableCell>{dept}</PapersTableCell>
        <PapersTableCell>{courseName}</PapersTableCell>
        <PapersTableCell>{year}</PapersTableCell>
        {!isAdmin && (
          <PapersTableCell>
            <TrashBagIcon
              name="trash alternate outline"
              onClick={() => deletePaper(_id)}
            />
          </PapersTableCell>
        )}
      </PapersTableRow>
    )
  );

  const papersPagination = items.length > postsPerPage && (
    <Table.Row>
      <Table.HeaderCell colSpan="6">
        <PapersPagination
          activePage={activePage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </Table.HeaderCell>
    </Table.Row>
  );

  return (
    <PapersContainer>
      <ContentContainer loading={false}>
        <PapersTable>
          <Table.Header>
            <Table.Row>
              <PapersTableHeaderCell>Paper name</PapersTableHeaderCell>
              {isAdmin && (
                <PapersTableHeaderCell>Student name</PapersTableHeaderCell>
              )}
              <PapersTableHeaderCell>Date uploaded</PapersTableHeaderCell>
              <PapersTableHeaderCell>Department</PapersTableHeaderCell>
              <PapersTableHeaderCell>Course</PapersTableHeaderCell>
              <PapersTableHeaderCell>Class</PapersTableHeaderCell>
              {!isAdmin && <PapersTableHeaderCell></PapersTableHeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>{allPapers}</Table.Body>
          <Table.Footer>{papersPagination}</Table.Footer>
        </PapersTable>
      </ContentContainer>
    </PapersContainer>
  );
};

export default MyPapersSection;

import styled from 'styled-components';

import { Select } from 'semantic-ui-react';

export const SelectClasses = styled(Select)`
  &&&&& {
    background: #3f4246;
    border: none !important;
    height: auto;
    color: #fff;
    margin-bottom: 8px;
    min-width: 100%;
    .text {
      color: #fff !important;
    }
    i {
      color: #fff;
    }
    .menu.transition {
      background: #3f4246;
      border: none;
      .item {
        color: #fff;
        border: none;
        &.active {
          .text {
            color: #7bb3ff !important;
          }
        }
      }
    }
  }
`;

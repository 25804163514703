import { useTranslation } from 'react-i18next';

export const hasErrors = ({ touched, errors }: any) => {
  const { t } = useTranslation();
  let error = '';

  if (touched && errors) {
    error = t(errors || 'Error');
  }

  return error;
};

export const displayError = ({ touched, errors }: any) => {
  const getErrors = hasErrors({ touched, errors });

  return getErrors.length > 0
    ? {
        label: getErrors,
        error: true
      }
    : {};
};

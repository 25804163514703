import React from 'react';

import { connect } from 'react-redux';
import { resetPassword } from 'src/redux/actions/AuthActions';

import { ResetPasswordForm } from 'src/components/Forms';
import { ScreenHeader } from 'src/components/Headers';

import ContainerOverview from 'src/containers/ContainerOverview';
import { Container } from 'semantic-ui-react';
import { FormContainer } from 'src/components/Forms/styles';

interface IProps {
  resetPassword: (email: string, history: any) => void;
  history: any;
}

const ResetPassword = (props: IProps) => (
  <ContainerOverview>
    <Container>
      <FormContainer>
        <ScreenHeader title="Reset Password" textAlign="center" />
        <ResetPasswordForm
          resetPassword={value => props.resetPassword(value, props.history)}
        />
      </FormContainer>
    </Container>
  </ContainerOverview>
);

export default connect(null, { resetPassword })(ResetPassword);

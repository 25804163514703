import React from 'react';
import { Loader } from 'semantic-ui-react';

interface IProps {
  active: boolean;
  children?: JSX.Element;
}
const MainLoader = ({ active, children }: IProps) => {
  return (
    <div>
      <Loader active={active} inline="centered">
        {children}
      </Loader>
    </div>
  );
};

export default MainLoader;

import styled from 'styled-components';

export const UploadPapersContainer = styled.div`
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  padding: 50px 45px;
  @media (max-width: 768px) {
    padding: 35px 22px;
  }
`;

export const UploadPapersTitle = styled.h2`
  margin-bottom: 15px;
  text-align: center;
`;

export const UploadPapersSubTitle = styled.p`
  text-align: center;
  margin-bottom: 25px;
`;

export const UploadDropareaContainer = styled.div`
  background: #f4f5f7;
  border: 1px solid #c4c8d1;
  border-radius: 3px;
  height: 330px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    height: 240px;
  }
`;

export const UploadDropareaTitle = styled.p`
  color: #777a80;
  margin: 25px 0 0;
  max-width: 200px;
`;

export const UploadDropareaButton = styled.span`
  color: #448ced;
  cursor: pointer;
  margin: 0 4px;
  &:hover {
    text-decoration: underline;
  }
`;

export const FileFormatErrorTitle = styled.p`
  color: #de594d;
  font-size: 16px;
  font-weight: bold;
  margin: 15px 0 0;
`;

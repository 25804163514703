import styled from 'styled-components';

import { Input } from 'semantic-ui-react';
import { ReactComponent as Logo } from 'assets/logo.svg';

export const SidebarContainer = styled.aside`
  background: #14161a;
  width: 275px;
  height: 100%;
  position: fixed;
  padding: 20px 0 25px 25px;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  overflow-y: scroll;
`;

export const SidebarLogo = styled(Logo)`
  cursor: pointer;
  max-width: 80px;
  margin-bottom: 50px;
`;

export const University = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #484b52;
`;

export const UniversityPreTitle = styled.span`
  color: #93959a;
  font-size: 14px;
  display: block;
  margin-bottom: 2px;
`;

export const UniversityTitle = styled.h4`
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  cursor: pointer;
`;

export const SidebarSearch = styled(Input)`
  &&& {
    width: 100%;
    max-width: 220px;
    margin-bottom: 20px;
    input {
      height: 36px;
      background: #3f4246;
      border-radius: 3px;
      font-size: 14px;
      color: #fff;
      &:focus {
        color: #fff;
        background: #3f4246;
        outline: none;
        border: 1px solid transparent;
        &::placeholder {
          opacity: 0;
        }
      }
      &::placeholder {
        color: #fff;
      }
      &::selection {
        background: #fff;
        color: #000;
      }
    }
    i {
      color: #fff;
      opacity: 1 !important;
    }
  }
`;

import styled from 'styled-components';

import { NavLink, Link } from 'react-router-dom';

interface IProps {
  isAdmin?: boolean;
}

export const Header = styled.section`
  margin-bottom: 0;
  background-color: #fff;
  height: 55px;
  overflow: hidden;
  padding: 0 35px 0 30px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.04);
  position: relative;
  & > div {
    align-items: center;
    height: 100%;
    > div {
      width: 100%;
      justify-content: flex-end;
      > a:first-child {
        margin-right: ${({ isAdmin }: IProps) => (isAdmin ? 'auto' : '26px')};
      }
    }
  }
  @media (max-width: 768px) {
    padding: 0 10px 0 30px;
  }
`;

export const MenuItem = styled(NavLink)`
  color: #212327;
  font-size: 14px;
  display: block;
  cursor: pointer;
  margin-right: 26px;
  padding: 6px 8px;
  border-radius: 4px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: #448ced;
  }
`;

export const MenuLogo = styled(Link)`
  &&& {
    cursor: pointer;
    z-index: 10;
    max-width: 80px;
    svg {
      max-width: 100%;
    }
  }
`;

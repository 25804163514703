import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';

import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { createToast } from 'src/redux/actions/ToastActions';
import { displayError } from 'src/services/helpers/FormHelper';
import SendMail from 'src/services/helpers/SendMailHelper';

interface IFormValues {
  name: string;
  email: string;
  message: string;
}

interface IProps {}

const ContactForm = (props: FormikProps<IFormValues> & IProps) => {
  const { t } = useTranslation();

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit
  }: FormikProps<IFormValues> = props;

  return (
    <Form loading={isSubmitting}>
      <Form.Input
        placeholder={t('name')}
        value={values.name}
        onChange={handleChange('name')}
        {...displayError({
          field: 'name',
          touched: touched.name,
          errors: errors.name
        })}
      />

      <Form.Input
        placeholder={t('email')}
        value={values.email}
        onChange={handleChange('email')}
        {...displayError({
          field: 'email',
          touched: touched.email,
          errors: errors.email
        })}
      />

      <Form.TextArea
        placeholder={t('message')}
        rows={10}
        value={values.message}
        onChange={handleChange('message')}
        {...displayError({
          field: 'message',
          touched: touched.message,
          errors: errors.message
        })}
      />

      <Button primary fluid onClick={() => handleSubmit()}>
        {t('send')}
      </Button>
    </Form>
  );
};

export default withFormik<IProps, IFormValues>({
  mapPropsToValues() {
    return {
      name: '',
      email: '',
      message: ''
    };
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Required Field'),
    email: Yup.string()
      .email('Invalid Field')
      .required('Required Field'),
    message: Yup.string().required('Required Field')
  }),
  async handleSubmit(values, { setSubmitting, resetForm }) {
    setSubmitting(true);

    await SendMail(values)
      .then(() => {
        createToast({
          type: 'success',
          description: 'Email has been successfully sent'
        });

        resetForm();
      })
      .catch(() =>
        createToast({
          type: 'error',
          description: 'Email has not been sent'
        })
      );

    setSubmitting(false);
  }
})(ContactForm);

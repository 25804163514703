import React from 'react';

import { connect } from 'react-redux';
import { loginUser } from 'src/redux/actions/AuthActions';

import { LoginForm } from 'src/components/Forms';
import { ScreenHeader } from 'src/components/Headers';

import { LoginCredentials } from 'src/interfaces/AuthInterface';

import { FormContainer, SubHeader } from 'src/components/Forms/styles';

import { Container } from 'semantic-ui-react';
import ContainerOverview from 'src/containers/ContainerOverview';

interface IProps {
  history: any;
  loginUser: (args: LoginCredentials, history: any) => void;
}

const Login = (props: IProps) => (
  <ContainerOverview>
    <Container>
      <FormContainer>
        <ScreenHeader title="Login to VEXT" textAlign="center" />
        
        <LoginForm history={props.history} loginUser={props.loginUser} />
      </FormContainer>
    </Container>
  </ContainerOverview>
);

export default connect(null, { loginUser })(Login);

import React from 'react';

interface IProps {
  children: React.ReactNode;
}

import { Overview } from './styles';

const ContainerOverview = ({ children }: IProps) => (
  <Overview>{children}</Overview>
);

export default ContainerOverview;

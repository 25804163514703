import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';

import monitorReducersEnhancer from '../../enhancers/monitorReducer';
import rootReducer from '../reducers';

export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware];
  const enhancers = [];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
    enhancers.push(monitorReducersEnhancer);
  }

  const middlewareEnhancer = applyMiddleware(...middlewares);
  enhancers.push(middlewareEnhancer);

  const composedEnhancers = composeWithDevTools(...enhancers);
  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

interface IPropsLink {
  align?: string;
}

export const FormContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  padding: 40px 100px;
  border-radius: 3px;
  max-width: 540px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

export const SubHeader = styled.p`
  font-size: 15px;
  color: #7e8288;
  text-align: center;
  margin-bottom: 30px;
`;

export const ForgotPasswordLink = styled(Link)`
  display: block;
  text-align: ${({ align }: IPropsLink) => align || 'right'};
  font-size: 14px;
  @media (max-width: 768px) {
    text-align: left !important;
  }
`;

export const SubmitButton = styled(Button)`
  &&& {
    margin: 15px auto 0 !important;
    display: block;
  }
`;

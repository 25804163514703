import styled from 'styled-components';

export const BreadcrumbsContainer = styled.div`
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 28px;
  margin-top: -30px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const BreadcrumbsList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
`;

export const BreadcrumbsListItem = styled.li`
  list-style: none;
  font-size: 14px;
  color: #abafb7;
  cursor: pointer;
  margin-right: 40px;
  position: relative;
  &:before {
    content: '❯';
    position: absolute;
    top: 1px;
    right: -25px;
  }
  &:last-child {
    color: #212327;
    margin-right: 0;
    &:before {
      display: none;
    }
  }
`;

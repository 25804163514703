import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { API_KEY, API_URL, API_URL_2, API_URL_3, VEXT_API } from 'src/config';
import { SignUpCredentials } from 'src/interfaces/AuthInterface';

import { IBreadcrumbInfo } from 'src/interfaces/DashboardDataInterface';
import { IPaper } from 'src/interfaces/PapersInterface';
import { buildDashboardParamUrl } from 'src/services/helpers/DashboardUrlBuilder';

import { dashboardData } from './dashboardData';

const orgId = 'V1003';
export let orgId2 = null;

export const fetchUniversities = () => fetch(`${API_URL_3}/get-universities?key=${API_KEY}`)
  .then(async response => {
    const parsedResponse = await response.json();
    const { data } = parsedResponse;
    const university = data[0];
    orgId2 = university._id;
  })
  .catch(e => {});

// Creates a new user in the db and returns the userId
export const createUser = (credentials: SignUpCredentials, cognitoId: string) => {
  return axios.get(
    `${API_URL_3}/create-user?orgid=${orgId2}&username=${credentials.email}&&firstname=${credentials.firstName}&lastname=${credentials.lastName}&email=${credentials.email}&cognitoid=${cognitoId}&key=${API_KEY}`
  );
};

// Returns info about an user by his cognitoId
export const getUserInfoByCognitoId = (cognitoId: string) => {
  return axios.get(
    `${API_URL_3}/get-user-info-by-cognito-id?cognitoid=${cognitoId}&key=${API_KEY}`
  );
};

// returns the title of a specified arXiv paper
export const getTitle = (paperId: string) => {
  // ex: 1806.00414
  return axios.get(`${API_URL_3}/get-title/${paperId}?key=${API_KEY}`);
};

//  returns a list of authors for a specified arXiv paper
export const getAuthors = (paperId: string) => {
  // ex: 1806.00414
  return axios.get(`${API_URL_3}/get-authors/${paperId}?key=${API_KEY}`);
};

// returns the abstract for a specified arXiv paper
export const getAbstract = (paperId: string) => {
  // ex: 1806.00414
  return axios.get(`${API_URL_3}/get-abstract/${paperId}?key=${API_KEY}`);
};

// returns the number of distinct arXiv groups in the VEXT DB
export const getDistinctGroups = () => {
  return axios.get(`${API_URL_3}/get-distinct-groups?key=${API_KEY}`);
};

export const getPapersInGroup = (groupId: string) => {
  // ex: 1805
  return axios.get(`${API_URL_3}/get-papers-in-group/${groupId}?key=${API_KEY}`);
};

// returns the papers s3 url
const getPaperUploadUrl = (userId: string, filename: string, classid: string) => {
  const mimeType = 'application/pdf';

  return axios.get(
    `${API_URL_3}/get-file-upload-url?orgid=${orgId2}&userid=${userId}&classid=${classid}&filename=${filename}&mimetype=${mimeType}&key=${API_KEY}`
  );
};

// upload paper to s3 bucket
export const uploadPaper = (userId: string, file: any, filename: string, classid: string) => {
  const fd: FormData = new FormData();
  fd.append('file', file, filename);

  return getPaperUploadUrl(userId, filename, classid).then(async response => {
    const signedUrl = response.data.data.signedUrl;
    await axios.put(signedUrl, fd);
    return response;
  });
};

// list papers
export const listPapers = (userId: string, continuationToken?: '') => {
  const maxItems = 20;
  return axios.get(
    `${API_URL_3}/list-items?orgid=${orgId}&userid=${userId}&maxitems=${maxItems}&continuationtoken=${continuationToken}&key=${API_KEY}`
  );
};

// get papers by userId
export const getPapersByUserId = (userId: string) => {
  return axios.get(
    `${API_URL_3}/get-vext-papers-by-userid?userid=${userId}&key=${API_KEY}`
  );
};

// set paper info
export const setVextPaperInfo = (
  userId: string,
  { authors, title, discipline, filename }: IPaper,
  paperId: string
) => {
  const arrayAuthors = authors.includes(',') ? authors.split(',') : [authors];
  return axios.get(
    `${API_URL_3}/set-vext-paper-info?userid=${userId}&orgid=${orgId}&paperid=${paperId}&authors=${arrayAuthors}&title=${title}&subject=${discipline}&filename=${filename}&key=${API_KEY}`
  );
};

export const getPaperInfo = (paperId: string) => {
  return axios.get(
    `${API_URL_3}/get-vext-paper-info?paperid=${paperId}&key=${API_KEY}`
  );
};

export const vextImageUrls = (paperId: string) => {
  return axios.get(
    `${API_URL_3}/vext-image-urls?paperid=${paperId}&key=${API_KEY}`
  );
};

export const processPaperInfo = (paperId: string, userId: string, authors: string, title: string) => {
  return axios.get(
    `${VEXT_API}/process-vext-pdf?paperid=${paperId}&authors=[${authors}]&title=${title}&key=${API_KEY}`
  );
};

export const deleteVextPaper = (paperId: string) => {
  return axios.delete(
    `${API_URL_3}/delete-vext-paper?paperid=${paperId}&key=${API_KEY}`
  );
};

export const getDashboardData = () => {
  return axios.get('/dashboard');
};

export const getDashboardMicroData = (breadcrumbInfo: IBreadcrumbInfo) => {
  const paramUrl = buildDashboardParamUrl(breadcrumbInfo);
  return axios.get(
    `${API_URL_3}/get-micro-data?${paramUrl}&key=${API_KEY}`
  );
};

export const getDashboardTopKeywords = (breadcrumbInfo: IBreadcrumbInfo) => {
  const paramUrl = buildDashboardParamUrl(breadcrumbInfo);
  return axios.get(
    `${API_URL_3}/get-top-keywords?${paramUrl}&key=${API_KEY}`
  );
};

export const getDashboardSentimentHeatmap = (breadcrumbInfo: IBreadcrumbInfo, collegeYear?: string, collegeSemester?: string) => {
  let paramUrl = buildDashboardParamUrl(breadcrumbInfo);
  if(!breadcrumbInfo.classid) {
    paramUrl += `&year=${collegeYear}&semester=${collegeSemester}`;
  }
  return axios.get(
    `${API_URL_3}/get-sentiment-heatmap?${paramUrl}&key=${API_KEY}`
  );
};

export const getDashboardCitationIncreaseDecrease = (breadcrumbInfo: IBreadcrumbInfo) => {
  const paramUrl = buildDashboardParamUrl(breadcrumbInfo);
  return axios.get(
    `${API_URL_3}/get-avg-citation-sentiment?${paramUrl}&key=${API_KEY}`
  );
};

export const getVextPapersByTitleSearch = (title: string) => {
  return axios.get(
    `${API_URL_3}/get-vext-papers-by-title-search?search=${title}&key=${API_KEY}`
  );
};

export const getColleges = () => {
  return axios.get(`${API_URL_3}/get-colleges?orgid=${orgId2}&key=${API_KEY}`);
};

export const getCollegeInfo = (collegeid: string) => {
  return axios.get(`${API_URL_3}/get-college-info?collegeid=${collegeid}&key=${API_KEY}`);
};

export const getDepartments = (collegeId: string) => {
  return axios.get(`${API_URL_3}/get-departments?orgid=${orgId2}&collegeid=${collegeId}&key=${API_KEY}`)
}

export const getDepartmentsCoursesAndClasses = (collegeID: string | undefined) => {
  return axios.get(
    `${API_URL_3}/get-departments-courses-and-classes?orgid=${orgId2}&collegeid=${collegeID}&key=${API_KEY}`
  );
};

import React from 'react';
import { Grid, Image, Button } from 'semantic-ui-react';
import { WorkflowBoxContent, BackSideContainer } from './styles';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

import InstitutionImage from 'src/assets/workflow/institution.png';
import StudentImage from 'src/assets/workflow/student.png';
import FingerprintImage from 'src/assets/paper/fingerprint.png';

const WorkflowSection = () => {
  return (
    <Grid container columns={2} doubling>
      <Grid.Column>
        <WorkflowBox
          frontSide={
            <>
              <b>Students:</b> <br />
              Upload papers <br />
              Analyze and improve writing
              <br />
              Connect your ideas with your peers
            </>
          }
          backSide={<Image src={FingerprintImage} />}
        />
      </Grid.Column>

      <Grid.Column>
        <WorkflowBox
          frontSide={
            <>
              Click here to contact VEXT sales for pedagogically-based solutions
            </>
          }
          backSide={
            <div>
              <Button primary onClick={() => window.open('/contact')}>
                Contact Us
              </Button>
            </div>
          }
        />
      </Grid.Column>

      <Grid.Column>
        <WorkflowBox
          frontSide={
            <>
              <b>Students/Faculty:</b>
              <br />
              Spot trends <br />
              View impact of pedagogy <br />
              Real-time analyses across courses
            </>
          }
          backSide={<Image src={StudentImage} />}
        />
      </Grid.Column>

      <Grid.Column>
        <WorkflowBox
          frontSide={
            <>
              <b>Faculty/Administrators:</b>
              <br />
              Cut costs
              <br />
              View curriculum outputs
              <br />
              Get re-accreditation metrics
            </>
          }
          backSide={<Image src={InstitutionImage} />}
        />
      </Grid.Column>
    </Grid>
  );
};

const WorkflowBox = ({
  frontSide,
  backSide
}: {
  frontSide: JSX.Element;
  backSide: JSX.Element;
}) => (
  <Flippy
    flipOnHover={true}
    flipDirection="horizontal"
    style={{ height: '100%' }}
  >
    <FrontSide>
      <WorkflowBoxContent>{frontSide}</WorkflowBoxContent>
    </FrontSide>
    <BackSide>
      <BackSideContainer>{backSide}</BackSideContainer>
    </BackSide>
  </Flippy>
);

export default WorkflowSection;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, List } from 'semantic-ui-react';

import {
  InformationContainer,
  InformationItemHeader,
  InformationItemContent,
  InformationeSectionItem
} from './styles';

interface Item {
  title: string;
  content: JSX.Element;
  history: any;
}

const InformativeDashboardSection = (props: any) => {
  const { t } = useTranslation();

  return (
    <InformationContainer>
      <Grid columns={3} doubling stackable>
        <Grid.Column>
          <SectionItem
            title={'Students'}
            content={
              <>
                <p>Data visualization for pattern identification</p>
                <p>
                  Sentiment analysis for metrics on engagement, attitudes,
                  biases
                </p>
                <p>Formative and summative data analytics to improve writing</p>
                <p>
                  Open access knowledge sharing to map your ideas and search
                  your peers’ ideas
                </p>
              </>
            }
            history={props.history}
          />
        </Grid.Column>
        <Grid.Column>
          <SectionItem
            title={'Faculty'}
            content={
              <>
                <p>
                  Dashboards with macro insights across all students and courses
                </p>
                <p>Data visualization for trend spotting and tracking </p>
                <p>Visualize the impact of pedagogy</p>
                <p>
                  Crowdsourced plagiarism detection so you can see borrowed or
                  unoriginal text
                </p>
              </>
            }
            history={props.history}
          />
        </Grid.Column>

        <Grid.Column>
          <SectionItem
            title="Administrators"
            content={
              <>
                <p>
                  Dashboard so you can operate as a data-driven decision maker
                </p>
                <p>
                  Save tens of thousands to hundreds of thousands of dollars
                </p>
                <p>
                  Analytics to to see if you are delivering on the promise of
                  your curriculum
                </p>
                <p>
                  Impact metrics for program analysis and re-accreditation
                  reviews
                </p>
                <p>
                  Open, searchable database so students, faculty, and University
                  can access the knowledge being created across your institution
                </p>
              </>
            }
            history={props.history}
          />
        </Grid.Column>
      </Grid>
    </InformationContainer>
  );
};

const SectionItem = ({ title, content, history }: Item) => (
  <InformationeSectionItem basic onClick={() => history.push('/account')}>
    <InformationItemHeader>{title}</InformationItemHeader>
    <InformationItemContent>{content}</InformationItemContent>
  </InformationeSectionItem>
);

export default withRouter(InformativeDashboardSection);

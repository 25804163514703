import React from 'react';

import { SearchMessage } from './styles';

import CollegeItem from 'components/CollegeItem';

const CollegesList = ({ colleges }: any): any =>
  Array.isArray(colleges) ? (
    colleges.map(college => <CollegeItem key={college._id} {...college} />)
  ) : (
    <SearchMessage>Nothing was found</SearchMessage>
  );

export default React.memo(CollegesList);

import React, { Fragment } from 'react';

interface IProps {
  value: string | undefined;
  for: string;
}

const ErrorLabel = (props: IProps) => (
  <Fragment>
    {props.value && props.value.length > 0 && (
      <label htmlFor={props.for} style={{ color: '#9f3a38' }}>
        {props.value}
      </label>
    )}
  </Fragment>
);

export default ErrorLabel;

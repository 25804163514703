import React, { Fragment, useEffect } from 'react';

import get from 'lodash/get';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import { AttributesRoute } from './attributes.route';
import { PropperRoute } from './propper.route';
import { ProtectedRoute } from './protected.route';

import AdminSidebar from 'src/components/AdminSidebar';
import { LoadingContainer } from 'src/components/Containers';
import Header from 'src/components/Header';
import DashboardDataProvider from 'src/contexts/DashboardDataProvider';

import About from 'src/screens/About';
import Contact from 'src/screens/Contact';
import Home from 'src/screens/Home';
import Login from 'src/screens/Login';
import Logout from 'src/screens/Logout';
import NewPassword from 'src/screens/NewPassword';
import ResetPassword from 'src/screens/ResetPassword';
import Search from 'src/screens/Search';
import SignUp from 'src/screens/Signup';

import { IApp, IAuth } from 'src/interfaces/ReducersInterface';
import { saveCurrentUser } from 'src/redux/actions/AuthActions';
import Account from 'src/screens/Account';
import Dashboard from 'src/screens/Dashboard';
import { NotFoundError } from 'src/screens/Errors';
import Invitation from 'src/screens/Invitation';
import Paper from 'src/screens/Paper';
import Settings from 'src/screens/Settings';
import TermsAndConditions from 'src/screens/TermsAndConditions';
import UploadPapers from 'src/screens/UploadPapers';
import WaitingResult from 'src/screens/WaitingResult';

interface IProps extends IAuth {
  saveCurrentUser: (bypassCache?: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  user: any;
}

const GlobalStyle = createGlobalStyle`
  body {
    padding-left: 275px !important;
  }
  @media (max-width: 768px) {
    body {
      padding-left: 0 !important;
    }
    .pusher {
      padding-left: 275px;
    }
  }
`;

const Routes = (props: IProps) => {
  const { loading, loggedIn, saveCurrentUser, user } = props;

  useEffect(() => {
    saveCurrentUser();
  }, []);

  const isAdmin = Boolean(get(user, 'custom:isAdmin', false));

  return !loading ? (
    <DashboardDataProvider>
      <Header>
        {isAdmin && (
          <Fragment>
            <GlobalStyle />
            <AdminSidebar />
          </Fragment>
        )}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/resetpassword" component={ResetPassword} />

          {/* Authentification */}
          <PropperRoute
            path="/login"
            authenticated={loggedIn}
            component={Login}
          />
          <PropperRoute
            path="/signup"
            authenticated={loggedIn}
            component={SignUp}
          />

          <ProtectedRoute
            path="/logout"
            authenticated={loggedIn}
            component={Logout}
          />

          <Route path="/newpassword" component={NewPassword} />

          <ProtectedRoute
            path="/invitation"
            authenticated={loggedIn}
            component={Invitation}
          />

          {/* Account */}
          <ProtectedRoute
            path="/account"
            authenticated={loggedIn}
            component={Account}
          />

          <ProtectedRoute
            path="/search"
            authenticated={loggedIn}
            component={Search}
          />

          <ProtectedRoute
            path="/settings"
            authenticated={loggedIn}
            component={Settings}
          />

          <ProtectedRoute
            path="/dashboard"
            authenticated={loggedIn}
            component={Dashboard}
          />

          <ProtectedRoute
            path="/upload-papers"
            authenticated={loggedIn}
            component={UploadPapers}
          />

          <ProtectedRoute
            path="/paper/:paperId"
            authenticated={loggedIn}
            component={Paper}
          />

          {/* TERMS AND CONDITIONS */}
          <Route path="/terms-and-conditions" component={TermsAndConditions} />

          {/* DEV */}
          <Route exact path="/waitingResponse" component={WaitingResult} />

          <Route component={NotFoundError} />
        </Switch>
      </Header>
    </DashboardDataProvider>
  ) : (
    <LoadingContainer loading={loading} />
  );
};

const mapStateToProps = ({ auth, app }: { auth: IAuth; app: IApp }) => ({
  loggedIn: auth.loggedIn,
  user: auth.user,
  loading: app.loading
});

const connectWithRouter: any = withRouter;

export default connectWithRouter(
  connect(mapStateToProps, { saveCurrentUser })(Routes)
);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Container, Button } from 'semantic-ui-react';
import { HSContainer, HSHeader, HSSubheader } from './styles';

const HomePageSubheading = (props: any) => {
  const { t } = useTranslation();
  return (
    <HSContainer basic>
      <Container>
        <HSHeader>{t('Home Subheader Title')}</HSHeader>
        <HSSubheader>{t('Home Subheader Subtitle')}</HSSubheader>
        <HSSubheader>{t('Home Subheader Subtitle 2')}</HSSubheader>

        <Button
          style={{ marginRight: '30px' }}
          secondary
          onClick={() => props.history.push('/signup')}
        >
          {' '}
          Student Sign Up
        </Button>
        <Button secondary onClick={() => props.history.push('/contact')}>
          {' '}
          Administrator Sign Up
        </Button>
      </Container>
    </HSContainer>
  );
};

export default withRouter(HomePageSubheading);

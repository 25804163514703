import React from 'react';
import { Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface IProps {
  children: React.ReactNode;
}

const UploadButton = ({ children }: IProps) => {
  const history = useHistory();
  const loggedIn = useSelector((state: any) => state.auth.loggedIn);

  const userRedirect = () => {
    const redirectPath = loggedIn ? '/upload-papers' : '/login';
    history.push(redirectPath);
  };

  return (
    <Button primary onClick={userRedirect}>
      {children}
    </Button>
  );
};

export default UploadButton;

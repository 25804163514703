import React from 'react';

import { Form, Dropdown } from 'semantic-ui-react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { displayError } from 'src/services/helpers/FormHelper';
import { ErrorLabel } from 'src/components/Labels';
import { CountryCodes, Disciplines } from 'src/constants';

import { SubmitButton } from './styles';

interface IFormValues {
  name: string;
  'custom:discipline': string;
  'custom:institution_zip': string;
  'custom:institution_code': string;
  'custom:institution': string;
  'custom:home_code': string;
}

interface IProps {
  updateAttributes: (args: any) => void;
  email: string;
  name: string;
  discipline: string;
  zip: string;
  code: string;
  institution: string;
  homeCode: string;
}

interface IFormSubmit {
  handleSubmit: () => void;
}

const UpdateProfileForm = (props: FormikProps<IFormValues> & IProps) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue
  }: FormikProps<IFormValues> & IFormSubmit = props;

  const onChange = (field: string, value: any) => {
    setFieldValue(field, value);
  };

  return (
    <Form loading={isSubmitting}>
      <Form.Input
        fluid
        label="Full Name"
        value={values.name}
        onChange={handleChange('name')}
        {...displayError({
          field: 'name',
          touched: touched.name,
          errors: errors.name
        })}
      />

      <Form.Input fluid label="Email" value={props.email} readOnly />

      <Form.Field>
        <ErrorLabel for="discipline" value={errors['custom:discipline']} />
        <label>Select Discipline</label>
        <Dropdown
          fluid
          selection
          options={Disciplines}
          value={values['custom:discipline']}
          onChange={(_, { value }) => onChange('custom:discipline', value)}
          {...displayError({
            field: 'custom:discipline',
            touched: touched['custom:discipline'],
            errors: errors['custom:discipline']
          })}
        />
      </Form.Field>

      <Form.Input
        fluid
        label="Institution Zip"
        value={values['custom:institution_zip']}
        onChange={handleChange('custom:institution_zip')}
        {...displayError({
          field: 'custom:institution_zip',
          touched: touched['custom:institution_zip'],
          errors: errors['custom:institution_zip']
        })}
      />

      <Form.Field>
        <ErrorLabel
          for="institution_code"
          value={errors['custom:institution_code']}
        />
        <label>Select Institution Code</label>
        <Dropdown
          fluid
          selection
          options={CountryCodes}
          value={values['custom:institution_code']}
          onChange={(_, { value }) =>
            onChange('custom:institution_code', value)
          }
          {...displayError({
            field: 'custom:institution_code',
            touched: touched['custom:institution_code'],
            errors: errors['custom:institution_code']
          })}
        />
      </Form.Field>

      <Form.Input
        fluid
        label="Institution Name"
        value={values['custom:institution']}
        onChange={handleChange('custom:institution')}
        {...displayError({
          field: 'custom:institution',
          touched: touched['custom:institution'],
          errors: errors['custom:institution']
        })}
      />

      <Form.Field>
        <ErrorLabel for="custom:home_code" value={errors['custom:home_code']} />
        <label>Select Home Code</label>
        <Dropdown
          fluid
          selection
          options={CountryCodes}
          value={values['custom:home_code']}
          onChange={(_, { value }) => onChange('custom:home_code', value)}
          {...displayError({
            field: 'custom:home_code',
            touched: touched['custom:home_code'],
            errors: errors['custom:home_code']
          })}
        />
      </Form.Field>

      <SubmitButton
        primary
        floated="right"
        type="submit"
        onClick={handleSubmit}
      >
        Save
      </SubmitButton>
    </Form>
  );
};

export default withFormik<IProps, IFormValues>({
  mapPropsToValues({ name, discipline, zip, code, institution, homeCode }) {
    return {
      name: name || '',
      'custom:discipline': discipline || '',
      'custom:institution_zip': zip || '',
      'custom:institution_code': code || '',
      'custom:institution': institution || '',
      'custom:home_code': homeCode || ''
    };
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Required Field'),
    'custom:institution_zip': Yup.string().required('Required Field'),
    'custom:institution': Yup.string().required('Required Field')
  }),
  async handleSubmit(values, { setSubmitting, props }) {
    setSubmitting(true);

    await props.updateAttributes(values);

    setSubmitting(false);
  }
})(UpdateProfileForm);

import React from 'react';

import { Checkbox } from './styles';

import { uuid } from 'uuidv4';

interface ICheckboxProps {
  label: string;
  checked: boolean;
  onChange: any;
  error?: boolean;
}

const CheckboxField = ({ label, checked, onChange, error }: ICheckboxProps) => {
  const uniqueId = uuid();
  return (
    <Checkbox className={`field ${error ? 'error' : ''}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id={uniqueId}
      />
      <label htmlFor={uniqueId}>{label}</label>
    </Checkbox>
  );
};

export default CheckboxField;

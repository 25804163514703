// SAVE USER
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAILED = 'SAVE_USER_FAILED';
export const SAVE_USER = 'SAVE_USER';

// LOGIN
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

// SIGNUP
export const REGISTER_ADMIN_SUCCESS = 'REGISTER_ADMIN_SUCCESS';
export const REGISTER_ADMIN_FAILED = 'REGISTER_ADMIN_FAILED';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

// LOG OUT
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

// UPDATE ATTRIBUTES
export const UPDATE_ATTR_USER_SUCCESS = 'UPDATE_ATTR_USER_SUCCESS';
export const UPDATE_ATTR_USER_FAILED = 'UPDATE_ATTR_USER_FAILED';
export const UPDATE_ATTR_USER = 'UPDATE_ATTR_USER';

// ROOT LOADER
export const LOADING_START = 'LOADING_START';
export const LOADING_STOP = 'LOADING_STOP';

// CUSTOM
export const LOADING_AUTH_START = 'LOADING_AUTH_START';
export const LOADING_AUTH_STOP = 'LOADING_AUTH_STOP';

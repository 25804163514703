import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { Form } from 'semantic-ui-react';

import { SubmitButton } from './styles';

import { SignUpCredentials } from 'src/interfaces/AuthInterface';
import { displayError } from 'src/services/helpers/FormHelper';

import CheckboxField from '../Forms/CheckboxField/CheckboxField';

interface IFormValues extends SignUpCredentials {
  confirmPassword: string;
  signAgreement: boolean;
}
interface IProps {
  signUpUser: (args: SignUpCredentials, history: any) => void;
  history: any;
}

interface IFormSubmit {
  handleSubmit: () => void;
}

const SignupForm = (props: FormikProps<IFormValues> & IProps) => {
  const { t } = useTranslation();

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit
  }: FormikProps<IFormValues> & IFormSubmit = props;

  return (
    <Form size="large" loading={isSubmitting}>
      <Form.Input
        fluid
        label="First Name"
        value={values.firstName}
        onChange={handleChange('firstName')}
        {...displayError({
          field: 'firstName',
          touched: touched.firstName,
          errors: errors.firstName
        })}
      />

      <Form.Input
        fluid
        label="Last Name"
        value={values.lastName}
        onChange={handleChange('lastName')}
        {...displayError({
          field: 'lastName',
          touched: touched.lastName,
          errors: errors.lastName
        })}
      />

      <Form.Input
        fluid
        label="Email"
        type="email"
        value={values.email}
        onChange={handleChange('email')}
        {...displayError({
          field: 'email',
          touched: touched.email,
          errors: errors.email
        })}
      />

      <Form.Input
        fluid
        label="Password"
        type="password"
        value={values.password}
        onChange={handleChange('password')}
        {...displayError({
          field: 'password',
          touched: touched.password,
          errors: errors.password
        })}
      />

      <Form.Input
        fluid
        label="Repeat Password"
        type="password"
        value={values.confirmPassword}
        onChange={handleChange('confirmPassword')}
        {...displayError({
          field: 'confirmPassword',
          touched: touched.confirmPassword,
          errors: errors.confirmPassword
        })}
      />

      <CheckboxField
        label={t('Accept Terms')}
        checked={values.signAgreement}
        onChange={handleChange('signAgreement')}
        {...displayError({
          field: 'signAgreement',
          touched: touched.signAgreement,
          errors: errors.signAgreement
        })}
      />

      <SubmitButton primary type="submit" onClick={handleSubmit}>
        {t('Register')}
      </SubmitButton>
    </Form>
  );
};

export default withFormik<IProps, IFormValues>({
  mapPropsToValues() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      signAgreement: false
    };
  },
  validationSchema: Yup.object().shape({
    firstName: Yup.string().required('Required Field'),
    lastName: Yup.string().required('Required Field'),
    email: Yup.string()
      .email('Invalid Field')
      .required('Required Field'),
    password: Yup.string()
      .min(6, 'Password Min Length')
      .required('Required Field'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords do not match')
      .required('Required Field'),
    signAgreement: Yup.boolean().oneOf(
      [true],
      'Must Accept Terms and Conditions'
    )
  }),
  async handleSubmit(values, { setSubmitting, props }) {
    setSubmitting(true);

    await props.signUpUser(values, props.history);

    setSubmitting(false);
  }
})(SignupForm);

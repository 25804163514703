import styled from 'styled-components';

interface IProps {
  isActive?: boolean;
}

export const CourseItemContainer = styled.div`
  margin-top: 6px;
  &:nth-of-type(1) {
    margin-top: 10px;
  }
`;

export const CourseItemTitle = styled.p`
  color: ${({ isActive }: IProps) => (isActive ? '#7BB3FF' : '#cbcbcb')};
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  span {
    margin-left: 3px;
  }
  &:hover {
    color: #7bb3ff;
  }
`;

import React from 'react';

import { Input, Select } from 'semantic-ui-react';

import {
  DeletePaperIcon,
  UploadedPapersFormField,
  UploadedPapersFormGroup
} from '../UploadedPapers/styles';

interface IProps {
  title: string;
  deletePaper: (paperID: string) => void;
}

const UploadedPaper = ({ title, deletePaper }: IProps) => (
  <UploadedPapersFormGroup>
    <UploadedPapersFormField
      control={Input}
      icon="file alternate outline"
      iconPosition="left"
      placeholder="Enter title of paper (required)"
      value={title}
    />
    <UploadedPapersFormField
      control={Select}
      placeholder="Select college (required)"
      search
    />
    <UploadedPapersFormField
      control={Select}
      placeholder="Select department (required)"
      search
    />
    <UploadedPapersFormField
      control={Select}
      placeholder="Select course (required)"
      search
    />
    <UploadedPapersFormField
      control={Select}
      placeholder="Select class (required)"
      search
    />
    <DeletePaperIcon link name="delete" onClick={deletePaper} />
  </UploadedPapersFormGroup>
);

export default React.memo(UploadedPaper);

import React from 'react';

import Amplify from '@aws-amplify/core';
import GoogleAnalytics from 'react-ga';
import { Provider } from 'react-redux';
import { SemanticToastContainer } from 'react-semantic-toasts';

import MainLoader from 'src/components/Loader';
import { GA_KEY } from 'src/config';
import 'src/i18n';
import { ApiCalls } from 'src/services/api';
import { useFetchUniversities } from 'src/services/hooks/useFetchUniversities';
import awsmobile from './aws-exports';
import Layout from './containers/Layout';
import configureStore from './redux/store/configureStore';
import Routes from './routes';

// Amplify Setup
Amplify.configure(awsmobile);

// Initialize redux store
const store = configureStore();

// Google Analytics Setup
GoogleAnalytics.initialize(GA_KEY);
GoogleAnalytics.pageview(window.location.pathname + window.location.search);

const App = () => {
  const universitiesLoading = ApiCalls.orgId2 ? false : useFetchUniversities();
  if (universitiesLoading) {
    return (
      <MainLoader active={universitiesLoading}>
        <p>Loading</p>
      </MainLoader>
    );
  }
  return (
    <Provider store={store}>
      <SemanticToastContainer position="bottom-left" />
      <Layout>
        <Routes />
      </Layout>
    </Provider>
  );
};

export default App;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Segment,
  Container,
  Header,
  Image,
  Icon,
  Table,
  Pagination
} from 'semantic-ui-react';

// Informative section
export const InformationeSectionItem = styled(Segment)`
  cursor: pointer;
  border-radius: 5px !important;
  height: 100%;
  background: #fff !important;
  box-shadow: 0px 1px 3px 0px rgba(189, 189, 189, 1) !important;
  &:hover {
    -webkit-box-shadow: 0px 8px 11px -6px rgba(189, 189, 189, 1) !important;
    -moz-box-shadow: 0px 8px 11px -6px rgba(189, 189, 189, 1) !important;
    box-shadow: 0px 8px 11px 0px rgba(189, 189, 189, 1) !important;
    transition: all 300ms ease;
  }
`;

export const InformationContainer = styled(Container)`
  padding: 30px;
  background: steelblue;
  width: 100% !important;
`;

export const InformationItemHeader = styled(Header)`
  font-size: 24px !important;
`;

export const InformationItemContent = styled.div`
  font-size: 16px;
  color: #545556;
`;

// Tech
export const TechContainer = styled(Segment)`
  padding: 50px 0px 50px 0px !important;
`;

export const TechHeader = styled.p`
  font-size: 36px;
  color: #ffffff;
  font-weight: 600;
`;

export const TechSubheader = styled.p`
  margin-top: 10px;
  font-size: 18px;
  color: #ffffff;
`;

export const TechImage = styled(Image)`
  padding: 30px;
`;

export const TechName = styled.p`
  font-size: 16px;
  font-weight: 600;
`;

// Workflow section
export const WorkflowBoxContainer = styled.div`
  flex: 1;
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 15px;
`;

export const WorkflowBoxContent = styled.p`
  font-size: 16px;

  b {
    font-size: 18px;
  }
`;
// Pricing
export const PricingContainer = styled(Segment)`
  padding: 50px 0px 50px 0px !important;
`;

export const PricingHeader = styled.p`
  font-size: 36px;
  color: #000000;
  font-weight: 600;
`;

export const PricingContent = styled.p`
  text-align: center;
  font-size: 16px;
`;

// Blog Articoles
export const BlogArticlesContainer = styled(Segment)`
  padding: 50px 0px 50px 0px !important;
`;

// Workflow Section
export const BackSideContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// Papers
export const PapersContainer = styled.div`
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  padding: 22px;
`;

export const PapersTable = styled(Table)`
  &&& {
    border-radius: 0;
    border: 1px solid #c4c8d1;
  }
`;

export const PapersTableHeaderCell = styled(Table.HeaderCell)`
  &&& {
    border-bottom: 1px solid #c4c8d1;
    background: #f4f5f7;
  }
`;

export const PapersTableCell = styled(Table.Cell)`
  &&& {
    color: #707377;
    border-top: 1px solid #c4c8d1;
  }
`;

export const PapersTableRow = styled(Table.Row)`
  &&& {
    &:hover {
      background: #fafbfc;
    }
  }
`;

export const PapersPagination = styled(Pagination)`
  float: right;
`;

export const TrashBagIcon = styled(Icon)`
  font-size: 14px;
  color: #212327;
  float: right;
  cursor: pointer;
  &:hover {
    color: #448ced;
  }
  @media (max-width: 768px) {
    float: none;
    padding: 6px;
  }
`;

export const PaperLink = styled(Link)`
  color: #212327;
  font-size: 16px;
  display: flex;
  align-items: center;
  &:hover {
    color: #212327;
  }
`;

export const PaperTitle = styled.span`
  font-size: 14px;
  color: #212327;
  font-weight: bold;
  display: block;
  margin-left: 5px;
`;

// Premium Papers
export const PremiumPaperContainer = styled.div`
  width: 100%;
  min-height: 100px;
  background-color: #f8fafe;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 10px;
`;

export const PremiumPaperTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

// Papers Details
export const PapersDetailsContainer = styled(Segment)`
  box-shadow: none !important;
  background-color: #f5f5f5 !important;
`;

export const SubmissionColumnContainer = styled(Segment)`
  box-shadow: none !important;
  > * {
    color: #666666 !important;
    font-size: 36px;
    font-weight: 600;
  }
`;

// Footer
export const FooterContainer = styled(Segment)`
  box-shadow: none !important;
  background-color: #376fe1 !important;
  border-radius: 0px !important;

  * {
    color: #ffffff !important;
  }

  a {
    text-align: center;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
`;

export const SiteMapContainer = styled(Container)`
  margin: 20px 0px;
`;

export const TACContainer = styled(Container)`
  padding: 10px 0;
`;

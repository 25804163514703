export const microDataSubtitleEnum: { [key: string]: string} = {
  "citations_per_paper": "Citations Per Paper",
  "files_per_student": "Files Per Student",
  "papers_with_no_citations": "Papers With No Citations",
  "pct_negative_citations": "Negative Citations",
  "pct_neutral_citations": "Neutral Citations",
  "pct_positive_citations": "Positive Citations",
  "total_citations": "Total Citations",
  "total_students": "Total Students",
  "total_submissions": "Total Submissions"
}

export const formatMicroDataSubtitle = (microDataKey: string) => {
  return microDataSubtitleEnum[microDataKey];
}
import remove from 'lodash/remove';
import values from 'lodash/values';
import React, { Fragment } from 'react';
import { List, Message } from 'semantic-ui-react';
import IMessage from 'src/interfaces/MessageInterface';

const ErrorMessage = ({ title, data }: IMessage) => {
  const header = title ? title : 'Oops! Something went wrong';

  const messages = values(data);
  const notNullMessages = remove(messages);

  return (
    <Fragment>
      {notNullMessages.length > 0 && (
        <Message negative>
          <Message.Header>{header}</Message.Header>
          <List items={notNullMessages} />
        </Message>
      )}
    </Fragment>
  );
};

export default ErrorMessage;

import get from 'lodash/get';
import { useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { IBreadcrumbInfo } from 'src/interfaces/DashboardDataInterface';
import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';

const useFetchDashboardSentimentHeatmap = (breadcrumbInfo: IBreadcrumbInfo, collegeYear?: string, collegeSemester?: string) => {
  const [sentimentHeatmap, setSentimentHeatmap] = useState({});
  const [isSentimentHeatmapLoading, setSentimentHeatmapLoading] = useState(false);

  useDeepCompareEffect(() => {
    const dataIsValid = breadcrumbInfo.classid || (collegeYear && collegeSemester);
    if(!dataIsValid) {
      return;
    }
    const fetchDashboardSentimentHeatmap = async () => {
      setSentimentHeatmapLoading(true);
      try {
        const response = await ApiCalls.getDashboardSentimentHeatmap(breadcrumbInfo, collegeYear, collegeSemester);
        const { data } = response;
        const fetchedSentimentHeatmap = get(data, 'data', {});
        setSentimentHeatmap(fetchedSentimentHeatmap);
      } catch(error) {
        createToast({
          type: 'error',
          description: 'Couldn\'t fetch sentiment data'
        });
      } finally {
        setSentimentHeatmapLoading(false);
      }
    };

    fetchDashboardSentimentHeatmap();
  },[breadcrumbInfo, collegeSemester, collegeYear]);

  return [sentimentHeatmap, isSentimentHeatmapLoading];
};

export default useFetchDashboardSentimentHeatmap;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { WorkflowSection } from 'src/components/Sections';
import {
  HPContainer,
  HPHeader,
  HPHeader2,
  HPSubheader,
  HPSubheader2
} from './styles';

import UploadPapersCover from 'src/containers/UploadPapers';
const HomePageHeading = () => {
  const { t } = useTranslation();
  return (
    <HPContainer basic>
      <Container>
        <HPHeader>{t('Home Title')}</HPHeader>
        <HPHeader2>{t('Home Title 2')}</HPHeader2>
        <HPSubheader>{t('Home Subtitle')}</HPSubheader>
        <HPSubheader2>{t('Home Subtitle 2')}</HPSubheader2>
        <UploadPapersCover />
        <br />
        <br />
        <WorkflowSection />
        <br />
      </Container>
    </HPContainer>
  );
};

export default HomePageHeading;

import React from 'react';

import { connect } from 'react-redux';
import { forgotPasswordSubmit } from 'src/redux/actions/AuthActions';

import { NewPasswordForm } from 'src/components/Forms';
import { ScreenHeader } from 'src/components/Headers';
import ScreenContentContainer from 'src/containers/ScreenContentContainer';

import { NewPasswordCredentials } from 'src/interfaces/AuthInterface';
interface IProps {
  forgotPasswordSubmit: (args: NewPasswordCredentials, history: any) => void;
  history: any;
}

const NewPassword = (props: IProps) => (
  <ScreenContentContainer>
    <ScreenHeader title="New Password" />
    <NewPasswordForm
      forgotPasswordSubmit={value =>
        props.forgotPasswordSubmit(value, props.history)
      }
    />
  </ScreenContentContainer>
);

export default connect(
  null,
  {
    forgotPasswordSubmit
  }
)(NewPassword);

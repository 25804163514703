const URL = 'https://vextedu.net';

export const facebookConfig = {
  clientId: '1ktmfth6clpgc75t15vage3cva',
  domain: 'vextedubfca8b89-bfca8b89-master.auth.us-east-1.amazoncognito.com',
  appId: '2324224700944353',
  scope: 'aws.cognito.signin.user.admin email openid phone profile',
  responseType: 'code',
  redirectSignIn: `${URL}/waitingResponse/`,
  redirectSignOut: `${URL}/`,
  redirectUri: `${URL}/waitingResponse/`
};

export const facebookOAuthUrl = `https://${facebookConfig.domain}/oauth2/authorize?identity_provider=Facebook&redirect_uri=${facebookConfig.redirectUri}&response_type=${facebookConfig.responseType}&client_id=${facebookConfig.clientId}&scope=${facebookConfig.scope}`;

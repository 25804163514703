import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import HeaderNavbar from 'src/components/HeaderNavbar';

const Header = ({ children }: any) => {
  const auth = useSelector((state: any) => state.auth);
  const { loggedIn, user } = auth;

  const userName = get(user, 'name', '');

  const isAdmin = Boolean(get(user, 'custom:isAdmin', false));

  const accountRouteTitle = isAdmin ? `Welcome: ${userName}` : userName;
  const accountRoute = isAdmin ? '/dashboard' : '/account';

  const routes = {
    authRoutes: [
      { route: accountRouteTitle, key: 'account', url: accountRoute },
      { route: 'Profile Settings', key: 'settings', url: '/settings' },
      {
        route: 'Help',
        key: 'contact',
        url: '/contact'
      },
      { route: 'Logout', key: 'logout', url: '/logout' }
    ],
    unAuthRoutes: [
      { route: 'Login', key: 'login', url: '/login' },
      {
        route: 'Sign Up',
        key: 'signup',
        url: '/signup'
      }
    ]
  };

  const currentRoutes = loggedIn ? routes.authRoutes : routes.unAuthRoutes;

  return (
    <HeaderNavbar items={currentRoutes} isAdmin={isAdmin}>
      {children}
    </HeaderNavbar>
  );
};

export default Header;

import React from 'react';
import CarouselSlider from 'react-carousel-slider';
import ImageWF from 'src/assets/wireframes/image.png';

import {
  ArticlesCarouselSettings,
  ArticleCardImage,
  ArticleCardDesc
} from './style';

interface Item {
  title: string;
  thumbnail: string;
  link: string;
}

interface IProps {
  items: Item[];
}

const ArticlesCarousel = ({ items }: IProps) => {
  const handleClick = (url: string) => {
    window.open(url, '_blank');
  };

  const renderItems = items.map(({ title, thumbnail, link }, key) => {
    const titl = title.length > 50 ? `${title.substr(0, 50)}...` : title;
    const thumb = thumbnail.includes('https://medium.com')
      ? ImageWF
      : thumbnail;

    return (
      <div
        onClick={() => handleClick(link)}
        style={{ cursor: 'pointer' }}
        key={key}
      >
        <ArticleCardImage src={thumb} />
        <ArticleCardDesc>{titl}</ArticleCardDesc>
      </div>
    );
  });

  return (
    <CarouselSlider
      slideCpnts={renderItems}
      buttonSetting={ArticlesCarouselSettings.buttonSetting}
      sliderBoxStyle={ArticlesCarouselSettings.sliderBoxStyle}
      dotsSetting={ArticlesCarouselSettings.dotsSetting}
      accEle={{ dots: false }}
      itemsStyle={ArticlesCarouselSettings.itemsStyle}
    />
  );
};

export default ArticlesCarousel;

import React, { Fragment } from 'react';

import get from 'lodash/get';
import isObject from 'lodash/isObject';

import { LoadingPlaceholder } from 'src/components/Placeholders';
import { BoxTitle, DashboardBox } from 'src/screens/Dashboard/styles';
import { formatMicroDataSubtitle } from 'src/services/helpers/MicroDataSubtitleHelper';
import {
  ListItemSubtitle,
  ListItemTitle,
  MicroDataKeyword,
  MicroDataList,
  MicroDataListItem
} from './styles';

interface IProps {
  data: any;
  title?: string;
  loading: boolean;
}

const MicroData = ({ data, title, loading }: IProps) => {
  // const keyword = _.get(data, 'keyword', 'Unknown Keyword');

  const stringifyObjectKeys = (value: string) => {
    return (
      value[0].charAt(0).toUpperCase() +
      value
        .slice(1)
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    );
  };

  const formatObjectValues = (value: string | number) => {
    if(typeof value === 'number' && !Number.isInteger(value)) {
      return value.toFixed(3);
    }
    return value;
  };

  const microDataStatistics =
    isObject(data) &&
    Object.entries(data).map(
      (item, index) =>
        (
          <MicroDataListItem key={index}>
            <ListItemTitle>{formatObjectValues(item[1])}</ListItemTitle>
            <ListItemSubtitle>{formatMicroDataSubtitle(item[0])}</ListItemSubtitle>
          </MicroDataListItem>
        )
    );

  return (
    <Fragment>
      {title && <BoxTitle>{title}</BoxTitle>}
      <DashboardBox>
        <LoadingPlaceholder loading={loading}>
          {/*<MicroDataKeyword>*/}
          {/*  #1 Top Trending Keyword: <span>{keyword}</span>*/}
          {/*</MicroDataKeyword>*/}
          <MicroDataList>{microDataStatistics}</MicroDataList>
        </LoadingPlaceholder>
      </DashboardBox>
    </Fragment>
  );
};

export default React.memo(MicroData);

import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';

import {
  LoadingPlaceholder,
  UploadDocumentsPlaceholder
} from 'src/components/Placeholders';
import { MyPapersSection } from 'src/components/Sections';
import UploadButton from 'src/components/UploadButton';
import ContainerOverview from 'src/containers/ContainerOverview';
import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';
import { useFetchPapersByUserId } from 'src/services/hooks/useFetchPapersByUserId';
import { PapersLayoutHeader, PapersLayoutHeading } from './styles';

const Account = () => {
  const cognitoId = useSelector((state: any) => state.auth.user.sub);
  const userId = localStorage.getItem('userId') || cognitoId;
  const [papers, papersLoading, refetchPapers]: any = useFetchPapersByUserId(userId);

  const deletePaper = (paperID: string) => {
    return ApiCalls.deleteVextPaper(paperID)
      .then(res => {
        refetchPapers();
        createToast({
          type: 'success',
          description: 'Paper was successfully deleted'
        });
      })
      .catch(err => {
        createToast({
          type: 'error',
          description: err.message
        });
      });
  };

  const papersLayout = papers.length ? (
    <MyPapersSection items={papers} deletePaper={deletePaper}/>
  ) : (
    <UploadDocumentsPlaceholder />
  );

  return (
    <ContainerOverview>
      <Container>
        <LoadingPlaceholder loading={papersLoading}>
          <PapersLayoutHeader>
            <PapersLayoutHeading>Your Uploads</PapersLayoutHeading>
            {papers.length ? <UploadButton>Upload</UploadButton> : null}
          </PapersLayoutHeader>
          {papersLayout}
        </LoadingPlaceholder>
      </Container>
    </ContainerOverview>
  );
};

export default Account;

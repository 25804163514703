import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import moment from 'moment';
import randomstring from 'randomstring';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { uuid } from 'uuidv4';

import { ReactComponent as UploadIcon } from 'src/assets/paper/upload.svg';
import { ErrorMessage } from 'src/components/Messages';
import { LoadingPlaceholder } from 'src/components/Placeholders';
import UploadedPapers from 'src/components/UploadedPapers';
import ContainerOverview from 'src/containers/ContainerOverview';
import { IOptionProp } from 'src/interfaces/GeneralInterface';
import { createToast } from 'src/redux/actions/ToastActions';
import { ApiCalls } from 'src/services/api';
import { useFetchColleges } from 'src/services/hooks/useFetchColleges';
import { useFetchPapersByUserId } from 'src/services/hooks/useFetchPapersByUserId';
import UploadPapersValidation from 'src/services/validations/UploadPapersValidation';

import {
  FileFormatErrorTitle,
  UploadDropareaButton,
  UploadDropareaContainer,
  UploadDropareaTitle,
  UploadPapersContainer,
  UploadPapersSubTitle,
  UploadPapersTitle
} from './styles';

const UploadPapers = () => {
  const history = useHistory();
  const cognitoId = useSelector((state: any) => state.auth.user.sub);
  const name = useSelector((state: any) => state.auth.user.name);
  const userId = localStorage.getItem('userId') || cognitoId;
  const fileInput: React.RefObject<any> = useRef(null);
  const fileTypes = ['application/pdf'];

  const [clipboardPapers, setClipboardPapers] = useState([]);
  const [submitPapersLoading, setPapersLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [fileFormatErrorMessage, setFileFormatErrorMessage] = useState('');
  const [error, setError] = useState({});

  useEffect(() => {
    window.addEventListener('dragover', preventEventDefault);
    window.addEventListener('drop', preventEventDefault);
    return () => {
      window.removeEventListener('dragover', preventEventDefault);
      window.removeEventListener('drop', preventEventDefault);
    };
  }, []);

  const [papers, papersLoading]: any = useFetchPapersByUserId(userId);
  const [colleges, isCollegesLoading]: any = useFetchColleges();

  const collegeOptions: IOptionProp[] | [] = colleges.length ? colleges.map((c: any) => ({ text: c.college, value: c._id })) : [];

  const preventEventDefault: any = (e: React.SyntheticEvent) =>
    e.preventDefault();

  const updateClipboardPapers = (files: any) => {
    const clipboardPapers: any = Object.keys(files).reduce(
      (accumulator, currentValue, currentIndex): any => {
        const paperTitle = files[currentIndex].name.replace(/\.[^/.]+$/, '');
        return [
          ...accumulator,
          {
            authors: name,
            title: paperTitle,
            id: uuid(),
            file: files[currentIndex]
          }
        ];
      },
      []
    );
    setClipboardPapers(clipboardPapers);
  };

  const uploadFiles = () => fileInput.current.click();

  const addClipboardPapers = (e: any) => {
    const files: FileList = e.target.files;
    if (files.length === 0 || fileInput === null) {
      return;
    }
    updateClipboardPapers(files);
    setDisabled(false);
  };

  const clipboardPaperFieldChange = (
    value: string,
    index: number,
    field: string
  ) => {
    const clipPapers: any = cloneDeep(clipboardPapers);
    clipPapers[index][field] = value;
    setClipboardPapers(clipPapers);
  };

  const clipboardPaperCollegeChange = (value: string, index: number) => {
    const clipPapers: any = cloneDeep(clipboardPapers);
    clipPapers[index].collegeId = value;
    clipPapers[index].deptId = null;
    clipPapers[index].courseId = null;
    clipPapers[index].classId = null;
    setClipboardPapers(clipPapers);
  }

  const clipboardPaperDeptChange = (value: string, index: number) => {
    const clipPapers: any = cloneDeep(clipboardPapers);
    clipPapers[index].deptId = value;
    clipPapers[index].courseId = null;
    clipPapers[index].classId = null;
    setClipboardPapers(clipPapers)
  }

  const clipboardPaperCourseChange = (value: string, index: number) => {
    const clipPapers: any = cloneDeep(clipboardPapers);
    clipPapers[index].courseId = value;
    clipPapers[index].classId = null;
    setClipboardPapers(clipPapers)
  }

  const clipboardPaperClassChange = (value: string, index: number) => {
    const clipPapers: any = cloneDeep(clipboardPapers);
    clipPapers[index].classId = value;
    setClipboardPapers(clipPapers)
  }

  const deleteClipboardPaper = (id: string) => {
    const filteredClipboardPapers = clipboardPapers.filter(
      (paper: any) => paper.id !== id
    );
    const isClipboardPapers = filteredClipboardPapers.length ? true : false;
    setDisabled(!isClipboardPapers);
    setClipboardPapers([...filteredClipboardPapers]);
  };

  const isValidForm = () => {
    const { errors, isValid } = UploadPapersValidation(clipboardPapers);
    setError({ ...error, ...errors });
    return isValid;
  };

  const submitPapers = async () => {
    if (!isValidForm()) {
      window.scrollTo(0, 0);
      return false;
    }
    setPapersLoading(true);

    const uploadPromises: any = clipboardPapers.map((paper: any) => {
      const randomPaperId = randomstring.generate();
      const filename = `${randomPaperId}.pdf`;
      const dateUploaded = moment().format('YYYY-MM-DD');
      return ApiCalls.uploadPaper(
        userId,
        paper.file,
        filename,
        paper.classId
      )
        .then((response: any) => {
          console.log('response', response);
          const { data } = response;
          const resData = get(data, 'data', {});
          return ApiCalls.processPaperInfo(resData.paperID, userId, paper.authors, paper.title)
        })
        .catch(Error);
      // return ApiCalls.setVextPaperInfo(
      //   userId,
      //   {
      //     ...paper,
      //     filename,
      //     deptId: '5e5a9134b79886dd6a08225e',
      //     courseId: '5e5a9134b79886dd6a08225e',
      //     classId: '5e5a93a3b79886dd6a0838d0',
      //     dateUploaded
      //   },
      //   randomPaperId
      // )
      //   .then(() => ApiCalls.uploadPaper(userId, paper.file, filename))
      //   .then(() => ApiCalls.processPaperInfo(randomPaperId, userId))
      //   .catch(Error);
    });

    try {
      await Promise.all(uploadPromises);
      uploadPapersSuccess();
    } catch (e) {
      uploadPapersFailure();
    } finally {
      setPapersLoading(false);
    }
  };

  const uploadPapersSuccess = () => {
    createToast({
      type: 'success',
      description: `Paper(s) have been uploaded`
    });
    history.push('/account');
  };

  const uploadPapersFailure = () => {
    setError({ ...error, upload: 'Paper(s) have not been uploaded' });
  };

  const onPapersDrop = (e: any) => {
    preventEventDefault;
    setDisabled(false);
    const { files } = e.dataTransfer;

    const { type } = files[0];
    if (!fileTypes.includes(type)) {
      setFileFormatErrorMessage('File format must be pdf');
      setDisabled(true);
      return false;
    }
    setFileFormatErrorMessage('');
    updateClipboardPapers(files);
  };

  const fileFormatError = (
    <FileFormatErrorTitle>{fileFormatErrorMessage}</FileFormatErrorTitle>
  );

  const deletePaper = (paperID: string) => {
    return ApiCalls.deleteVextPaper(paperID)
      .then(res => {
        createToast({
          type: 'success',
          description: 'Paper was successfully deleted'
        });
      })
      .catch(err => {
        createToast({
          type: 'error',
          description: err.message
        });
      });
  };

  return (
    <ContainerOverview>
      <Container>
        <UploadPapersContainer>
          <UploadPapersTitle>Upload Papers</UploadPapersTitle>
          <UploadPapersSubTitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore.
          </UploadPapersSubTitle>
          <UploadDropareaContainer onDrop={onPapersDrop}>
            <UploadIcon />
            <UploadDropareaTitle>
              Drag & Drop files into this area or
              <UploadDropareaButton onClick={uploadFiles}>
                click here
              </UploadDropareaButton>
              to upload
            </UploadDropareaTitle>
            {fileFormatErrorMessage && fileFormatError}
            <input
              ref={fileInput}
              type="file"
              onChange={addClipboardPapers}
              hidden
              multiple
              accept="application/pdf"
            />
          </UploadDropareaContainer>
          <LoadingPlaceholder loading={papersLoading}>
            <ErrorMessage title="An error occured" data={error} />
            <UploadedPapers
              loading={submitPapersLoading}
              disabled={isDisabled}
              papers={papers}
              clipboardPapers={clipboardPapers}
              clipboardPaperFieldChange={clipboardPaperFieldChange}
              clipboardPaperCollegeChange={clipboardPaperCollegeChange}
              clipboardPaperDeptChange={clipboardPaperDeptChange}
              clipboardPaperCourseChange={clipboardPaperCourseChange}
              clipboardPaperClassChange={clipboardPaperClassChange}
              collegeOptions={collegeOptions}
              submitPapers={submitPapers}
              deleteClipboardPaper={deleteClipboardPaper}
              deletePaper={deletePaper}
            />
          </LoadingPlaceholder>
        </UploadPapersContainer>
      </Container>
    </ContainerOverview>
  );
};

export default UploadPapers;

import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

interface IProps {
  isOpen: boolean;
}

export const DepartmentItemContainer = styled.div`
  background: ${({ isOpen }: IProps) => (isOpen ? '#25282C' : 'transparent')};
  border-top: 1px solid #484b52;
  padding: 15px 26px 15px 30px;
  display: flex;
  flex-direction: column;
  i {
    transform: ${({ isOpen }: IProps) =>
      isOpen ? 'rotate(90deg) translate(3px, 0)' : 'rotate(0deg)'};
  }
`;

export const DepartmentItemTitle = styled.h5`
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: relative;
  span {
    font-weight: 500;
    margin-left: 3px;
  }
`;

export const DepartmentItemIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: -18px;
`;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Container } from 'semantic-ui-react';
import { PricingContainer, PricingContent, PricingHeader } from './styles';

const PricingSection = (props: any) => {
  return (
    <PricingContainer basic textAlign="center">
      <Container>
        <PricingHeader>Pricing</PricingHeader>
        <PricingContent>
          VEXT is a nimble and innovative organization. Our pricing model is
          designed to provide you maximum value and allow us to make enough
          profit to sustain our growth, all while ensuring that your institution
          is not being fleeced.
        </PricingContent>

        <PricingContent>
          If you think our values align with yours - and if you are ready to
          evolve past the existing broken paradigm of plagiarism detection -
          send us an email to see how we can work together.
        </PricingContent>
        <Button primary onClick={() => props.history.push('/contact')}>
          Contact Us
        </Button>
      </Container>
    </PricingContainer>
  );
};

export default withRouter(PricingSection);

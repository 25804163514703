import React from 'react';
import Loader from 'src/components/Loader';

interface IProps {
  loading: boolean;
  children: any;
}

const LoadingPlaceholder = ({ loading, children }: IProps) =>
  loading ? <Loader active={loading} /> : children;

export default LoadingPlaceholder;

import React, { Fragment } from 'react';

import { LoadingPlaceholder } from 'src/components/Placeholders';
import { BoxTitle, DashboardBox } from 'src/screens/Dashboard/styles';
import {
  HeatmapClassStatistics,
  HeatmapFingerprint,
  HeatmapFingerprintStatistics,
  HeatmapList,
  HeatmapListGroup,
  HeatmapListItem,
  HeatmapStatistics,
} from './styles';

interface IProps {
  data: any;
  title?: string;
  loading: boolean;
  showFingerprintHeatmap: boolean;
  currentBreadcrumbItem: any;
}

const FingerprintHeatmap = ({ data, title, loading, currentBreadcrumbItem, showFingerprintHeatmap }: IProps) => {
  const { heatmap = [] } = data;
  const heatmapItems = Array.isArray(heatmap)
    ? heatmap.map((heatmapElement, index) => (
      <HeatmapListGroup key={index}>
        {
          heatmapElement.map((fingerprint: any, fingerprintIndex: number) => (
            <HeatmapListItem key={fingerprintIndex} fingerprint={fingerprint} />
          ))
        }
      </HeatmapListGroup>
      ))
    : [];

  const getBreacrumbTitle = () => {
    if(currentBreadcrumbItem.college) {
      return currentBreadcrumbItem.college;
    }
    if(currentBreadcrumbItem.dept) {
      return currentBreadcrumbItem.dept + ' department';
    }
    if(currentBreadcrumbItem.course) {
      return currentBreadcrumbItem.course + ' course';
    }
    if(currentBreadcrumbItem.class) {
      return currentBreadcrumbItem.class + ' class';
    }
  }

  const summaryData = data.summary || {};
  return (
    <Fragment>
      {title && <BoxTitle>{title}</BoxTitle>}
      {showFingerprintHeatmap ? <DashboardBox>
        <LoadingPlaceholder loading={loading}>
          <HeatmapList>{heatmapItems}</HeatmapList>
          <HeatmapStatistics>
            <HeatmapClassStatistics>
              {getBreacrumbTitle()} - Students: <b>{summaryData['Total students']}</b>
            </HeatmapClassStatistics>
            <HeatmapFingerprintStatistics>
              <HeatmapFingerprint positive>
                Positive:<b>{summaryData.Positive}</b>
              </HeatmapFingerprint>
              <HeatmapFingerprint negative>
                Negative:<b>{summaryData.Negative}</b>
              </HeatmapFingerprint>
              <HeatmapFingerprint>
                Neutral:<b>{summaryData.Neutral}</b>
              </HeatmapFingerprint>
            </HeatmapFingerprintStatistics>
          </HeatmapStatistics>
        </LoadingPlaceholder>
      </DashboardBox> : <p>Select a class or the year and semester for the heatmap</p> }
    </Fragment>
  );
};

export default React.memo(FingerprintHeatmap);

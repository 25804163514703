import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

// Home Page Heading
export const HPContainer = styled(Segment)`
  min-height: 0px;
  background-color: #f8fafe !important;
  text-align: center;
`;

export const HPHeader = styled.p`
  font-size: 52px;
  font-weight: 600;
  margin-bottom: 0;
`;
export const HPHeader2 = styled.p`
  font-size: 20px;
  font-weight: 400;
`;

export const HPSubheader = styled.p`
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const HPSubheader2 = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
`;
// Home Page Subheading
export const HSContainer = styled(Segment)`
  background-color: #fff;
  text-align: center;
`;

export const HSHeader = styled.p`
  font-size: 32px;
  font-weight: 600;
`;

export const HSSubheader = styled.p`
  font-size: 20px;
  font-weight: 300;
`;

// Paper Page Heading
export const PPContainer = styled(Segment)`
  min-height: 300px;
  background-color: #f8fafe !important;
`;

export const PPHeader = styled.p`
  font-size: 36px;
  margin-bottom: -5px;
`;

export const PPSubheader = styled.p`
  font-size: 20px;
  color: #a19d9d;
`;

export const PPFingerContainer = styled.div`
  padding: 30px 0px;
`;

export const PPFingerTitle = styled.p`
  float: left;
  font-weight: 600;
`;

export const PPFingerIconsContainer = styled.div`
  float: right;
`;

export const PPStatisticContainer = styled.div`
  margin-top: 15px;
`;

export const LoadingFingerprintSegment = styled(Segment)`
  margin: 30px 0px !important;
`;

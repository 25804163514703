import styled from 'styled-components';

import { Button, Form, Icon, Grid, Divider } from 'semantic-ui-react';

export const InvitationContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  padding: 40px 65px 40px;
  border-radius: 3px;
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 35px 45px;
  }
  @media (max-width: 480px) {
    padding: 30px 35px;
  }
`;

export const InvitationTitle = styled.h2`
  text-align: center;
  margin-bottom: 12px;
`;

export const InvitationSubtitle = styled.p`
  text-align: center;
  color: #7e8288;
  font-size: 15px;
  margin: 0 auto 20px;
  max-width: 340px;
`;

export const SubmitButton = styled(Button)`
  &&& {
    margin: 18px auto 8px !important;
    display: block;
  }
`;

export const AddedPeopleInfo = styled.p`
  &&& {
    font-size: 13px;
    color: #40444a;
    text-align: center;
    margin: 0;
  }
`;

export const InviteFormGroup = styled(Form.Group)`
  &&&&& {
    position: relative;
    margin-bottom: 0;
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }
`;

export const DeletePeopleIcon = styled(Icon)`
  &&& {
    position: absolute;
    top: 10px;
    right: -22px;
    cursor: pointer;
    font-size: 14px;
    @media (max-width: 768px) {
      top: 35px;
    }
  }
`;

export const AddPeopleIcon = styled(Icon)`
  &&& {
    cursor: pointer;
    font-size: 18px;
    display: block;
    margin: 6px auto 0;
  }
`;

export const InviteGridColumn = styled(Grid.Column)`
  &&&&& {
    padding-bottom: 14.5px;
    padding-left: ${({ paddingleft }) => paddingleft && paddingleft + 'px'};
    @media (max-width: 768px) {
      padding-left: 1rem;
    }
  }
`;

export const FormDivider = styled(Divider)`
  &&& {
    margin-bottom: 20px;
  }
`;

export const InvitePeopleButton = styled(Button)`
  display: block !important;
  margin: 0 auto !important;
`;

import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Icon, List, Image } from 'semantic-ui-react';

export const PaperContainer = styled.div`
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  padding: 35px 55px 50px;
  @media (max-width: 992px) {
    padding: 30px 40px 30px;
  }
  @media (max-width: 768px) {
    padding: 30px 22px 30px;
  }
`;

export const PaperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const DashboardLink = styled(Link)`
  color: #212327;
  font-size: 15px;
  &:hover {
    color: #212327;
  }
`;

export const PaperTitleSection = styled.div`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 18px;
  margin-bottom: 18px;
  position: relative;
`;

export const PaperTitle = styled.h2`
  margin-bottom: 10px;
`;

export const PaperIconDelete = styled(Icon)`
  position: absolute;
  top: 5px;
  right: 0;
`;

export const PaperList = styled(List)`
  &&&&& {
    @media (max-width: 580px) {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const PaperListItem = styled(List.Item)`
  &&&&& {
    margin-left: 40px;
    @media (max-width: 580px) {
      margin: 10px 0 0 0;
    }
  }
`;

export const PaperFingerprint = styled.div`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 18px;
  margin-bottom: 18px;
`;

export const PaperFingerprintTitle = styled.h4`
  margin-bottom: 13px;
`;

export const PaperFingerprintImage = styled(Image)`
  margin-bottom: 13px;
  width: 100%;
`;

export const SentimentListItem = styled(List.Item)`
  &&&&& {
    margin-left: 23px;
  }
`;

export const SentimentTitle = styled.p`
  color: #707377;
  margin-bottom: 4px;
`;

export const SentimentPercentage = styled.h2`
  font-size: 36px;
  margin-top: 0;
`;

export const PapersLibraryContainer = styled.div`
  margin-bottom: 10px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const PapersLibraryTitle = styled.h4`
  margin-bottom: 6px;
`;

export const PapersLibrarySubtitle = styled.p`
  margin-bottom: 18px;
`;

export const PapersOverview = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -6px;
`;

export const PaperItem = styled.div`
  background: #f4f5f7;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
  height: 112px;
  width: 100%;
  max-width: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 10px;
  margin: 6px;
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

export const PaperItemTitle = styled.p`
  font-size: 15px;
  margin-top: 8px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import { LoadingContainer } from 'src/components/Containers';
import { saveCurrentUser } from 'src/redux/actions/AuthActions';
import { createToast } from 'src/redux/actions/ToastActions';

const WaitingResult = (props: any) => {
  useEffect(() => {
    if (props.location.search.includes('error')) {
      createToast({ type: 'error', description: "Can't authenticate" });
      props.history.push('/login');
    }

    Hub.listen('auth', ({ payload: { event } }) => {
      return Auth.currentAuthenticatedUser().then(() => {
        props.saveCurrentUser();
        props.history.push('/account');
      });
    });
  }, []);

  return <LoadingContainer loading={true} />;
};

export default connect(
  null,
  { saveCurrentUser }
)(WaitingResult);

// Api Setup
export const API_KEY = '6e028c21-6824-482f-97e8-5477d12b47eb';
export const API_URL =
  'https://f4qtryg54k.execute-api.us-east-1.amazonaws.com/latest';

export const API_URL_2 = 'https://vextapi.com';
export const API_URL_3 =
  'https://biw74jhcll.execute-api.us-east-1.amazonaws.com/api';
export const VEXT_API = 'https://vextapi.com';

// EmailJS
export const EMAILJS_SERVICE_ID = 'gmailhello_vextedu_net';
export const EMAILJS_TEMPLATE_ID = 'template_oisUBadY';
export const EMAILJS_USER_ID = 'user_RJ1ymVdKqrXQdUVktbiX3';

// Google Analytics
export const GA_KEY = 'UA-136941242-1';

// General
export const BLOG_URL = 'https://medium.com/vexted';
export const BLOG_USER = '@chris_41477';
export const RSS_KEY = 'a5b0vlgethjdatcjni2u6mrhvg0sywpvpt8cagxp';

import assignWith from 'lodash/assignWith';
import isNull from 'lodash/isNull';
import { IPaper } from 'src/interfaces/PapersInterface';
import validator from 'validator';

const filter = (acc: any, newValue: any) => {
  const isValueNull = isNull(newValue);
  return isValueNull ? acc : newValue;
};

interface IError {
  title: string | null;
  authors: string | null;
}

const formValidation = (data: IPaper[]) => {
  const errors: IError[] = [];

  data.map((item: IPaper) => {
    const error: IError = {
      title: null,
      authors: null
    };

    if (validator.isEmpty(item.authors)) {
      error.authors = 'You should have at least 1 author';
    }

    if (validator.isEmpty(item.title)) {
      error.title = 'Title is required';
    }

    return errors.push(error);
  });

  const filteredErrors: any = assignWith({}, ...errors, filter);

  return {
    errors: filteredErrors,
    isValid: !Object.values(filteredErrors).some(element => element !== null)
  };
};

export default formValidation;
